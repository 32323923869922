import Navigation from '../navigation/navigation.component';

const AsideBody = () => {
	return (
		<div className="aside-body">
			<nav>
				<Navigation />
			</nav>
		</div>
	)
}

export default AsideBody