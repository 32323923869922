import React from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import { IconReceipt } from '@tabler/icons-react'
import CardBody from '../card/card-body.component'
import Chart from "react-apexcharts";

const RevenueChart = () => {

	var options = {
		series: [
			{
				name: 'Активни Корисници',
				type: 'column',
				data: [20, 22, 15, 18, 23, 25, 30, 32, 35, 32, 33, 37]
			}, {
				name: 'Суспендирани Корисници',
				type: 'column',
				data: [0, 1, 3, 2, 4, 1, 5, 3, 2, 3, 2, 4]
			}, {
				name: 'Приход',
				type: 'line',
				data: [1.5, 1.6, 1.8, 1.7, 2.1, 2.3, 2.4, 2.6, 2.8, 3.4, 3.5, 3.8]
			}
		],
		options: {
			chart: {
				type: 'line',
				stacked: false,
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				width: [1, 1, 4]
			},
			xaxis: {
				categories: ['Jan', 'Fev', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				tooltip: {
					enabled: true,
					theme: 'dark',
					offsetY: 0,
				},
			},

			yaxis: [
			{
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: 'rgb(70, 188, 170)'
				},
				labels: {
					style: {
						colors: 'rgb(70, 188, 170)',
					}
				},
				title: {
					text: "Вкупно Активни Корисници",
					style: {
						color: 'rgb(70, 188, 170)',
					}
				},
				tooltip: {
					enabled: true,
					offsetX: -20
				},
				plotOptions: {
					bar: {
						columnWidth: '30%',
						borderRadius: 5,
					}
				},
			},
			{
				seriesName: 'Вкупно Суспендирани Корисници',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: 'rgb(77, 105, 250)'
				},
				labels: {
					style: {
						colors: 'rgb(77, 105, 250)',
					}
				},
				title: {
					text: "Total Suspended Users",
					style: {
						color: 'rgb(77, 105, 250)',
					}
				},
			},
			{
				seriesName: 'Вкупно Приход',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: 'rgb(255, 207, 82)'
				},
				labels: {
					style: {
						colors: 'rgb(255, 207, 82)',
					},
				},
				title: {
					text: "Вкупно Приход",
					style: {
						color: 'rgb(255, 207, 82)',
					}
				}
			}],
			colors: ['rgb(70, 188, 170)', 'rgb(77, 105, 250)', 'rgb(255, 207, 82)'],
			tooltip: {
				theme: 'dark',
				fixed: {
					enabled: true,
					position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
					offsetY: 30,
					offsetX: 95
				},
			},
			legend: {
				horizontalAlign: 'left',
				offsetX: 40
			},
			plotOptions: {
				bar: {
					columnWidth: '40%',
					borderRadius: 5,
				}
			},
		}
	};

	return (
		<Card>
			<CardHeader title='Статистика' icon={<IconReceipt size={30} color="#6c5dd3" />} />

			<CardBody>
				<div className="apex-chart">
					<Chart 
						options={options.options}
						series={options.series}
						width="100%"
						height="350"
					/>
				</div>
			</CardBody>
		</Card>
	)
}

export default RevenueChart