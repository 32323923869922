import { ACTION_TYPES } from './customers.types';

const INITIAL_STATE = {
	collection: []
}

export const customersReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ACTION_TYPES.SET_CUSTOMERS:
			return {
				...state,
				collection: payload
			}
		case ACTION_TYPES.REMOVE_CUSTOMERS:
			return {
				...state,
				collection: []
			}
		default:
			return state;
	}
}
