import React, { useEffect, useState } from 'react'
import Rain from './../../assets/icons/rain.svg'
import Storm from './../../assets/icons/thunderstorms-rain.svg';
import Fog from './../../assets/icons/fog.svg';
import Sun from './../../assets/icons/clear-day.svg';

const TemplateContent = ({title, description, price, duration}) => {
	const icons = [Rain, Storm, Fog, Sun];
	const [ ran, setRan ] = useState()
	
	useEffect( () => {
		setRan(Math.floor(Math.random() * icons.length));
	}, [])

	return (
		<>
			<div className="ratio ratio-4x3 rounded-2 mb-3">
				<img src={icons[ran]} alt="Icon Weather" width="50px" className="object-fit-contain p-3" />
			</div>
			<h5 className="card-title">{title}</h5>
			<p className="text-muted truncate-line-2">{description}</p>
		</>
  	)
}

export default TemplateContent