import React, { useEffect, useState } from 'react'
import Macedonia from './assets/mk.jpg';
import moment from 'moment';

import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import { getWeatherIcon, GetWeatherMaxTemp } from '../components/weather_info';

const MacedoniaMap = ({data}) => {

	const day = 1;

	return (
		<>
			<div className="balkan">
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
					<defs>
						<radialGradient id="radial-gradient" cx="0.267" cy="0.199" r="0.796" gradientTransform="matrix(0.565, 0.825, -0.825, 0.565, 0.28, -0.134)" gradientUnits="objectBoundingBox">
						<stop offset="0" stopColor="#eb8332"/>
						<stop offset="1" stopColor="#d12c1f"/>
						</radialGradient>
						<clipPath id="clip-Custom_Size_9">
						<rect width="1920" height="1080"/>
						</clipPath>
						<image id="image" width="80" height="70"  />
					</defs>
					<g id="Custom_Size_9" data-name="Custom Size – 9" clipPath="url(#clip-Custom_Size_9)">
						<rect width="1920" height="1080"/>
						<image id="Image_6" data-name="Image 6" width="1926" height="1082" transform="translate(-2 -2)" href={Macedonia} />
						<g id="city-wrap" data-name="Group 15" transform="translate(630 555)">
							<text id="Кавадарци" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Кавадарци</tspan></text>
							<g id="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Кавадарци" day={day} />°</tspan></text>
							</g>
							<g id="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Скопје', day)} transform="translate(551 43)" />
						</g>
						<g id="city-wrap" data-name="Group 18" transform="translate(418 583)">
							<text id="Прилеп" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Прилеп</tspan></text>
							<g id="temperature-2" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-2" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Прилеп" day={day} />°</tspan></text>
							</g>
							<g id="pin-2" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Прилеп', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 19" transform="translate(297 778)">
							<text id="Битола" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Битола</tspan></text>
							<g id="temperature-3" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-3" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Битола" day={day} />°</tspan></text>
							</g>
							<g id="pin-3" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Битола', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 22" transform="translate(228 546)">
							<text id="Крушево" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Крушево</tspan></text>
							<g id="temperature-4" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-4" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Крушево" day={day} />°</tspan></text>
							</g>
							<g id="pin-4" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Крушево', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 56" transform="translate(231 368)">
							<text id="Кичево" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Кичево</tspan></text>
							<g id="temperature-5" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-5" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Кичево" day={day} />°</tspan></text>
							</g>
							<g id="pin-5" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Кичево', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 23" transform="translate(22 362)">
							<text id="Дебар" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Дебар</tspan></text>
							<g id="temperature-5" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-5" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Дебар" day={day} />°</tspan></text>
							</g>
							<g id="pin-5" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Дебар', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 24" transform="translate(169 219)">
							<text id="Гостивар" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Гостивар</tspan></text>
							<g id="temperature-6" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-6" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Гостивар" day={day} />°</tspan></text>
							</g>
							<g id="pin-6" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Гостивар', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 25" transform="translate(277 95)">
							<text id="Тетово" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Тетово</tspan></text>
							<g id="temperature-7" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-7" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Тетово" day={day} />°</tspan></text>
							</g>
							<g id="pin-7" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Тетово', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 26" transform="translate(441 119)">
							<text id="Скопје" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Скопје</tspan></text>
							<g id="temperature-8" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-8" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Скопје" day={day} />°</tspan></text>
							</g>
							<g id="pin-8" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Скопје', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 27" transform="translate(558 301)">
							<text id="Велес" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Велес</tspan></text>
							<g id="temperature-9" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-9" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Велес" day={day} />°</tspan></text>
							</g>
							<g id="pin-9" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Велес', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 28" transform="translate(744 103)">
							<text id="Кратово" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Кратово</tspan></text>
							<g id="temperature-10" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-10" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Крива Паланка" day={day} />°</tspan></text>
							</g>
							<g id="pin-10" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Крива Паланка', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 29" transform="translate(917 163)">
							<text id="Кочани" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Кочани</tspan></text>
							<g id="temperature-11" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-11" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Кочани" day={day} />°</tspan></text>
							</g>
							<g id="pin-11" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Кочани', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 30" transform="translate(786 296)">
							<text id="Штип" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Штип</tspan></text>
							<g id="temperature-12" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-12" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Штип" day={day} />°</tspan></text>
							</g>
							<g id="pin-12" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Штип', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 31" transform="translate(897 403)">
							<text id="Радовиш" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Радовиш</tspan></text>
							<g id="temperature-13" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-13" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Радовиш" day={day} />°</tspan></text>
							</g>
							<g id="pin-13" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Радовиш', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 32" transform="translate(1126 320)">
							<text id="Берово" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Берово</tspan></text>
							<g id="temperature-14" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-14" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Берово" day={day} />°</tspan></text>
							</g>
							<g id="pin-14" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Берово', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 33" transform="translate(1035 527)">
							<text id="Струмица" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Струмица</tspan></text>
							<g id="temperature-15" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-15" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Струмица" day={day} />°</tspan></text>
							</g>
							<g id="pin-15" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Струмица', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 34" transform="translate(875 718)">
							<text id="Гевгелија" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Гевгелија</tspan></text>
							<g id="temperature-16" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-16" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Гевгелија" day={day} />°</tspan></text>
							</g>
							<g id="pin-16" data-name="pin" transform="translate(4564 73)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Гевгелија', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 20" transform="translate(119 752)">
							<text id="Ресен" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Ресен</tspan></text>
							<g id="temperature-17" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-17" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Ресен" day={day} />°</tspan></text>
							</g>
							<g id="pin-17" data-name="pin" transform="translate(4530 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Ресен', day)} transform="translate(551 50)" />
						</g>
						<g id="city-wrap" data-name="Group 21" transform="translate(13 666)">
							<text id="Охрид" transform="translate(561 156)" fill="#fff" fontSize="37"><tspan x="0" y="0">Охрид</tspan></text>
							<g id="temperature-18" data-name="temperature" transform="translate(-22 -52)">
								<circle id="Ellipse_2-18" data-name="Ellipse 2" cx="27.5" cy="27.5" r="27.5" transform="translate(662 120)" fill="url(#radial-gradient)"/>
								<text id="temp_cels" transform="translate(700 162)" fill="#fff" fontSize="39"><tspan x="-10.744" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Охрид" day={day} />°</tspan></text>
							</g>
							<g id="pin-18" data-name="pin" transform="translate(4552 81)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={100} height={100} href={getWeatherIcon(data, 'Охрид', day)} transform="translate(551 50)" />
						</g>
						<text id="МАКЕДОНИЈА" transform="translate(1860 168)" fill="#fff" fontSize="37"><tspan x="-289.932" y="0">МАКЕДОНИЈА {moment().add(day,'days').format('DD.MM.Y')}</tspan></text>
						<text id="www.weather4all.com.mk" transform="translate(1641 193)" fill="#fff" fontSize="27" opacity="0.7"><tspan x="0" y="0">www.weather4all.com.mk</tspan></text>
					</g>
				</svg>
			</div>
		</>
	)
}

export default MacedoniaMap