import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react'
import React, { Fragment } from 'react'

const TableFooter = ({ pagination, setPagination, setItemsPerPage, items, sliceFrom, sliceTo }) => {
	
	const handlePrevPage = () => {
		if ( pagination.currentPage > 1 )
			setPagination({
				...pagination,
				currentPage: pagination.currentPage - 1,
			});
	}

	const handleNextPage = () => {
		if ( pagination.currentPage < pagination.totalPages )
			setPagination({
				...pagination,
				currentPage: pagination.currentPage + 1,
			});
	}

	const handleFirstPage = () => {
		setPagination({
			...pagination,
			currentPage: 1,
		});
	}
	const handleLastPage = () => {
		setPagination({
			...pagination,
			currentPage: pagination.totalPages,
		});
	}


	return (
		<>
			<div className="card-footer-left">
				<span className="text-muted">
					{ items ? <span className="pagination__desc">Се прикажуваат од {++sliceFrom} до {sliceTo > items.length ? items.length : sliceTo} од вкупно {items.length} </span> : ''}
				</span>
			</div>
			<div className="card-footer-right d-flex">
			{ pagination.totalPages > 1 ? (
				<nav aria-label="items">
					<ul className="pagination m-0">
					{ pagination.totalPages < 6 ? (
						<Fragment>
							<li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`} onClick={handlePrevPage}><span role="button" className="page-link"><IconChevronLeft size={14} /></span></li>
							{
								[...Array(pagination.totalPages)].map( (x, i) =>
									<li key={i} className={`page-item ${ pagination.currentPage === i+1 ? 'active' : '' }`} onClick={() => setPagination({...pagination, currentPage: i+1 })}><span role="button" className="page-link">{i+1}</span></li>
								)
							}
							<li className={`page-item ${pagination.currentPage === pagination.totalPages ? 'disabled' : ''}`} onClick={handleNextPage}><span role="button" className="page-link"><IconChevronRight size={14} /></span></li>
						</Fragment>
					) : (
						<Fragment>
							<li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`} onClick={handleFirstPage}><span role="button" className="page-link"><IconChevronsLeft size={14} /></span></li>
							<li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`} onClick={handlePrevPage}><span role="button" className="page-link"><IconChevronLeft size={14} /></span></li>
													
							{ pagination.currentPage > 1 ? <li className={`page-item`} onClick={() => setPagination({...pagination, currentPage: pagination.currentPage - 1 })}><span role="button" className="page-link">{pagination.currentPage-1}</span></li> : '' }
							<li className={`page-item active`}><span role="button" className="page-link">{pagination.currentPage}</span></li>
							{ pagination.currentPage === pagination.totalPages ? '' : <li className={`page-item`} onClick={() => setPagination({...pagination, currentPage: pagination.currentPage + 1 })}><span role="button" className="page-link">{pagination.currentPage + 1}</span></li> }
							
							<li className={`page-item ${pagination.currentPage === pagination.totalPages ? 'disabled' : ''}`} onClick={handleNextPage}><span role="button" className="page-link"><IconChevronRight size={14} /></span></li>
							<li className={`page-item ${pagination.currentPage === pagination.totalPages ? 'disabled' : ''}`} onClick={handleLastPage}><span role="button" className="page-link"><IconChevronsRight size={14} /></span></li>
						</Fragment>
					)} 
					</ul>
				</nav>
				) : ''}
				{ items ? 
				<select className="form-select form-select-sm" onChange={(e) => { setItemsPerPage(e.target.value); setPagination({currentPage: 1, totalPages: Math.ceil(items.length / e.target.value)})  }} aria-label="Per">
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="30">30</option>
				</select>
				: '' }
			</div>
		</>
	)
}

export default TableFooter