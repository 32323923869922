import React, { useEffect, useState } from 'react'
import Card from '../card/card.component'
import CardBody from '../card/card-body.component'
import CardHeader from '../card/card-header.component'
import { IconUser } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import CardFooter from '../card/card-footer.component'
import Alert from '../alert/alert.component'
import SaveButton from '../buttons/save-button.component'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../../fetch/customers.fetch'
import { updateUserInfo } from '../../store/user/user.action'

const PersonalInformation = () => {
	const user = useSelector(state => state.user.info)
	const token = useSelector(state => state.user.token)
	
	const [ changesMade, setChangesMade ] = useState(false);

	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, watch, handleSubmit } = useForm({
		defaultValues: {
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
			address: user.address,
			city: user.city,
			zip: user.zip,
			country: user.country,
			edb: user.edb,
			phone: user.phone,
		}
	});

	const onSubmit = (data) => {
		mutation.mutate(data)
	};


	const dispatch = useDispatch();

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editCustomer(token, data, user?.id),
		onSuccess: (res) => {
			dispatch(updateUserInfo(res.data.user))
			setChangesMade(false)
		},
		onError: () => {
			
		}
	})

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if ( value.first_name !== user.first_name || value.last_name !== user.last_name 
				|| value.email !== user.email 
				|| value.address !== user.address || value.city !== user.city 
				|| value.zip !== user.zip || value.country !== user.country
				|| value.edb !== user.edb || value.phone !== user.phone ) 
				setChangesMade(true)
			else 
				setChangesMade(false)
		});

		return () => subscription.unsubscribe();
	  }, [watch]);
	

	return (
		<Card>
			<form onSubmit={handleSubmit(onSubmit)}>
				<CardHeader title="Лични податоци" icon={<IconUser size={30} color="#6c5dd3" />} />
				<CardBody>
					<div className="row g-4">
						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="first_name" type="text" className="form-control" placeholder="First Name" {...register("first_name")} />
								<label htmlFor="first_name" className="form-label">Име</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="last_name" type="text" className="form-control" placeholder="Last Name" {...register("last_name")} />
								<label htmlFor="last_name" className="form-label">Презиме</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-12">
							<div className="form-floating">
								<input id="email" type="text" className="form-control" placeholder="Email Adress" {...register("email")} />
								<label htmlFor="email" className="form-label">Е-адреса</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="address" type="text" className="form-control" placeholder="Address" {...register("address")} />
								<label htmlFor="address" className="form-label">Адреса</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="city" type="text" className="form-control" placeholder="City" {...register("city")} />
								<label htmlFor="city" className="form-label">Град</label>
							</div>
						</div>
						
						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="zip" type="text" className="form-control" placeholder="Zip" {...register("zip")} />
								<label htmlFor="zip" className="form-label">Поштенски број</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="country" type="text" className="form-control" placeholder="Country" {...register("country")} />
								<label htmlFor="country" className="form-label">Град</label>
							</div>
						</div>
						
						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="edb" type="text" className="form-control" placeholder="EDB" {...register("edb")} />
								<label htmlFor="edb" className="form-label">ЕДБ</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="form-floating">
								<input id="phone" type="text" className="form-control" placeholder="Phone" {...register("phone")} />
								<label htmlFor="phone" className="form-label">Телефон</label>
							</div>
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div>
						{ changesMade && <Alert type="info" message="Changes are not saved" />}
					</div>

					<SaveButton disabled={!changesMade} />
				</CardFooter> 
			</form>
		</Card>
  	)
}

export default PersonalInformation