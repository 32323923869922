import React from 'react'
import Macedonia from './assets/macedonia.jpg';
import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons'
import { GetWeatherMaxTemp } from '../components/weather_info';

const MacedoniaMap = ({data}) => {
	return (
		<>
			<div className="balkan">
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
					<image id="_2.2024-_EVROPA_00126" data-name="2.2024- EVROPA_00126" href={Macedonia} width="1920" height="1080" />
					<g id="city-wrap" data-name="Group 106" transform="translate(-4109 -3809)">
						<g id="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Битола" day={1} />°C</tspan></text>
						</g>
						<g id="city" transform="translate(720 -586)">
						<rect id="Rectangle_18" data-name="Rectangle 18" width="122" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Битола" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Битола</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 110" transform="translate(-4047 -3973)">
						<g id="temp-2" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-2" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-2" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Прилеп" day={1} />°C</tspan></text>
						</g>
						<g id="city-2" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-2" data-name="Rectangle 18" width="125" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Прилеп" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Прилеп</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 111" transform="translate(-3867 -4033)">
						<g id="temp-3" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-3" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-3" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Кавадарци" day={1} />°C</tspan></text>
						</g>
						<g id="city-3" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-3" data-name="Rectangle 18" width="167" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Кавадарци" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Кавадарци</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 112" transform="translate(-3661 -3888)">
						<g id="temp-4" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-4" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-4" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Гевгелија" day={1} />°C</tspan></text>
						</g>
						<g id="city-4" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-4" data-name="Rectangle 18" width="152" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Гевгелија" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Гевгелија</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 113" transform="translate(-3565 -4033)">
						<g id="temp-5" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-5" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-5" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Струмица" day={1} />°C</tspan></text>
						</g>
						<g id="city-5" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-5" data-name="Rectangle 18" width="152" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Струмица" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Струмица</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 114" transform="translate(-3443 -4212)">
						<g id="temp-6" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-6" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-6" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Берово" day={1} />°C</tspan></text>
						</g>
						<g id="city-6" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-6" data-name="Rectangle 18" width="120" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Берово" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Берово</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 115" transform="translate(-3671 -4127)">
						<g id="temp-7" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-7" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-7" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Радовиш" day={1} />°C</tspan></text>
						</g>
						<g id="city-7" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-7" data-name="Rectangle 18" width="144" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Радовиш" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Радовиш</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 116" transform="translate(-3793 -4212)">
						<g id="temp-8" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-8" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-8" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Штип" day={1} />°C</tspan></text>
						</g>
						<g id="city-8" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-8" data-name="Rectangle 18" width="100" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Штип" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Штип</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 117" transform="translate(-3632 -4293)">
						<g id="temp-9" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-9" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-9" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Кочани" day={1} />°C</tspan></text>
						</g>
						<g id="city-9" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-9" data-name="Rectangle 18" width="123" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Кочани" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Кочани</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 118" transform="translate(-3776 -4379)">
						<g id="temp-10" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-10" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-10" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Кратово" day={1} />°C</tspan></text>
						</g>
						<g id="city-10" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-10" data-name="Rectangle 18" width="132" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Кратово" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Кратово</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 125" transform="translate(-3922 -4455)">
						<g id="temp-11" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-11" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-11" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Куманово" day={1} />°C</tspan></text>
						</g>
						<g id="city-11" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-11" data-name="Rectangle 18" width="152" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Куманово" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Куманово</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 119" transform="translate(-4082 -4353)">
						<g id="temp-12" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-12" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-12" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Скопје" day={1} />°C</tspan></text>
						</g>
						<g id="city-12" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-12" data-name="Rectangle 18" width="112" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Скопје" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Скопје</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 120" transform="translate(-4003 -4219)">
						<g id="temp-13" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-13" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-13" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Велес" day={1} />°C</tspan></text>
						</g>
						<g id="city-13" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-13" data-name="Rectangle 18" width="105" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Велес" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Велес</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 107" transform="translate(-4313 -3809)">
						<g id="temp-14" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-14" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-14" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Ресен" day={1} />°C</tspan></text>
						</g>
						<g id="city-14" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-14" data-name="Rectangle 18" width="104" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Ресен" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Ресен</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 109" transform="translate(-4262 -3995)">
						<g id="temp-15" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-15" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-15" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Крушево" day={1} />°C</tspan></text>
						</g>
						<g id="city-15" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-15" data-name="Rectangle 18" width="138" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Крушево" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Крушево</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 121" transform="translate(-4277 -4115)">
						<g id="temp-16" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-16" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-16" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Кичево" day={1} />°C</tspan></text>
						</g>
						<g id="city-16" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-16" data-name="Rectangle 18" width="121" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Кичево" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Кичево</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 122" transform="translate(-4502 -4132)">
						<g id="temp-17" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-17" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-17" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Дебар" day={1} />°C</tspan></text>
						</g>
						<g id="city-17" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-17" data-name="Rectangle 18" width="109" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Дебар" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Дебар</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 123" transform="translate(-4382 -4266)">
						<g id="temp-18" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-18" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-18" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Гостивар" day={1} />°C</tspan></text>
						</g>
						<g id="city-18" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-18" data-name="Rectangle 18" width="144" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Гостивар" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Гостивар</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 124" transform="translate(-4333 -4386)">
						<g id="temp-19" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-19" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-19" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Тетово" day={1} />°C</tspan></text>
						</g>
						<g id="city-19" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-19" data-name="Rectangle 18" width="116" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Тетово" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Тетово</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="city-wrap" data-name="Group 108" transform="translate(-4437 -3899)">
						<g id="temp-20" data-name="temp" transform="translate(714 -583)">
						<rect id="Rectangle_19-20" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-20" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Охрид" day={1} />°C</tspan></text>
						</g>
						<g id="city-20" data-name="city" transform="translate(720 -586)">
						<rect id="Rectangle_18-20" data-name="Rectangle 18" width="110" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Охрид" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Охрид</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="120" height="120" transform="translate(4824 4607)" />
					</g>
					<g id="Group_59" data-name="Group 59" transform="translate(1428 53)">
						<rect id="Rectangle_20" data-name="Rectangle 20" width="430" height="50" transform="translate(0 -4)" fill="#262626"/>
						<text id="ЕВРОПА_25.03.2024" data-name="ЕВРОПА 25.03.2024" transform="translate(171 33)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="-149.652" y="0">МАКЕДОНИЈА 25.03.2024</tspan></text>
					</g>
					<g id="Group_60" data-name="Group 60" transform="translate(1428 104)">
						<rect id="Rectangle_21" data-name="Rectangle 21" width="254" height="50" fill="#3c789f"/>
						<text id="www.w4all.mk" transform="translate(20 37)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="0" y="0">www.w4all.mk</tspan></text>
					</g>
				</svg>
			</div>
		</>
	)
}

export default MacedoniaMap
