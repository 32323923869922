import { IconClipboardCheck } from '@tabler/icons-react'
import React from 'react'

const SaveButton = ({disabled = false, ...prop}) => {
	return (
		<button type="submit" className={`btn btn-primary ${disabled ? 'cursor-not-allowed' : ''}`} disabled={disabled} {...prop}>
			<IconClipboardCheck size={18} className="me-2" />
			Зачувај
		</button>
	)
}

export default SaveButton