import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedAdminRoute = ({children}) => {
	const user = useSelector( state => state.user.info )

	if (user.isAdmin === 0)
		return <Navigate to="/" replace />;
	
	return children;
};

export default ProtectedAdminRoute;