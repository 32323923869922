export const URL = process.env.NODE_ENV === 'development' ? 'https://api-dev.weather4all.com.mk' : 'https://api.weather4all.com.mk';
export const API_URL = process.env.NODE_ENV === 'development' ? 'https://api-dev.weather4all.com.mk/v2' : 'https://api.weather4all.com.mk/v2';
export const PRODUCTION_API = 'https://api.weather4all.com.mk/v2';
export const MOCK_URL = 'https://ad248daa-07f8-4204-a6e8-8957eed2b7b6.mock.pstmn.io';
export const ADMIN_ROLE = 'admin';
export const ITEMS_PER_PAGE = 10;

export const WEATHER_ICONS = [
	{
		img: `${window.location.origin}/w4all_icons/100.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/100.svg`} alt="Icon" /><small>01. Сончево</small></div>,
		text: "Сончево",
		code: 100,
		value: 100,
	},
	{
		img: `${window.location.origin}/w4all_icons/101.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/101.svg`} alt="Icon" /><small>02. Претежно сончево</small></div>,
		text: "Претежно сончево",
		code: 101,
		value: 101,
	},
	{
		img: `${window.location.origin}/w4all_icons/102.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/102.svg`} alt="Icon" /><small>03. Претежно сончево со зголемена oблачност</small></div>,
		text: "Претежно сончево со зголемена oблачност",
		code: 102,
		value: 102,
	},
	{
		img: `${window.location.origin}/w4all_icons/103.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/103.svg`} alt="Icon" /><small>04. Претежно сончево со услови за дожд </small></div>,
		text: "Претежно сончево со услови за дожд ",
		code: 103,
		value: 103,
	},
	{
		img: `${window.location.origin}/w4all_icons/104.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/104.svg`} alt="Icon" /><small>05. Умерено облачно со услови за дожд</small></div>,
		text: "Умерено облачно со услови за дожд",
		code: 104,
		value: 104,
	},
	{
		img: `${window.location.origin}/w4all_icons/105.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/105.svg`} alt="Icon" /><small>06. Умерено облачно со дожд и грмотевици </small></div>,
		text: "Умерено облачно со дожд и грмотевици ",
		code: 105,
		value: 105,
	},
	{
		img: `${window.location.origin}/w4all_icons/106.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/106.svg`} alt="Icon" /><small>07. Умерено облачно со сончеви периоди</small></div>,
		text: "Умерено облачно со сончеви периоди",
		code: 106,
		value: 106,
	},
	{
		img: `${window.location.origin}/w4all_icons/107.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/107.svg`} alt="Icon" /><small>08. Умерено облачно со услови за слаб дожд </small></div>,
		text: "Умерено облачно со услови за слаб дожд ",
		code: 107,
		value: 107,
	},
	{
		img: `${window.location.origin}/w4all_icons/108.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/108.svg`} alt="Icon" /><small>09. Умерено облачно со услови за слаб дожд и снег</small></div>,
		text: "Умерено облачно со услови за слаб дожд и снег",
		code: 108,
		value: 108,
	},
	{
		img: `${window.location.origin}/w4all_icons/109.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/109.svg`} alt="Icon" /><small>10. Умерено облачно со услови за слаб снег </small></div>,
		text: "Умерено облачно со услови за слаб снег ",
		code: 109,
		value: 109,
	},
	{
		img: `${window.location.origin}/w4all_icons/110.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/110.svg`} alt="Icon" /><small>11. Умерено облачно со врнежи од дожд</small></div>,
		text: "Умерено облачно со врнежи од дожд",
		code: 110,
		value: 110,
	},
	{
		img: `${window.location.origin}/w4all_icons/111.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/111.svg`} alt="Icon" /><small>12. Умерено облачно со дожд и снег</small></div>,
		text: "Умерено облачно со дожд и снег",
		code: 111,
		value: 111,
	},
	{
		img: `${window.location.origin}/w4all_icons/112.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/112.svg`} alt="Icon" /><small>13. Умерено облачно со снег</small></div>,
		text: "Умерено облачно со снег",
		code: 112,
		value: 112,
	},
	{
		img: `${window.location.origin}/w4all_icons/113.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/113.svg`} alt="Icon" /><small>14. Умерено облачно со дожд и грмотевици</small></div>,
		text: "Умерено облачно со дожд и грмотевици",
		code: 113,
		value: 113,
	},
	{
		img: `${window.location.origin}/w4all_icons/114.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/114.svg`} alt="Icon" /><small>15. Облачно</small></div>,
		text: "Облачно",
		code: 114,
		value: 114,
	},
	{
		img: `${window.location.origin}/w4all_icons/115.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/115.svg`} alt="Icon" /><small>16. Облачно со слаб дожд</small></div>,
		text: "Облачно со слаб дожд",
		code: 115,
		value: 115,
	},
	{
		img: `${window.location.origin}/w4all_icons/116.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/116.svg`} alt="Icon" /><small>17. Облачно со слаб дожд и снег</small></div>,
		text: "Облачно со слаб дожд и снег",
		code: 116,
		value: 116,
	},
	{
		img: `${window.location.origin}/w4all_icons/117.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/117.svg`} alt="Icon" /><small>18. Облачно со слаб снег</small></div>,
		text: "Облачно со слаб снег",
		code: 117,
		value: 117,
	},
	{
		img: `${window.location.origin}/w4all_icons/118.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/118.svg`} alt="Icon" /><small>19. Облачно со дожд</small></div>,
		text: "Облачно со дожд",
		code: 118,
		value: 118,
	},
	{
		img: `${window.location.origin}/w4all_icons/119.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/119.svg`} alt="Icon" /><small>20. Облачно со дожд и снег</small></div>,
		text: "Облачно со дожд и снег",
		code: 119,
		value: 119,
	},
	{
		img: `${window.location.origin}/w4all_icons/120.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/120.svg`} alt="Icon" /><small>21. Облачно со снег</small></div>,
		text: "Облачно со снег",
		code: 120,
		value: 120,
	},
	{
		img: `${window.location.origin}/w4all_icons/121.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/121.svg`} alt="Icon" /><small>22. Облачно со обилен дожд</small></div>,
		text: "Облачно со обилен дожд",
		code: 121,
		value: 121,
	},
	{
		img: `${window.location.origin}/w4all_icons/122.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/122.svg`} alt="Icon" /><small>23. Облачно со врнежи од дожд и грмотевици</small></div>,
		text: "Облачно со врнежи од дожд и грмотевици",
		code: 122,
		value: 122,
	},
	{
		img: `${window.location.origin}/w4all_icons/123.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/123.svg`} alt="Icon" /><small>24. Претежно сончево со појава на магла</small></div>,
		text: "Претежно сончево со појава на магла",
		code: 123,
		value: 123,
	},
	{
		img: `${window.location.origin}/w4all_icons/124.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/124.svg`} alt="Icon" /><small>25. Магла </small></div>,
		text: "Магла ",
		code: 124,
		value: 124,
	},
	{
		img: `${window.location.origin}/w4all_icons/125.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/125.svg`} alt="Icon" /><small>26. Ведро </small></div>,
		text: "Ведро ",
		code: 125,
		value: 125,
	},
	{
		img: `${window.location.origin}/w4all_icons/126.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/126.svg`} alt="Icon" /><small>27. Претежно ведро </small></div>,
		text: "Претежно ведро ",
		code: 126,
		value: 126,
	},
	{
		img: `${window.location.origin}/w4all_icons/127.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/127.svg`} alt="Icon" /><small>28. Претежно ведро со зголемена oблачност</small></div>,
		text: "Претежно ведро со зголемена oблачност",
		code: 127,
		value: 127,
	},
	{
		img: `${window.location.origin}/w4all_icons/128.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/128.svg`} alt="Icon" /><small>29. Претежно ведро со услови за дожд </small></div>,
		text: "Претежно ведро со услови за дожд ",
		code: 128,
		value: 128,
	},
	{
		img: `${window.location.origin}/w4all_icons/129.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/129.svg`} alt="Icon" /><small>30. Умерено облачно со услови за дожд</small></div>,
		text: "Умерено облачно со услови за дожд",
		code: 129,
		value: 129,
	},
	{
		img: `${window.location.origin}/w4all_icons/130.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/130.svg`} alt="Icon" /><small>31. Умерено облачно со дожд и грмотевици</small></div>,
		text: "Умерено облачно со дожд и грмотевици",
		code: 130,
		value: 130,
	},
	{
		img: `${window.location.origin}/w4all_icons/131.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/131.svg`} alt="Icon" /><small>32. Умерено облачно</small></div>,
		text: "Умерено облачно",
		code: 131,
		value: 131,
	},
	{
		img: `${window.location.origin}/w4all_icons/132.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/132.svg`} alt="Icon" /><small>33. Умерено облачно со услови за слаб дожд</small></div>,
		text: "Умерено облачно со услови за слаб дожд",
		code: 132,
		value: 132,
	},
	{
		img: `${window.location.origin}/w4all_icons/133.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/133.svg`} alt="Icon" /><small>34. Умерено облачно со услови за слаб дожд и снег</small></div>,
		text: "Умерено облачно со услови за слаб дожд и снег",
		code: 133,
		value: 133,
	},
	{
		img: `${window.location.origin}/w4all_icons/134.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/134.svg`} alt="Icon" /><small>35. Умерено облачно со услови за слаб снег </small></div>,
		text: "Умерено облачно со услови за слаб снег ",
		code: 134,
		value: 134,
	},
	{
		img: `${window.location.origin}/w4all_icons/135.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/135.svg`} alt="Icon" /><small>36. Умерено облачно со врнежи од дожд</small></div>,
		text: "Умерено облачно со врнежи од дожд",
		code: 135,
		value: 135,
	},
	{
		img: `${window.location.origin}/w4all_icons/136.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/136.svg`} alt="Icon" /><small>37. Умерено облачно со дожд и снег</small></div>,
		text: "Умерено облачно со дожд и снег",
		code: 136,
		value: 136,
	},
	{
		img: `${window.location.origin}/w4all_icons/137.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/137.svg`} alt="Icon" /><small>38. Умерено облачно со снег</small></div>,
		text: "Умерено облачно со снег",
		code: 137,
		value: 137,
	},
	{
		img: `${window.location.origin}/w4all_icons/138.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/138.svg`} alt="Icon" /><small>39. Умерено облачно со дожд и грмотевици</small></div>,
		text: "Умерено облачно со дожд и грмотевици",
		code: 138,
		value: 138,
	},
	{
		img: `${window.location.origin}/w4all_icons/139.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/139.svg`} alt="Icon" /><small>40. Облачно</small></div>,
		text: "Облачно",
		code: 139,
		value: 139,
	},
	{
		img: `${window.location.origin}/w4all_icons/140.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/140.svg`} alt="Icon" /><small>41. Облачно со слаб дожд</small></div>,
		text: "Облачно со слаб дожд",
		code: 140,
		value: 140,
	},
	{
		img: `${window.location.origin}/w4all_icons/141.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/141.svg`} alt="Icon" /><small>42. Облачно со слаб дожд и снег</small></div>,
		text: "Облачно со слаб дожд и снег",
		code: 141,
		value: 141,
	},
	{
		img: `${window.location.origin}/w4all_icons/142.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/142.svg`} alt="Icon" /><small>43. Облачно со слаб снег</small></div>,
		text: "Облачно со слаб снег",
		code: 142,
		value: 142,
	},
	{
		img: `${window.location.origin}/w4all_icons/143.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/143.svg`} alt="Icon" /><small>44. Облачно со дожд</small></div>,
		text: "Облачно со дожд",
		code: 143,
		value: 143,
	},
	{
		img: `${window.location.origin}/w4all_icons/144.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/144.svg`} alt="Icon" /><small>45. Облачно со дожд и снег</small></div>,
		text: "Облачно со дожд и снег",
		code: 144,
		value: 144,
	},
	{
		img: `${window.location.origin}/w4all_icons/145.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/145.svg`} alt="Icon" /><small>46. Облачно со снег</small></div>,
		text: "Облачно со снег",
		code: 145,
		value: 145,
	},
	{
		img: `${window.location.origin}/w4all_icons/146.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/146.svg`} alt="Icon" /><small>47. Облачно со обилен дожд</small></div>,
		text: "Облачно со обилен дожд",
		code: 146,
		value: 146,
	},
	{
		img: `${window.location.origin}/w4all_icons/147.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/147.svg`} alt="Icon" /><small>48. Облачно со врнежи од дожд и грмотевици</small></div>,
		text: "Облачно со врнежи од дожд и грмотевици",
		code: 147,
		value: 147,
	},
	{
		img: `${window.location.origin}/w4all_icons/148.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/148.svg`} alt="Icon" /><small>49. Претежно ведро со појава на магла</small></div>,
		text: "Претежно ведро со појава на магла",
		code: 148,
		value: 148,
	},
	{
		img: `${window.location.origin}/w4all_icons/149.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/149.svg`} alt="Icon" /><small>50. Магла</small></div>,
		text: "Магла",
		code: 149,
		value: 149,
	},
];

export const W4ALL_ICONS = {
	100: {
		img: `${window.location.origin}/w4all_icons/100.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/100.svg`} alt="Icon" /><small>01. Сончево</small></div>,
		value: "Сончево"
	},
	101: {
		img: `${window.location.origin}/w4all_icons/101.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/101.svg`} alt="Icon" /><small>02. Претежно сончево</small></div>,
		value: "Претежно сончево"
	},
	102: {
		img: `${window.location.origin}/w4all_icons/102.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/102.svg`} alt="Icon" /><small>03. Претежно сончево со зголемена oблачност</small></div>,
		value: "Претежно сончево со зголемена oблачност"
	},
	103: {
		img: `${window.location.origin}/w4all_icons/103.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/103.svg`} alt="Icon" /><small>04. Претежно сончево со услови за дожд </small></div>,
		value: "Претежно сончево со услови за дожд "
	},
	104: {
		img: `${window.location.origin}/w4all_icons/104.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/104.svg`} alt="Icon" /><small>05. Умерено облачно со услови за дожд</small></div>,
		value: "Умерено облачно со услови за дожд"
	},
	105: {
		img: `${window.location.origin}/w4all_icons/105.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/105.svg`} alt="Icon" /><small>06. Умерено облачно со дожд и грмотевици </small></div>,
		value: "Умерено облачно со дожд и грмотевици "
	},
	106: {
		img: `${window.location.origin}/w4all_icons/106.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/106.svg`} alt="Icon" /><small>07. Умерено облачно со сончеви периоди</small></div>,
		value: "Умерено облачно со сончеви периоди"
	},
	107: {
		img: `${window.location.origin}/w4all_icons/107.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/107.svg`} alt="Icon" /><small>08. Умерено облачно со услови за слаб дожд </small></div>,
		value: "Умерено облачно со услови за слаб дожд "
	},
	108: {
		img: `${window.location.origin}/w4all_icons/108.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/108.svg`} alt="Icon" /><small>09. Умерено облачно со услови за слаб дожд и снег</small></div>,
		value: "Умерено облачно со услови за слаб дожд и снег"
	},
	109: {
		img: `${window.location.origin}/w4all_icons/109.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/109.svg`} alt="Icon" /><small>10. Умерено облачно со услови за слаб снег </small></div>,
		value: "Умерено облачно со услови за слаб снег "
	},
	110: {
		img: `${window.location.origin}/w4all_icons/110.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/110.svg`} alt="Icon" /><small>11. Умерено облачно со врнежи од дожд</small></div>,
		value: "Умерено облачно со врнежи од дожд"
	},
	111: {
		img: `${window.location.origin}/w4all_icons/111.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/111.svg`} alt="Icon" /><small>12. Умерено облачно со дожд и снег</small></div>,
		value: "Умерено облачно со дожд и снег"
	},
	112: {
		img: `${window.location.origin}/w4all_icons/112.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/112.svg`} alt="Icon" /><small>13. Умерено облачно со снег</small></div>,
		value: "Умерено облачно со снег"
	},
	113: {
		img: `${window.location.origin}/w4all_icons/113.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/113.svg`} alt="Icon" /><small>14. Умерено облачно со дожд и грмотевици</small></div>,
		value: "Умерено облачно со дожд и грмотевици"
	},
	114: {
		img: `${window.location.origin}/w4all_icons/114.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/114.svg`} alt="Icon" /><small>15. Облачно</small></div>,
		value: "Облачно"
	},
	115: {
		img: `${window.location.origin}/w4all_icons/115.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/115.svg`} alt="Icon" /><small>16. Облачно со слаб дожд</small></div>,
		value: "Облачно со слаб дожд"
	},
	116: {
		img: `${window.location.origin}/w4all_icons/116.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/116.svg`} alt="Icon" /><small>17. Облачно со слаб дожд и снег</small></div>,
		value: "Облачно со слаб дожд и снег"
	},
	117: {
		img: `${window.location.origin}/w4all_icons/117.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/117.svg`} alt="Icon" /><small>18. Облачно со слаб снег</small></div>,
		value: "Облачно со слаб снег"
	},
	118: {
		img: `${window.location.origin}/w4all_icons/118.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/118.svg`} alt="Icon" /><small>19. Облачно со дожд</small></div>,
		value: "Облачно со дожд"
	},
	119: {
		img: `${window.location.origin}/w4all_icons/119.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/119.svg`} alt="Icon" /><small>20. Облачно со дожд и снег</small></div>,
		value: "Облачно со дожд и снег"
	},
	120: {
		img: `${window.location.origin}/w4all_icons/120.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/120.svg`} alt="Icon" /><small>21. Облачно со снег</small></div>,
		value: "Облачно со снег"
	},
	121: {
		img: `${window.location.origin}/w4all_icons/121.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/121.svg`} alt="Icon" /><small>22. Облачно со обилен дожд</small></div>,
		value: "Облачно со обилен дожд"
	},
	122: {
		img: `${window.location.origin}/w4all_icons/122.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/122.svg`} alt="Icon" /><small>23. Облачно со врнежи од дожд и грмотевици</small></div>,
		value: "Облачно со врнежи од дожд и грмотевици"
	},
	123: {
		img: `${window.location.origin}/w4all_icons/123.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/123.svg`} alt="Icon" /><small>24. Претежно сончево со појава на магла</small></div>,
		value: "Претежно сончево со појава на магла"
	},
	124: {
		img: `${window.location.origin}/w4all_icons/124.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/124.svg`} alt="Icon" /><small>25. Магла </small></div>,
		value: "Магла "
	},
	125: {
		img: `${window.location.origin}/w4all_icons/125.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/125.svg`} alt="Icon" /><small>26. Ведро </small></div>,
		value: "Ведро "
	},
	126: {
		img: `${window.location.origin}/w4all_icons/126.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/126.svg`} alt="Icon" /><small>27. Претежно ведро </small></div>,
		value: "Претежно ведро "
	},
	127: {
		img: `${window.location.origin}/w4all_icons/127.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/127.svg`} alt="Icon" /><small>28. Претежно ведро со зголемена oблачност</small></div>,
		value: "Претежно ведро со зголемена oблачност"
	},
	128: {
		img: `${window.location.origin}/w4all_icons/128.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/128.svg`} alt="Icon" /><small>29. Претежно ведро со услови за дожд </small></div>,
		value: "Претежно ведро со услови за дожд "
	},
	129: {
		img: `${window.location.origin}/w4all_icons/129.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/129.svg`} alt="Icon" /><small>30. Умерено облачно со услови за дожд</small></div>,
		value: "Умерено облачно со услови за дожд"
	},
	130: {
		img: `${window.location.origin}/w4all_icons/130.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/130.svg`} alt="Icon" /><small>31. Умерено облачно со дожд и грмотевици</small></div>,
		value: "Умерено облачно со дожд и грмотевици"
	},
	131: {
		img: `${window.location.origin}/w4all_icons/131.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/131.svg`} alt="Icon" /><small>32. Умерено облачно</small></div>,
		value: "Умерено облачно"
	},
	132: {
		img: `${window.location.origin}/w4all_icons/132.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/132.svg`} alt="Icon" /><small>33. Умерено облачно со услови за слаб дожд</small></div>,
		value: "Умерено облачно со услови за слаб дожд"
	},
	133: {
		img: `${window.location.origin}/w4all_icons/133.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/133.svg`} alt="Icon" /><small>34. Умерено облачно со услови за слаб дожд и снег</small></div>,
		value: "Умерено облачно со услови за слаб дожд и снег"
	},
	134: {
		img: `${window.location.origin}/w4all_icons/134.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/134.svg`} alt="Icon" /><small>35. Умерено облачно со услови за слаб снег </small></div>,
		value: "Умерено облачно со услови за слаб снег "
	},
	135: {
		img: `${window.location.origin}/w4all_icons/135.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/135.svg`} alt="Icon" /><small>36. Умерено облачно со врнежи од дожд</small></div>,
		value: "Умерено облачно со врнежи од дожд"
	},
	136: {
		img: `${window.location.origin}/w4all_icons/136.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/136.svg`} alt="Icon" /><small>37. Умерено облачно со дожд и снег</small></div>,
		value: "Умерено облачно со дожд и снег"
	},
	137: {
		img: `${window.location.origin}/w4all_icons/137.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/137.svg`} alt="Icon" /><small>38. Умерено облачно со снег</small></div>,
		value: "Умерено облачно со снег"
	},
	138: {
		img: `${window.location.origin}/w4all_icons/138.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/138.svg`} alt="Icon" /><small>39. Умерено облачно со дожд и грмотевици</small></div>,
		value: "Умерено облачно со дожд и грмотевици"
	},
	139: {
		img: `${window.location.origin}/w4all_icons/139.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/139.svg`} alt="Icon" /><small>40. Облачно</small></div>,
		value: "Облачно"
	},
	140: {
		img: `${window.location.origin}/w4all_icons/140.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/140.svg`} alt="Icon" /><small>41. Облачно со слаб дожд</small></div>,
		value: "Облачно со слаб дожд"
	},
	141: {
		img: `${window.location.origin}/w4all_icons/141.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/141.svg`} alt="Icon" /><small>42. Облачно со слаб дожд и снег</small></div>,
		value: "Облачно со слаб дожд и снег"
	},
	142: {
		img: `${window.location.origin}/w4all_icons/142.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/142.svg`} alt="Icon" /><small>43. Облачно со слаб снег</small></div>,
		value: "Облачно со слаб снег"
	},
	143: {
		img: `${window.location.origin}/w4all_icons/143.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/143.svg`} alt="Icon" /><small>44. Облачно со дожд</small></div>,
		value: "Облачно со дожд"
	},
	144: {
		img: `${window.location.origin}/w4all_icons/144.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/144.svg`} alt="Icon" /><small>45. Облачно со дожд и снег</small></div>,
		value: "Облачно со дожд и снег"
	},
	145: {
		img: `${window.location.origin}/w4all_icons/145.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/145.svg`} alt="Icon" /><small>46. Облачно со снег</small></div>,
		value: "Облачно со снег"
	},
	146: {
		img: `${window.location.origin}/w4all_icons/146.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/146.svg`} alt="Icon" /><small>47. Облачно со обилен дожд</small></div>,
		value: "Облачно со обилен дожд"
	},
	147: {
		img: `${window.location.origin}/w4all_icons/147.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/147.svg`} alt="Icon" /><small>48. Облачно со врнежи од дожд и грмотевици</small></div>,
		value: "Облачно со врнежи од дожд и грмотевици"
	},
	148: {
		img: `${window.location.origin}/w4all_icons/148.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/148.svg`} alt="Icon" /><small>49. Претежно ведро со појава на магла</small></div>,
		value: "Претежно ведро со појава на магла"
	},
	149: {
		img: `${window.location.origin}/w4all_icons/149.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/w4all_icons/149.svg`} alt="Icon" /><small>Магла</small></div>,
		value: "Магла"
	},
}