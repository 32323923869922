import React from 'react'
import Video from '../components/video'
import map from './assets/map.mp4'
import Rain from '../../assets/icons/rain.svg'
import Sun from '../../assets/icons/clear-day.svg';
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info';
import moment from 'moment';

const Macedonia3Days = ({data}) => {

	const fiveDays = [1, 2, 3];

	return (
		<div className="delayed-screen">
			<div className="video-bg">
				<Video src={map} muted />
			</div>

			<div className='temp'>
				<div className="temp-header">
					Македонија во наредните&nbsp; <b> 3 дена</b>
				</div>

				<div className="temp-boxes">
					{ fiveDays.map((i) => 
						<div className="temp-box">
							<div className="temp-box_header">{moment(data['Скопје']?.forecast[i]?.date*1000).format('dddd')}</div>
							<div className='temp-box_content'>
								<div className="temp-box_icon">
									<img src={getWeatherIcon( data, 'Гевгелија', i )} alt='Icon' />
								</div>
								<div className="temp-box_temp">
									<div className="daily">
										<div className="min"><GetWeatherMinTemp city='Маврово' day={i} />°</div>
										<div><span></span></div>
										<div className="max"><GetWeatherMaxTemp city='Маврово' day={i} />°</div>
									</div>
									<div className="night">
										<div className="min"><GetWeatherMinTemp city='Гевгелија' day={i} />°</div>
										<div><span></span></div>
										<div className="max"><GetWeatherMaxTemp city='Гевгелија' day={i} />°</div>
									</div>
								</div>
								<div className="temp-box_desc">
									<p>Претежно сончево со локална облачност</p>
								</div>
							</div>
						</div>
					)}

				</div>
			</div>
		</div>
	)
}

export default Macedonia3Days