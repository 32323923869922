import { IconArrowLeft, IconCircleCheck, IconCircleX, IconPencil } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import EditCustomerModal from '../../modals/edit-customer.modal'
import SuspendCustomerModal from '../../modals/suspend-customer.modal'
import EditButton from '../buttons/edit-button.component'
import moment from 'moment'

const CustomerSubheader = ({user, queryCustomer, isAdmin}) => {
	// ----------------------------------------------------------
	// Modals State
	// ----------------------------------------------------------
	const [suspendUserModal, setSuspendUserModal] = useState(false)
	const [editUserModal, setEditUserModal] = useState(false)
	const [userAction, setUserAction] = useState(false)


	// ----------------------------------------------------------
	// Handling escape keyboard press for closing all modals
	// ----------------------------------------------------------
	useEffect( () => {
		if ( suspendUserModal === true || editUserModal === true )
		{
			document.addEventListener('keydown', (e) => {
				if ( e.key === 'Escape' )
				{
					setSuspendUserModal(false)
					setEditUserModal(false)
				}
			}, false)
		}
	}, [suspendUserModal, editUserModal] );

	const navigate = useNavigate();

	return (
		<>
			<div className="subheader row">
				<div className="subheader-left col-sm">
					{ isAdmin ? <>
						<Link className="btn btn-outline-primary border-transparent" rel="noopener" to="/customers">
							<IconArrowLeft size={18} className="me-2" />
							Назад
						</Link>
						<div className="subheader-separator"></div>
					</> : '' }
					<span className="text-muted fst-italic me-2">Последна промена:</span>
					<span className="fw-bold">{user?.updated_at ? moment(user?.updated_at).fromNow() : '...'}</span>
				</div>
				{ isAdmin ? 
				<div className="subheader-right col-sm-auto">
					<EditButton onClick={() => setEditUserModal(true) } />
					{ user?.status ? <button type="button" className="btn btn-light-danger" onClick={() => { setSuspendUserModal(true); setUserAction(0); } }><IconCircleX className="me-2 svg-icon btn-icon" />Суспендирај</button> : <button type="button" className="btn btn-light-success" onClick={() => { setSuspendUserModal(true); setUserAction(1); } }><IconCircleCheck className="me-2 svg-icon btn-icon" />Активирај</button> }
				</div>
				: 
				<div className="subheader-right col-sm-auto">
					<EditButton onClick={() => navigate('/settings') } />
				</div>
				}
			</div>

			{/* Suspend User Modal */}
			<CSSTransition in={suspendUserModal} timeout={0} classNames="fade">
				<SuspendCustomerModal user={user} userAction={userAction} refetchCustomer={queryCustomer.refetch} setSuspendUserModal={setSuspendUserModal} />
			</CSSTransition>
					
			
			{/* Edit User Modal */}
			<CSSTransition in={editUserModal} timeout={0} classNames="fade">
				<EditCustomerModal user={user} refetchCustomer={queryCustomer.refetch} setEditUserModal={setEditUserModal} />
			</CSSTransition>
		</>
	)
}

export default CustomerSubheader