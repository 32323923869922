const DashboardInfoCard = ({icon, text, iconBGClass, data}) => {
	return (
		<div className="card card-sm">
			<div className="card-body">
				<div className="row align-items-center">
					<div className="col-auto">
						<span className={`p-3 rounded ${iconBGClass}`}>
							{icon}
						</span>
					</div>
					<div className="col">
						<div className="fs-5 fw-bold">{data}</div>
						<div className="text-muted">{text}</div>
					</div>
				</div>
			</div>
		</div>
		
	)
}

export default DashboardInfoCard