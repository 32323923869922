import React from 'react'
import Balkan from './assets/balkan.png'
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info';
import moment from 'moment';

const MapBalkan = ({data}) => {
	return (
		<div className="delayed-screen">
			<div className="balkan">
				<div className="balkan-bg">
					<img src={Balkan} alt="Balkan" />
				</div>

				<div className="cities">
					<div className="current-date text-white" style={{ position: "absolute", top: 70, right: 100 }}>
						<h1>МАКЕДОНИЈА {moment().format('DD.MM.Y')}</h1>
						<h3>weather4all.com.mk</h3>
					</div>

					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080">
						<defs>
							<clipPath id="balkan">
							<rect id="Rectangle_5" data-name="Rectangle 5" width="1920" height="1080" fill="#fff"/>
							</clipPath>
							<filter id="Сараево" x="662.5" y="433.5" width="101" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Загреб" x="439.5" y="136.5" width="84" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-2"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-2"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Љубљана" x="249.5" y="98.5" width="119" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-3"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-3"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Подгорица" x="766.5" y="631.5" width="135" height="36" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-4"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-4"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Приштина" x="979.5" y="641.5" width="123" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-5"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-5"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Белград" x="926.5" y="314.5" width="101" height="36" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-6"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-6"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Софија" x="1339.5" y="567.5" width="92" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-7"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-7"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Тирана" x="856.5" y="811.5" width="90" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-8"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-8"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Скопје2" x="1059.5" y="763.5" width="86" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-9"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-9"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Солун" x="1233.5" y="920.5" width="80" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-10"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-10"/>
							<feComposite in="SourceGraphic"/>
							</filter>
						</defs>
						<g id="cities" data-name="Mask Group 3" clip-path="url(#balkan)">
							<g id="Group_10" data-name="Group 10" transform="translate(-137 29)">
							<g id="box" transform="translate(593 -410)">
								<rect id="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max">
								<rect id="Rectangle_4" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Сараево" day={0} />°</tspan></text>
								</g>
								<g id="min">
								<rect id="Rectangle_6" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Сараево" day={0} />°</tspan></text>
								</g>
								<g id="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Сараево', 1)} />
								</g>
								<g id="title">
								<rect id="Rectangle_2" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -456, 381)" filter="url(#Сараево)">
									<text id="Сараево-2" data-name="Сараево" transform="translate(670 455)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">САРАЕВО</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-2" data-name="box" transform="translate(370 -707)">
								<rect id="bg-2" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-2" data-name="max">
								<rect id="Rectangle_4-2" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_2" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Загреб" day={0} />°</tspan></text>
								</g>
								<g id="min-2" data-name="min">
								<rect id="Rectangle_6-2" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_2" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Загреб" day={0} />°</tspan></text>
								</g>
								<g id="icon-2" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Загреб', 1)} />
								</g>
								<g id="title-2" data-name="title">
								<rect id="Rectangle_2-2" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -233, 678)" filter="url(#Загреб)">
									<text id="Загреб-2" data-name="Загреб" transform="translate(447 158)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ЗАГРЕБ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-3" data-name="box" transform="translate(180 -745)">
								<rect id="bg-3" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-3" data-name="max">
								<rect id="Rectangle_4-3" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_3" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Љубљана" day={0} />°</tspan></text>
								</g>
								<g id="min-3" data-name="min">
								<rect id="Rectangle_6-3" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_3" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Љубљана" day={0} />°</tspan></text>
								</g>
								<g id="icon-3" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Љубљана', 1)} />
								</g>
								<g id="title-3" data-name="title">
								<rect id="Rectangle_2-3" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -43, 716)" filter="url(#Љубљана)">
									<text id="Љубљана-2" data-name="Љубљана" transform="translate(257 120)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ЉУБЉАНА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-4" data-name="box" transform="translate(697 -212)">
								<rect id="bg-4" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-4" data-name="max">
								<rect id="Rectangle_4-4" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_4" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Подгорица" day={0} />°</tspan></text>
								</g>
								<g id="min-4" data-name="min">
								<rect id="Rectangle_6-4" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_4" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Подгорица" day={0} />°</tspan></text>
								</g>
								<g id="icon-4" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Подгорица', 1)} />
								</g>
								<g id="title-4" data-name="title">
								<rect id="Rectangle_2-4" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -560, 183)" filter="url(#Подгорица)">
									<text id="Подгорица-2" data-name="Подгорица" transform="translate(774 653)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ПОДГОРИЦА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-5" data-name="box" transform="translate(910 -202)">
								<rect id="bg-5" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-5" data-name="max">
								<rect id="Rectangle_4-5" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_5" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Приштина" day={0} />°</tspan></text>
								</g>
								<g id="min-5" data-name="min">
								<rect id="Rectangle_6-5" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_5" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Приштина" day={0} />°</tspan></text>
								</g>
								<g id="icon-5" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Приштина', 1)} />
								</g>
								<g id="title-5" data-name="title">
								<rect id="Rectangle_2-5" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -773, 173)" filter="url(#Приштина)">
									<text id="Приштина-2" data-name="Приштина" transform="translate(987 663)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ПРИШТИНА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-6" data-name="box" transform="translate(857 -529)">
								<rect id="bg-6" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-6" data-name="max">
								<rect id="Rectangle_4-6" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_6" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Белград" day={0} />°</tspan></text>
								</g>
								<g id="min-6" data-name="min">
								<rect id="Rectangle_6-6" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_6" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Белград" day={0} />°</tspan></text>
								</g>
								<g id="icon-6" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Белград', 1)} />
								</g>
								<g id="title-6" data-name="title">
								<rect id="Rectangle_2-6" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -720, 500)" filter="url(#Белград)">
									<text id="Белград-2" data-name="Белград" transform="translate(934 336)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">БЕЛГРАД</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-7" data-name="box" transform="translate(1270 -276)">
								<rect id="bg-7" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-7" data-name="max">
								<rect id="Rectangle_4-7" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_7" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Софија" day={0} />°</tspan></text>
								</g>
								<g id="min-7" data-name="min">
								<rect id="Rectangle_6-7" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_7" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Софија" day={0} />°</tspan></text>
								</g>
								<g id="icon-7" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Софија', 1)} />
								</g>
								<g id="title-7" data-name="title">
								<rect id="Rectangle_2-7" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -1133, 247)" filter="url(#Софија)">
									<text id="Софија-2" data-name="Софија" transform="translate(1347 589)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">СОФИЈА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-8" data-name="box" transform="translate(787 -32)">
								<rect id="bg-8" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-8" data-name="max">
								<rect id="Rectangle_4-8" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_8" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Тирана" day={0} />°</tspan></text>
								</g>
								<g id="min-8" data-name="min">
								<rect id="Rectangle_6-8" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_8" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Тирана" day={0} />°</tspan></text>
								</g>
								<g id="icon-8" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Тирана', 1)} />
								</g>
								<g id="title-8" data-name="title">
								<rect id="Rectangle_2-8" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -650, 3)" filter="url(#Тирана)">
									<text id="Тирана-2" data-name="Тирана" transform="translate(864 833)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ТИРАНА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-9" data-name="box" transform="translate(990 -80)">
								<rect id="bg-9" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-9" data-name="max">
								<rect id="Rectangle_4-9" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_9" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Скопје" day={0} />°</tspan></text>
								</g>
								<g id="min-9" data-name="min">
								<rect id="Rectangle_6-9" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_9" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Скопје" day={0} />°</tspan></text>
								</g>
								<g id="icon-9" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Скопје', 1)} />
								</g>
								<g id="title-9" data-name="title">
								<rect id="Rectangle_2-9" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -853, 51)" filter="url(#Скопје2)">
									<text id="Скопје-2" data-name="Скопје" transform="translate(1067 785)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">СКОПЈЕ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-10" data-name="box" transform="translate(1164 77)">
								<rect id="bg-10" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255,255,255,.2)"/>
								<g id="max-10" data-name="max">
								<rect id="Rectangle_4-10" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_10" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city="Солун" day={0} />°</tspan></text>
								</g>
								<g id="min-10" data-name="min">
								<rect id="Rectangle_6-10" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_10" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city="Солун" day={0} />°</tspan></text>
								</g>
								<g id="icon-10" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Солун', 1)} />
								</g>
								<g id="title-10" data-name="title">
								<rect id="Rectangle_2-10" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -1027, -106)" filter="url(#Солун)">
									<text id="Солун-2" data-name="Солун" transform="translate(1241 942)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">СОЛУН</tspan></text>
								</g>
								</g>
							</g>
							</g>
						</g>
					</svg>
				</div>
			</div>
		</div>
	)
}

export default MapBalkan
