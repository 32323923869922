import React from 'react'
import Page from "../../layouts/page/page.component"
import ChangeAvatar from '../../components/settings/change-avatar.component'
import PersonalInformation from '../../components/settings/personal-information.component'
import PasswordChange from '../../components/settings/password-change.component'

const Settings = () => {
	return (
		<Page>
			<div className="row">
				<div className="col-sm-12">
					<ChangeAvatar />
				</div>

				<div className="col-sm-12 col-lg-7">
					<PersonalInformation />
				</div>
				<div className="col-sm-12 col-lg-5">
					<PasswordChange />
				</div>
			</div>
		</Page>
	)
}

export default Settings