import React, { useEffect, useState } from 'react'
import Weather14ForecastCard from './weather-forecast-card/weather-14-forecast-card.component'

const WeeklyTemperature = ({data}) => {

	const [ forecastData, setForecastData ] = useState(data?.data?.forecast);

	return (
		<div className="row g-4">
			{ forecastData !== undefined && forecastData.length > 0 ?
				forecastData.map( (item, key) => <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" key={key}><Weather14ForecastCard index={key} setForecastData={setForecastData} forecastData={forecastData} /></div> ) : 
				<p className='col-sm-12 text-center'>Не се пронајдени податоци</p>
			}
		</div>
	)
}

export default WeeklyTemperature