import React from 'react'

const CardHeader = ({icon, title}) => {

	return (
		<div className="card-header">
			<div className="card-label">
				{icon}
				<div className="card-title-wrapper ms-2">
					<h5 className="card-title">{title}</h5>
				</div>
			</div>
		</div>
	)
}

export default CardHeader