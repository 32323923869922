import axios from "axios";
import {PRODUCTION_API} from '../config'

// ----------------------------------------------------------
// Get all customers
// ----------------------------------------------------------
export const getCustomers = async (token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.get(`${PRODUCTION_API}/users`, config);
}

// ----------------------------------------------------------
// Get single Customer Profile
// ----------------------------------------------------------
export const getCustomer = async (token, userID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/users/${userID}`, config);
}

// ----------------------------------------------------------
// Edit Customer Profile
// ----------------------------------------------------------
export const editCustomer = async (token, formData, userID) => {
	const config = {
		headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
	};
	return await axios.post(`${PRODUCTION_API}/users/edit/${userID}`, formData, config);
}