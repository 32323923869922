import { IconCreditCard, IconPrinter } from '@tabler/icons-react'
import moment from 'moment'
import React, { useEffect } from 'react'

const GeneratedInvoice = ({invoice}) => {

	console.log(invoice)

	const handlePrint = () => {
		// const printContents = document.getElementById('print').innerHTML;
		// const originalContents = document.body.innerHTML;

		// document.body.innerHTML = printContents;

		window.print();

		// document.body.innerHTML = originalContents;
	}

	return (
		<div className="card px-5 py-2 print" id="print">
			<div className="card-header">
				<div className="card-label">
					<h2>Logo</h2>
				</div>
				<div className="card-actions">
					<h1 className="mb-0">Фактура</h1>
				</div>
			</div>

			<div className="card-body pt-0">
				<div className="row border-top border-bottom py-3">
					<div className="col-sm-6">
						<p className="lead mb-0"><b className="fw-semibold">Дата:</b> {moment(invoice.created_at).format('D/MM/Y')}</p>
					</div>

					<div className="col-sm-6 text-end">
						<p className="lead mb-0"><b className="fw-semibold">Фактура бр.</b> #{invoice.id}</p>
					</div>
				</div>

				<div className="row my-5 fs-5">
					<div className="col-sm-6">
						<span className="fw-bold">Фактурирана од:</span>
						{/* <p>{invoice.receiver_info}</p> */}
						<p>
							Weather4All<br />
							ul. Partizanska no. 10<br />
							1000, Skopje,<br />
							Macedonia
						</p>
					</div>
					<div className="col-sm-6 text-end">
						<span className="fw-bold">Фактурирана до:</span>
						<br /><br />
						{invoice.user.first_name} {invoice.user.last_name}
						<br />
						{invoice.user.address}
						<br />
						{invoice.user.zip}, {invoice.user.city}
						<br />
						Macedonia
					</div>
				</div>

				{ invoice.note ? 
				<div className="row my-5 fs-5">
					<div className="col-sm-12">
						<p><span class="fw-bold">Забелешка:</span><br />{invoice.note}</p>
					</div>
				</div> : '' }

				<div className="row my-5 fs-5">
					<div className="col-12">
						<table className="table table-modern fs-5">
							<thead>
								<tr>
									<th>#</th>
									<th>Сервис</th>
									<th>Текст</th>
									<th className="text-center">Цена</th>
									<th className="text-center">Количина</th>
									<th className="text-end">Износ</th>
								</tr>
							</thead>
							<tbody>
								{ invoice.lines.map( (line, i) => 
								<tr>
									<td>{++i}</td>
									<td>{line.name}</td>
									<td>{line.description}</td>
									<td className="text-center">{line.amount.toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</td>
									<td className="text-center">{line.quantity}</td>
									<td className="text-end">{(line.quantity*line.amount).toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</td>
								</tr>
								) }
								<tr className="">
									<th colSpan={5} className="text-end">Под вкупно:</th>
									<th className="text-end">{invoice.total.toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</th>
								</tr>
								<tr className="">
									<th colSpan={5} className="text-end">Попуст:</th>
									<th className="text-end">{invoice.discount}%</th>
								</tr>
								<tr className="">
									<th colSpan={5} className="text-end">ДДВ:</th>
									<th className="text-end">{invoice.tax}</th>
								</tr>
								<tr className="">
									<th colSpan={5} className="text-end">Вкупно:</th>
									<th className="text-end">{invoice.total.toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="card-footer">
				<div className="card-footer-left">
					ЗАБЕЛЕШКА: Ова е потврда генерирана од компјутер и не бара физички потпис.
				</div>

				<div className="card-footer-right">
					<button type="button" onClick={handlePrint} className="btn btn-outline-primary"><IconPrinter size={18} /> Принтај</button>
					<button type="button" className="btn btn-primary"><IconCreditCard size={18} /> Плати</button>
				</div>
			</div>
		</div>
	)
}

export default GeneratedInvoice