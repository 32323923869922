import React, { useEffect, useState } from 'react'

import Balkan from './assets/balkan.jpg';
import moment from 'moment';

import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import { getWeatherIcon, GetWeatherMaxTemp } from '../components/weather_info';

const BalkanMap = ({data}) => {

	return (
		<>
			<div className="balkan">
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
					<defs>
						<radialGradient id="radial-gradient" cx="0.267" cy="0.199" r="0.796" gradientTransform="matrix(0.565, 0.825, -0.825, 0.565, 0.28, -0.134)" gradientUnits="objectBoundingBox">
							<stop offset="0" stopColor="#eb8332"/>
							<stop offset="1" stopColor="#d12c1f"/>
						</radialGradient>
						<clipPath id="clip-Custom_Size_3">
							<rect width="1920" height="1080"/>
						</clipPath>
					</defs>
					<g id="Custom_Size_3" data-name="Custom Size – 3" clipPath="url(#clip-Custom_Size_3)">
						<rect width="1920" height="1080"/>
						<image id="bg" data-name="Image 3" width="100%" href={Balkan} />
						<g id="city-wrap" data-name="Group 7">
							<text id="Љубљана" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-109.779" y="0">Љубљана</tspan></text>
							<g id="temperature">
								<circle id="Ellipse_2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Љубљана" day={0} />°</tspan></text>
							</g>
							<g id="pin" transform="translate(4471 82)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Љубљана', 0)} transform="translate(535 25)" />
						</g>
						<g id="city-wrap" data-name="Group 8" transform="translate(213 49)">
							<text id="Загреб" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-82.88" y="0">Загреб</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_2" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Загреб" day={0} />°</tspan></text>
							</g>
							<g id="pin-2" data-name="pin" transform="translate(4518 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Загреб', 0)} transform="translate(545 25)" />
						</g>
						<g id="city-wrap" data-name="Group 9" transform="translate(630 150)">
							<text id="Белград" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-99.641" y="0">Белград</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-3" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_3" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Белград" day={0} />°</tspan></text>
							</g>
							<g id="pin-3" data-name="pin" transform="translate(4516 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Белград', 0)} transform="translate(537 25)" />
						</g>
						<g id="city-wrap" data-name="Group 10" transform="translate(365 202)">
							<text id="Сараево" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-99.345" y="0">Сараево</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-4" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_4" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Сараево" day={0} />°</tspan></text>
							</g>
							<g id="pin-4" data-name="pin" transform="translate(4517 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Сараево', 0)} transform="translate(540 25)" />
						</g>
						<g id="city-wrap" data-name="Group 11" transform="translate(509 404)">
							<text id="Подгорица" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-129.611" y="0">Подгорица</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-5" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_5" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Подгорица" day={0} />°</tspan></text>
							</g>
							<g id="pin-5" data-name="pin" transform="translate(4501 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Подгорица', 0)} transform="translate(520 25)" />
						</g>
						<g id="city-wrap" data-name="Group 14" transform="translate(757 420)">
							<text id="Приштина" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-121.249" y="0">Приштина</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-6" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_6" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Приштина" day={0} />°</tspan></text>
							</g>
							<g id="pin-6" data-name="pin" transform="translate(4506 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Приштина', 0)} transform="translate(530 25)" />
						</g>
						<g id="city-wrap" data-name="Group 15" transform="translate(552 620)">
							<text id="Тирана" transform="translate(648 156)" fill="#fff" fontSize="37"><tspan x="-84.36" y="0">Тирана</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-7" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_7" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Тирана" day={0} />°</tspan></text>
							</g>
							<g id="pin-7" data-name="pin" transform="translate(4524 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Тирана', 0)} transform="translate(540 25)" />
						</g>
						<g id="city-wrap" data-name="Group 12" transform="translate(933 704)">
							<text id="Солун" transform="translate(647 156)" fill="#fff" fontSize="37"><tspan x="-70.892" y="0">Солун</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-8" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_8" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Солун" day={0} />°</tspan></text>
							</g>
							<g id="pin-8" data-name="pin" transform="translate(4529 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Солун', 0)} transform="translate(550 25)" />
						</g>
						<g id="city-wrap" data-name="Group 16" transform="translate(1050 457)">
							<text id="Софија" transform="translate(647 156)" fill="#fff" fontSize="37"><tspan x="-86.247" y="0">Софија</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-9" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_9" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Софија" day={0} />°</tspan></text>
							</g>
							<g id="pin-9" data-name="pin" transform="translate(4522 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Софија', 0)} transform="translate(540 25)" />
						</g>
						<g id="city-wrap" data-name="Group 17" transform="translate(1059 236)">
							<text id="Букурешт" transform="translate(647 156)" fill="#fff" fontSize="37"><tspan x="-116.994" y="0">Букурешт</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-10" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_10" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Букурешт" day={0} />°</tspan></text>
							</g>
							<g id="pin-10" data-name="pin" transform="translate(4507 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Букурешт', 0)} transform="translate(525 25)" />
						</g>
						<g id="city-wrap" data-name="Group 13" transform="translate(736 558)">
							<text id="Скопје" transform="translate(647 156)" fill="#fff" fontSize="37"><tspan x="-78.366" y="0">Скопје</tspan></text>
							<g id="temperature" data-name="temperature">
								<circle id="Ellipse_2-11" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(657 111)" fill="url(#radial-gradient)"/>
								<text id="_3_11" data-name="3°" transform="translate(700 160)" fill="#fff" fontSize="45"><tspan x="-11.692" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Скопје" day={0} />°</tspan></text>
							</g>
							<g id="pin-11" data-name="pin" transform="translate(4526 78)" fill="none">
								<path d="M-3919,110V98.917A6,6,0,0,1-3924,93a6,6,0,0,1,6-6,6,6,0,0,1,6,6,6,6,0,0,1-5,5.917V110Z" stroke="none"/>
								<path d="M -3918.000244140625 97.05720520019531 L -3917.3310546875 96.94496917724609 C -3916.410888671875 96.79064178466797 -3915.567626953125 96.31161499023438 -3914.956298828125 95.59613037109375 C -3914.33935546875 94.87385559082031 -3913.99951171875 93.95188140869141 -3913.99951171875 93.00003051757812 C -3913.99951171875 90.79422760009766 -3915.794189453125 88.99967956542969 -3918 88.99967956542969 C -3920.205810546875 88.99967956542969 -3922.000244140625 90.79422760009766 -3922.000244140625 93.00003051757812 C -3922.000244140625 93.95172882080078 -3921.660400390625 94.87359619140625 -3921.043701171875 95.5958251953125 C -3920.4326171875 96.311279296875 -3919.58935546875 96.79037475585938 -3918.669189453125 96.94485473632812 L -3918.000244140625 97.05720520019531 M -3917.000244140625 109.9995498657227 L -3919.00048828125 109.9995498657227 L -3919.00048828125 98.91725158691406 C -3921.837890625 98.4407958984375 -3924.000244140625 95.97284698486328 -3924.000244140625 93.00003051757812 C -3924.000244140625 89.68619537353516 -3921.313720703125 86.99967956542969 -3918 86.99967956542969 C -3914.68603515625 86.99967956542969 -3911.99951171875 89.68619537353516 -3911.99951171875 93.00003051757812 C -3911.99951171875 95.97325134277344 -3914.162353515625 98.44145202636719 -3917.000244140625 98.91741943359375 L -3917.000244140625 109.9995498657227 Z" stroke="none" fill="#fff"/>
							</g>
							<image className="icon" width={128} height={128} href={getWeatherIcon(data, 'Скопје', 0)} transform="translate(545 25)" />
						</g>
						<text id="БАЛКАН" transform="translate(1860 168)" fill="#fff" fontSize="37"><tspan x="-224.257" y="0">БАЛКАН {moment().format('DD.MM.Y')}</tspan></text>
						<text id="www.weather4all.com.mk" transform="translate(1860 193)" fill="#fff" fontSize="27" opacity="0.7"><tspan x="-218.997" y="0">www.weather4all.com.mk</tspan></text>
					</g>
				</svg>
			</div>
		</>
	)
}

export default BalkanMap