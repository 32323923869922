import React, { useEffect } from 'react'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import Modal from '../components/modal/modal.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../fetch/customers.fetch'
import { useSelector } from 'react-redux'
import SaveButton from '../components/buttons/save-button.component'
import { editTemplate } from '../fetch/templates.fetch'

const EditTemplateModal = ({template, refetchTemplates, setCloseModal}) => {
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST) for editing customer
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editTemplate(token, data, template?.id),
		onSuccess: (res) => {
            setCloseModal(false)
			refetchTemplates()
		}
	})


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, formState: { errors }, setValue, handleSubmit } = useForm();
  	const onSubmit = data => {
		mutation.mutate(data)
	};


	// ----------------------------------------------------------
	// Setting default values in inputs after opening modal
	// ----------------------------------------------------------
	useEffect( () => {
		if ( template )
		{
			setValue('name', template.name);
			setValue('slug', template.slug);
			setValue('description', template.description);
			setValue('duration', template.duration);
		}
	}, [template])


	return (
		<Modal size="modal-xl">
			<form onSubmit={handleSubmit(onSubmit)} className="modal-content">
				<ModalHeader title="Измени Темплејт" />
				
				<ModalBody>
					<div className="row g-4">
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="name" className="form-label">Име</label>
							<input id="name" type="text" className="form-control" {...register("name")} />
						</div>
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="description" className="form-label">Опис</label>
							<input id="description" type="text" className="form-control" {...register("description")} />
						</div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="slug" className="form-label">Клуч</label>
                            <input id="slug" type="text" className="form-control" {...register("slug")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="duration" className="form-label">Времетраење</label>
                            <input id="duration" type="text" className="form-control" {...register("duration")} />
                        </div>
					</div>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
					{ mutation.isLoading ? (
					<button type="submit" disabled className="btn btn-primary">
						<span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
					</button>
					) : (
					<SaveButton />
					) }
				</ModalFooter>
			</form>

			<div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
		</Modal>
	)
}

export default EditTemplateModal