import React from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconUserCheck, IconUserX } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../fetch/customers.fetch'
import { useSelector } from 'react-redux'

const SuspendCustomerModal = ({user, userAction, refetchCustomer, setSuspendUserModal}) => {
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST) for editing customer
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editCustomer(token, data, user?.id),
		onSuccess: (res) => {
            setSuspendUserModal(false)
			refetchCustomer()
		}
	})

	const handleSuspendButton = () => {
		var data = {
			'first_name': user?.first_name,
			'last_name': user?.last_name,
			'email': user?.email,
			'status': userAction,
			'address': user?.address,
			'city': user?.city,
			'zip': user?.zip,
			'country': user?.country,
			'edb': user?.edb,
			'phone': user?.phone
		}

		mutation.mutate(data)
	}

	return (
		<Modal>
			<div className="modal-content">
				<ModalHeader title={`${ !userAction ? 'Суспендирај' : 'Активирај' } клиент?`} />
				
				<ModalBody>
					<p>Дали сте сигурни дека сакате да го { !userAction ? 'суспендирате' : 'активирате' } {user?.name}?</p>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setSuspendUserModal(false)} className="btn btn-outline-info border-0">Не</button>
					<button type="button" className={`btn ${ !userAction ? 'btn-danger' : 'btn-success' }`} onClick={handleSuspendButton}>
						{ !userAction ? <IconUserX size={18} className="me-1" /> : <IconUserCheck size={18} className="me-1" /> }
						{ !userAction ? 'Суспендирај' : 'Активирај' }
					</button>
				</ModalFooter>
			</div>

			<div className="modal-backdrop" onClick={ () => setSuspendUserModal(false) }></div>
		</Modal>
	)
}

export default SuspendCustomerModal