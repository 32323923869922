import { IconTrash } from '@tabler/icons-react'
import React from 'react'

const DeleteButton = ({disabled = false, onlyIcon = false, ...prop}) => {
	return (
		<button type="submit" className={`btn btn-outline-danger ${disabled ? 'cursor-not-allowed' : ''}  ${onlyIcon ? 'btn-only-icon' : ''} `} title='Избриши' disabled={disabled} {...prop}>
			<IconTrash size={18} className={`${ onlyIcon ? '' : 'me-2' } svg-icon btn-icon`} />
			{ onlyIcon ? '' : 'Избриши' }
		</button>
	)
}

export default DeleteButton