import { IconAlertCircle, IconCircleCheck, IconInfoCircle } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'

const Alert = ({ type, message }) => {
	const [icon, setIcon] = useState(false);
	
	useEffect( () => {
		if (type === 'danger')
			setIcon(<IconAlertCircle size={24} />)
		else if ( type === 'success' ) 
			setIcon(<IconCircleCheck size={24} />)
		else if ( type === 'info' ) 
			setIcon(<IconInfoCircle size={24} />)
	}, [type])

	return (
		<div className={`alert alert-light-${type} rounded`}>
			{icon}
			<div className="alert-text ms-2">{message}</div>
		</div>
	)
}

export default Alert