import React, { useEffect, useState } from 'react'
import Europe from './assets/europe.jpg';
import moment from 'moment';
import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import { GetWeatherMaxTemp } from '../components/weather_info';

const EuropeMap = ({data}) => {

	return (
		<>
			<div className="balkan">
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
					<image id="_2.2024-_EVROPA_00126" data-name="2.2024- EVROPA_00126" href={Europe} width="1920" height="1080" />
					<g id="city-wrap" data-name="Group 75" transform="translate(-3924 -4271)">
						<g id="temp" transform="translate(-59 26)">
						<rect id="Rectangle_19" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Маркеш" day={1} />°C</tspan></text>
						</g>
						<g id="city" transform="translate(-51 23)">
						<rect id="Rectangle_18" data-name="Rectangle 18" width="144" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Маракеш" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Маракеш</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4006 5195)" />
					</g>
					<g id="city-wrap" data-name="Group 71" transform="translate(-3924 -4271)">
						<g id="temp-2" data-name="temp" transform="translate(62 -183)">
						<rect id="Rectangle_19-2" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-2" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Мадрид" day={1} />°C</tspan></text>
						</g>
						<g id="city-2" data-name="city" transform="translate(70 -186)">
						<rect id="Rectangle_18-2" data-name="Rectangle 18" width="131" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Мадрид" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Мадрид</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4127 4986)" />
					</g>
					<g id="city-wrap" data-name="Group 70" transform="translate(-3924 -4271)">
						<g id="temp-3" data-name="temp" transform="translate(208 -393)">
						<rect id="Rectangle_19-3" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-3" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Париз" day={1} />°C</tspan></text>
						</g>
						<g id="city-3" data-name="city" transform="translate(223 -396)">
						<rect id="Rectangle_18-3" data-name="Rectangle 18" width="108" height="38" transform="translate(4188 5215)" fill="#363636"/>
						<text id="Париз" transform="translate(4206 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Париз</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4277 4776)" />
					</g>
					<g id="city-wrap" data-name="Group 62" transform="translate(-3924 -4271)">
						<g id="temp-4" data-name="temp" transform="translate(285 -571)">
						<rect id="Rectangle_19-4" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-4" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Лондон" day={1} />°C</tspan></text>
						</g>
						<g id="city-4" data-name="city" transform="translate(296 -574)">
						<rect id="Rectangle_18-4" data-name="Rectangle 18" width="127" height="38" transform="translate(4192 5215)" fill="#363636"/>
						<text id="Лондон" transform="translate(4210 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Лондон</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4350 4598)" />
					</g>
					<g id="city-wrap" data-name="Group 61" transform="translate(-3924 -4271)">
						<g id="temp-5" data-name="temp" transform="translate(134 -690)">
						<rect id="Rectangle_19-5" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-5" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Даблин" day={1} />°C</tspan></text>
						</g>
						<g id="city-5" data-name="city" transform="translate(150 -693)">
						<rect id="Rectangle_18-5" data-name="Rectangle 18" width="126" height="38" transform="translate(4187 5215)" fill="#363636"/>
						<text id="Даблин" transform="translate(4205 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Даблин</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4207 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 63" transform="translate(-3924 -4271)">
						<g id="temp-6" data-name="temp" transform="translate(634 -549)">
						<rect id="Rectangle_19-6" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-6" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Варшава" day={1} />°C</tspan></text>
						</g>
						<g id="city-6" data-name="city" transform="translate(642 -552)">
						<rect id="Rectangle_18-6" data-name="Rectangle 18" width="142" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Варшава" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Варшава</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4699 4620)" />
					</g>
					<g id="city-wrap" data-name="Group 69" transform="translate(-3924 -4271)">
						<g id="temp-7" data-name="temp" transform="translate(453 -347)">
						<rect id="Rectangle_19-7" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-7" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Берн" day={1} />°C</tspan></text>
						</g>
						<g id="city-7" data-name="city" transform="translate(463 -350)">
						<rect id="Rectangle_18-7" data-name="Rectangle 18" width="93" height="38" transform="translate(4193 5215)" fill="#363636"/>
						<text id="Берн" transform="translate(4211 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Берн</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4518 4822)" />
					</g>
					<g id="city-wrap" data-name="Group 72" transform="translate(-3924 -4271)">
						<g id="temp-8" data-name="temp" transform="translate(522 -167)">
						<rect id="Rectangle_19-8" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-8" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Рим" day={1} />°C</tspan></text>
						</g>
						<g id="city-8" data-name="city" transform="translate(522 -170)">
						<rect id="Rectangle_18-8" data-name="Rectangle 18" width="85" height="38" transform="translate(4203 5215)" fill="#363636"/>
						<text id="Рим" transform="translate(4221 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Рим</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4587 5002)" />
					</g>
					<g id="city-wrap" data-name="Group 74" transform="translate(-3924 -4271)">
						<g id="temp-9" data-name="temp" transform="translate(383 26)">
						<rect id="Rectangle_19-9" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-9" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Тунис" day={1} />°C</tspan></text>
						</g>
						<g id="city-9" data-name="city" transform="translate(391 23)">
						<rect id="Rectangle_18-9" data-name="Rectangle 18" width="104" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Тунис" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Тунис</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4448 5195)" />
					</g>
					<g id="city-wrap" data-name="Group 73" transform="translate(-3924 -4271)">
						<g id="temp-10" data-name="temp" transform="translate(783 -44)">
						<rect id="Rectangle_19-10" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-10" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Атина" day={1} />°C</tspan></text>
						</g>
						<g id="city-10" data-name="city" transform="translate(791 -47)">
						<rect id="Rectangle_18-10" data-name="Rectangle 18" width="108" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Атина" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Атина</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4848 5125)" />
					</g>
					<g id="city-wrap" data-name="Group 67" transform="translate(-4024 -4301)">
						<g id="temp-11" data-name="temp" transform="translate(1084 -81)">
						<rect id="Rectangle_19-11" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-11" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Истанбул" day={1} />°C</tspan></text>
						</g>
						<g id="city-11" data-name="city" transform="translate(1092 -84)">
						<rect id="Rectangle_18-11" data-name="Rectangle 18" width="145" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Истанбул" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Истанбул</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(5149 5088)" />
					</g>
					<g id="city-wrap" data-name="Group 66" transform="translate(-3924 -4271)">
						<g id="temp-12" data-name="temp" transform="translate(1307 -315)">
						<rect id="Rectangle_19-12" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-12" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Тбилиси" day={1} />°C</tspan></text>
						</g>
						<g id="city-12" data-name="city" transform="translate(1315 -318)">
						<rect id="Rectangle_18-12" data-name="Rectangle 18" width="137" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Тбилиси" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Тбилиси</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(5372 4854)" />
					</g>
					<g id="city-wrap" data-name="Group 68" transform="translate(-3924 -4271)">
						<g id="temp-13" data-name="temp" transform="translate(710 -315)">
						<rect id="Rectangle_19-13" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-13" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Будимпешта" day={1} />°C</tspan></text>
						</g>
						<g id="city-13" data-name="city" transform="translate(718 -318)">
						<rect id="Rectangle_18-13" data-name="Rectangle 18" width="187" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Будимпешта" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Будимпешта</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4775 4854)" />
					</g>
					<g id="city-wrap" data-name="Group 65" transform="translate(-3924 -4271)">
						<g id="temp-14" data-name="temp" transform="translate(949 -481)">
						<rect id="Rectangle_19-14" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-14" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Киев" day={1} />°C</tspan></text>
						</g>
						<g id="city-14" data-name="city" transform="translate(957 -484)">
						<rect id="Rectangle_18-14" data-name="Rectangle 18" width="93" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Киев" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Киев</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(5014 4688)" />
					</g>
					<g id="city-wrap" data-name="Group 64" transform="translate(-3924 -4271)">
						<g id="temp-15" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-15" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-15" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Москва" day={1} />°C</tspan></text>
						</g>
						<g id="city-15" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-15" data-name="Rectangle 18" width="122" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Москва" transform="translate(4213 5243)" fill="#fff" fontSize="28" font-family="BloggerSans-Medium, Blogger Sans" fontWeight="500"><tspan x="0" y="0">Москва</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width={156} height={156} transform="translate(4998 4479)" />
					</g>
					<g id="Group_59" data-name="Group 59" transform="translate(1428 53)">
						<rect id="Rectangle_20" data-name="Rectangle 20" width="342" height="50" transform="translate(0 -4)" fill="#262626"/>
						<text id="ЕВРОПА_25.03.2024" data-name="ЕВРОПА 25.03.2024" transform="translate(171 33)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="-149.652" y="0">ЕВРОПА 25.03.2024</tspan></text>
					</g>
					<g id="Group_60" data-name="Group 60" transform="translate(1428 104)">
						<rect id="Rectangle_21" data-name="Rectangle 21" width="254" height="50" fill="#3c789f"/>
						<text id="www.w4all.mk" transform="translate(20 37)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="0" y="0">www.w4all.mk</tspan></text>
					</g>
				</svg>
			</div>
		</>
	)
}

export default EuropeMap