import React, { useEffect } from 'react'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import Modal from '../components/modal/modal.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import SaveButton from '../components/buttons/save-button.component'
import { editInvoice } from '../fetch/invoices.fetch'

const EditInvoiceModal = ({invoice, refetch, setCloseModal}) => {
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST) for editing customer
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editInvoice(token, data, invoice?.id),
		onSuccess: (res) => {
            setCloseModal(false)
			refetch()
		}
	})



	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, setValue, handleSubmit } = useForm();
  	const onSubmit = data => {
		mutation.mutate(data);
	};


	// ----------------------------------------------------------
	// Setting default values in inputs after opening modal
	// ----------------------------------------------------------
	useEffect( () => {
		if ( invoice )
		{
			setValue('receiver_info', invoice.receiver_info);
			setValue('sender_info', invoice.sender_info);
			setValue('currency', invoice.currency);
			setValue('discount', invoice.discount);
			setValue('tax', invoice.tax);
			setValue('total', invoice.total);
			setValue('note', invoice.note);
			setValue('status', invoice.status);
		}
	}, [invoice])


	return (
		<Modal size="modal-xl">
			<form onSubmit={handleSubmit(onSubmit)} className="modal-content">
				<ModalHeader title="Измени фактура" />
				
				<ModalBody>
					<div className="row g-4">
						<div className="col-md-6">
							<label htmlFor="receiver" className="form-label">Информации за примателот</label>
							<input id="receiver" type="text" className="form-control" {...register("receiver_info")} />
						</div>
						<div className="col-md-6">
							<label htmlFor="sender" className="form-label">Информации за испраќач</label>
							<input id="sender" type="text" className="form-control" {...register("sender_info")} />
						</div>
                        <div className="col-md-6">
                            <label htmlFor="status" className="form-label">Статус</label>
							<select name="status" className="form-select" {...register("status")}>
								<option value="unpaid">Unpaid</option>
								<option value="paid">Paid</option>
							</select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="currency" className="form-label">Валута</label>
                            <input id="currency" type="text" className="form-control" {...register("currency")} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="discount" className="form-label">Попуст</label>
                            <input id="discount" type="text" className="form-control" {...register("discount")} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="tax" className="form-label">Данок</label>
                            <input id="tax" type="text" className="form-control" {...register("tax")} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="total" className="form-label">Вкупно</label>
                            <input id="total" type="text" className="form-control" {...register("total")} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="note" className="form-label">Забелешка</label>
                            <input id="note" type="text" className="form-control" {...register("note")} />
                        </div>
					</div>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
					{ mutation.isLoading ? (
					<button type="submit" disabled className="btn btn-primary">
						<span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
					</button>
					) : (
					<SaveButton />
					) }
				</ModalFooter>
			</form>

			<div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
		</Modal>
	)
}

export default EditInvoiceModal