import React from 'react'
import GeneratedInvoice from '../../components/invoice/invoice.component'
import Page from '../../layouts/page/page.component'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { getInvoice } from '../../fetch/invoices.fetch'
import Loader from '../../components/loader/loader.component'

const Invoice = () => {
	const { invoiceID } = useParams()
	const user = useSelector( state => state.user.info );
	const token = useSelector( state => state.user.token );
	
	const navigate = useNavigate();

	// ----------------------------------------------------------
	// Fetching invoice by id
	// ----------------------------------------------------------
	const queryInvoice = useQuery({ 
		queryKey: ['invoice', invoiceID], 
		queryFn: () => getInvoice(token, invoiceID),
		onSuccess: (res) => {
			if ( !user.isAdmin && user.id !== res.data.invoice.user_id  )
			{
				navigate('/invoices');
			}
		}
	});
	
	return (
		<Page>
			{ 
				queryInvoice.isLoading ? <Loader isCentered={true} /> : <GeneratedInvoice invoice={queryInvoice?.data?.data?.invoice} /> 
			}
		</Page>
	)
}

export default Invoice