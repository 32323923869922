import Page from "../../layouts/page/page.component"
import { getCustomer } from "../../fetch/customers.fetch"
import { useQuery } from "@tanstack/react-query"
import Loader from "../../components/loader/loader.component"
import { useNavigate } from "react-router-dom"
import CustomerSubheader from "../../components/subheader/customer-subheader.component"
import CardBody from "../../components/card/card-body.component"
import Card from "../../components/card/card.component"
import UserProfile from "../../components/user-profile/user-profile.component"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../store/user/user.action"
import UserAddress from "../../components/user-profile/user-address.component"
import CustomerInvoices from "../../components/customer/customer-invoices.component"
import CustomerServices from "../../components/customer/customer-services.component"

const CustomerDashboard = () => {
	const user = useSelector(state => state.user);
	const isAdmin = useSelector(state => state.user.info.isAdmin);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	// ----------------------------------------------------------
	// Fetching user information
	// ----------------------------------------------------------
	const queryCustomer = useQuery({
		queryKey: ['customer', user.info.id], 
		queryFn: () => getCustomer(user.token, user.info.id),
		onSuccess: (res) => {
			if ( res.status === 401 )
				dispatch(removeUser());
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
			else navigate('/customers')
		}
	});	
		

	// ----------------------------------------------------------
	// If query has error while fetching the user return false and redirect to customers page
	// ----------------------------------------------------------
	if ( queryCustomer.isError ) 
		return false

	return (
		<Page>
			<CustomerSubheader isAdmin={isAdmin} queryCustomer={queryCustomer} user={queryCustomer?.data?.data?.user} />
			
			<div className="row mt-5">
				{ queryCustomer.isLoading ? <div className="mt-5"><Loader isCentered /></div> : 
				<>
					<div className="col-sm-12 col-lg-4">
						<Card className="shadow-3d-primary">
							<CardBody>
								<UserProfile user={queryCustomer?.data?.data} />
							</CardBody>
						</Card>

						<Card className="my-5">
							<CardBody>
								<UserAddress customer={queryCustomer?.data?.data} />
							</CardBody>
						</Card>
					</div>

					<div className="col-sm-12 col-lg-8">
						<CustomerInvoices invoices={queryCustomer?.data?.data?.user?.invoices} refetch={queryCustomer.refetch} />

						<CustomerServices services={queryCustomer?.data?.data?.user?.services} isAdmin={isAdmin} refetchCustomer={queryCustomer.refetch} userID={user.info.id} />
					</div>
				</>
				}	
			</div>
		</Page>
	)
}

export default CustomerDashboard