import axios from "axios";
import {PRODUCTION_API} from '../config'

// ----------------------------------------------------------
// Get all available services
// ----------------------------------------------------------
export const getServices = async (token, userID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/services`, config);
}



// ----------------------------------------------------------
// Create new service for user
// ----------------------------------------------------------
export const createService = async (data, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.post(`${PRODUCTION_API}/services/add`, data, config);
}


// ----------------------------------------------------------
// Edit service for user
// ----------------------------------------------------------
export const editService = async (service_id, data, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.post(`${PRODUCTION_API}/services/edit/${service_id}`, data, config);
}



// ----------------------------------------------------------
// Edit service for user
// ----------------------------------------------------------
export const removeService = async (service_id, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.delete(`${PRODUCTION_API}/services/${service_id}`, config);
}
