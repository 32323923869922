import axios from "axios";
import { MOCK_URL, PRODUCTION_API } from "../config";

// ----------------------------------------------------------
// Get all city with weather
// ----------------------------------------------------------
export const getCitiesWeather = async (token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.get(`${PRODUCTION_API}/weather`, config);
}


// ----------------------------------------------------------
// Get single city with weather
// ----------------------------------------------------------
export const getCityWeather = async (token, city) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/weather/${city}`, config);
}

// ----------------------------------------------------------
// Get single city with weather
// ----------------------------------------------------------
export const getCityWeatherInfo = async (token, city) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/cities/${city}`, config);
}


// ----------------------------------------------------------
// Create new city with weather data sources
// ----------------------------------------------------------
export const createCity = async (token, data) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.put(`${PRODUCTION_API}/cities`, data, config);
}


export const editCityInfoData = async (token, data) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.post(`${PRODUCTION_API}/cities/current`, data, config);
}

export const editForecastData = async (token, data) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.post(`${PRODUCTION_API}/weather`, data, config);
}

// Edit forecast 14 days text

export const editForecastText = async (token, data, cityID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.post(`${PRODUCTION_API}/cities/${cityID}`, data, config);
}


// ----------------------------------------------------------
// Delete city
// ----------------------------------------------------------
export const deleteCity = async (token, id) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.delete(`${PRODUCTION_API}/cities/${id}`, config);
}