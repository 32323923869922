import { ACTION_TYPES } from './weather.types';

const INITIAL_STATE = {
	collection: false
}

export const weatherReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ACTION_TYPES.SET_WEATHER:
			var data = [];

			payload.data.map( weather => {
				data[weather.name] = weather;
			})

			return {
				...state,
				collection: data
			}
		case ACTION_TYPES.REMOVE_WEATHER:
			return {
				...state,
				collection: []
			}
		default:
			return state;
	}
}
