import React from 'react'
import Balkan from './assets/balkan.jpg';
import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons'
import { GetWeatherMaxTemp } from '../components/weather_info';

const BalkanMap = ({data}) => {
	return (
		<>
			<div className="balkan">
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
					<image id="_2.2024-_EVROPA_00126" data-name="2.2024- EVROPA_00126" href={Balkan} width="1920" height="1080" />
					<g id="city-wrap" data-name="Group 77" transform="translate(-3928 -3695)">
						<g id="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Солун" day={1} />°C</tspan></text>
						</g>
						<g id="city" transform="translate(941 -693)">
						<rect id="Rectangle_18" data-name="Rectangle 18" width="107" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Солун" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Солун</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 79" transform="translate(-3737 -3878)">
						<g id="temp-2" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-2" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-2" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Софија" day={1} />°C</tspan></text>
						</g>
						<g id="city-2" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-2" data-name="Rectangle 18" width="118" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Софија" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Софија</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 82" transform="translate(-3724 -4082)">
						<g id="temp-3" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-3" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-3" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Букурешт" day={1} />°C</tspan></text>
						</g>
						<g id="city-3" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-3" data-name="Rectangle 18" width="148" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Букурешт" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Букурешт</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 78" transform="translate(-4038 -3837)">
						<g id="temp-4" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-4" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-4" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Скопје" day={1} />°C</tspan></text>
						</g>
						<g id="city-4" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-4" data-name="Rectangle 18" width="112" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Скопје" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Скопје</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 87" transform="translate(-4049 -4004)">
						<g id="temp-5" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-5" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-5" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Приштина" day={1} />°C</tspan></text>
						</g>
						<g id="city-5" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-5" data-name="Rectangle 18" width="159" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Приштина" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Приштина</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 83" transform="translate(-4129 -4207)">
						<g id="temp-6" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-6" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-6" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Белград" day={1} />°C</tspan></text>
						</g>
						<g id="city-6" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-6" data-name="Rectangle 18" width="135" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Белград" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Белград</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 84" transform="translate(-4347 -4095)">
						<g id="temp-7" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-7" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-7" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Сараево" day={1} />°C</tspan></text>
						</g>
						<g id="city-7" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-7" data-name="Rectangle 18" width="133" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Сараево" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Сараево</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 85" transform="translate(-4424 -4238)">
						<g id="temp-8" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-8" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-8" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Загреб" day={1} />°C</tspan></text>
						</g>
						<g id="city-8" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-8" data-name="Rectangle 18" width="116" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Загреб" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Загреб</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 86" transform="translate(-4577 -4355)">
						<g id="temp-9" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-9" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-9" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Љубљана" day={1} />°C</tspan></text>
						</g>
						<g id="city-9" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-9" data-name="Rectangle 18" width="152" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Љубљана" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Љубљана</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 80" transform="translate(-4212 -3736)">
						<g id="temp-10" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-10" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-10" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Тирана" day={1} />°C</tspan></text>
						</g>
						<g id="city-10" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-10" data-name="Rectangle 18" width="123" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Тирана" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Тирана</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="city-wrap" data-name="Group 81" transform="translate(-4287 -3907)">
						<g id="temp-11" data-name="temp" transform="translate(933 -690)">
						<rect id="Rectangle_19-11" data-name="Rectangle 19" width="76" height="35" transform="translate(4211 5250)" fill="#ededed"/>
						<text id="_-27_C-11" data-name="-27°C" transform="translate(4249 5277)" fontSize="28" fontWeight="500"><tspan x="-32.942" y="0"><GetWeatherMaxTemp city="Подгорица" day={1} />°C</tspan></text>
						</g>
						<g id="city-11" data-name="city" transform="translate(941 -693)">
						<rect id="Rectangle_18-11" data-name="Rectangle 18" width="166" height="38" transform="translate(4195 5215)" fill="#363636"/>
						<text id="Подгорица" transform="translate(4213 5243)" fill="#fff" fontSize="28" fontWeight="500"><tspan x="0" y="0">Подгорица</tspan></text>
						</g>
						<image href={SITEL_ICONS[103].link} width="156" height="156" transform="translate(4998 4479)" />
					</g>
					<g id="Group_59" data-name="Group 59" transform="translate(1428 53)">
						<rect id="Rectangle_20" data-name="Rectangle 20" width="342" height="50" transform="translate(0 -4)" fill="#262626"/>
						<text id="ЕВРОПА_25.03.2024" data-name="ЕВРОПА 25.03.2024" transform="translate(171 33)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="-149.652" y="0">БАЛКАН 25.03.2024</tspan></text>
					</g>
					<g id="Group_60" data-name="Group 60" transform="translate(1428 104)">
						<rect id="Rectangle_21" data-name="Rectangle 21" width="254" height="50" fill="#3c789f"/>
						<text id="www.w4all.mk" transform="translate(20 37)" fill="#fff" fontSize="36" fontWeight="500"><tspan x="0" y="0">www.w4all.mk</tspan></text>
					</g>
				</svg>
			</div>
		</>
	)
}

export default BalkanMap
