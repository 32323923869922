import Page from "../../layouts/page/page.component"
import DashboardInfo from "../../components/dashboard-info/dashboard-info.component"
import RevenueChart from "../../components/revenue-chart/revenue-chart.component"
import LatestTransactions from "../../components/latest-transactions/latest-transactions.component"
import { useQuery } from "@tanstack/react-query"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardStats } from "../../fetch/settings.fetch"
import { removeUser } from "../../store/user/user.action"

const Dashboard = () => {

    const user = useSelector( state => state.user )
    const dispatch = useDispatch();

    // ----------------------------------------------------------
	// Fetching all customers information
	// ----------------------------------------------------------
	const statsInfoQuery = useQuery({
		queryKey: ['stats'],
		queryFn: () => getDashboardStats(user?.token),
		staleTime: 5000,
		onSuccess: (res) => {
            if(res.status !== 200 )
                dispatch(removeUser())
        },
		onError: (err) => {
            dispatch(removeUser())
        }
	});

    return (
        <Page>
            <DashboardInfo data={statsInfoQuery?.data?.data} />
            <div className="row">
			    <div className="col-sm-12 col-lg-8">
                    <RevenueChart />
                </div>
			    <div className="col-sm-12 col-lg-4">
                    <LatestTransactions data={statsInfoQuery?.data?.data} />
                </div>
            </div>
        </Page>
    )
}

export default Dashboard