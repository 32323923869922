import React, { useEffect } from 'react'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import Modal from '../components/modal/modal.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../fetch/customers.fetch'
import { useSelector } from 'react-redux'
import SaveButton from '../components/buttons/save-button.component'
import { createTemplate, editTemplate } from '../fetch/templates.fetch'

const NewTemplateModal = ({refetchTemplates, setCloseModal}) => {
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST) for editing customer
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => createTemplate(token, data),
		onSuccess: (res) => {
            setCloseModal(false)
			refetchTemplates()
		}
	})


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, formState: { errors }, handleSubmit, reset } = useForm();
  	const onSubmit = data => {
		mutation.mutate(data)
		reset();
	};


	return (
		<Modal size="modal-xl">
			<form onSubmit={handleSubmit(onSubmit)} className="modal-content">
				<ModalHeader title="Нов темплејт" />
				
				<ModalBody>
					<div className="row g-4">
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="name" className="form-label">Име</label>
							<input id="name" type="text" className="form-control" {...register("name", { required: "Внесете име." })} />
                            <p className='text-danger mb-0'>{errors?.name?.message}</p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="description" className="form-label">Опис</label>
							<input id="description" type="text" className="form-control" {...register("description", { required: "Внесете опис." })} />
                            <p className='text-danger mb-0'>{errors?.description?.message}</p>
						</div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="key" className="form-label">Клуч</label>
                            <input id="key" type="text" className="form-control" {...register("key", { required: "Внесете идентификациски клуч по ваш избор." })} />
                            <p className='text-danger mb-0'>{errors?.key?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="active" className="form-label">Активен</label>
							<select id="active" className="form-control" {...register("active")}>
								<option value="1" selected>1</option>
								<option value="0">0</option>
							</select>
                            <p className='text-danger mb-0'>{errors?.active?.message}</p>
                        </div>
					</div>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
					{ mutation.isLoading ? (
					<button type="submit" disabled className="btn btn-primary">
						<span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
					</button>
					) : (
					<SaveButton />
					) }
				</ModalFooter>
			</form>

			<div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
		</Modal>
	)
}

export default NewTemplateModal