import { ACTION_TYPES } from './user.types';

const INITIAL_STATE = {
	token: localStorage.getItem('token') ?? false,
	token_expire: localStorage.getItem('token_expire') ?? false,
	info: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false,
}

export const userReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ACTION_TYPES.SET_USER:
			localStorage.setItem( 'user', JSON.stringify(payload.user) );
			localStorage.setItem( 'token', payload.access_token );
			localStorage.setItem( 'token_expire', Date.now() + 3600000 );

			return {
				...state,
				token: payload.access_token,
				token_expire: Date.now() + 3600000,
				info: payload.user
			}
		case ACTION_TYPES.UPDATE_USER_INFO:
			return {
				...state,
				info: {
					...state.info,
					...payload
				}
			}
		case ACTION_TYPES.REMOVE_USER:
			localStorage.removeItem( 'user' );
			localStorage.removeItem( 'token' );
			localStorage.removeItem( 'token_expire' );
			return {
				...state,
				token_expire: 0,
				token: false,
				info: [],
			}
		default:
			return state;
	}
}
