const AsideHead = ({asideToggle, isAsideOpen}) => {
  	return (
		<div className="aside-head">
			<div className="brand">
				<div className="brand-title">M</div>

				<button type="button" onClick={() => { localStorage.setItem('isAsideOpen', !isAsideOpen); asideToggle(!isAsideOpen); }} className="btn brand-aside-toggle" aria-label="Toggle Aside"><svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon brand-aside-toggle-close" data-name="Material--FirstPage"><path d="M24 0v24H0V0h24z" fill="none" opacity="0.87"></path><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6 1.41-1.41zM6 6h2v12H6V6z"></path></svg><svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon brand-aside-toggle-open" data-name="Material--LastPage"><path d="M0 0h24v24H0V0z" fill="none" opacity="0.87"></path><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6-1.41 1.41zM16 6h2v12h-2V6z"></path></svg></button>
			</div>
		</div>
  	)
}

export default AsideHead