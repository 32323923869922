import { IconCircleFilled, IconClipboardText, IconEdit, IconPencil } from '@tabler/icons-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group'
import EditInvoiceModal from '../../modals/edit-invoice.modal';
import EditButton from '../buttons/edit-button.component';
import { useSelector } from 'react-redux';

const InvoicesTable = ({invoices, refetch, sliceFrom, sliceTo}) => {
	// ----------------------------------------------------------
	// Use navigate hook for redirecting
	// ----------------------------------------------------------
	const navigate = useNavigate();

	const [ openModal, setOpenModal ] = useState(false)
	const [ invoiceData, setInvoiceData ] = useState(false)
	const isAdmin = useSelector(state => state.user.info.isAdmin)


	useEffect( () => {
		if (!openModal)
			setInvoiceData(false)
	}, [openModal])

	return (
		<>
			<table className="table table-modern table-hover">
				<thead>
					<tr>
						<th>#</th>
						{ window.location.pathname === '/invoices' ? <th>Корисник</th> : '' }
						<th>Број на фактура</th>
						<th>Вкупно</th>
						<th>Дата</th>
						<th>Статус</th>
						{ isAdmin ? <th width={50}></th> : ''}
						<th width={50}></th>
					</tr>
				</thead>
				<tbody>
					{ invoices && invoices?.length !== 0 ? <>
					{ invoices.slice(sliceFrom, sliceTo).map( (invoice, i) => (
						<tr key={invoice.id} role='button'>
							<td>
								<div className="fs-6 fw-bold">#{invoice.id}</div>
							</td>
							{ window.location.pathname === '/invoices' ?
							<td>
								<div>{invoice.user.first_name} {invoice.user.last_name}</div><div className="small text-muted"></div>
							</td> : '' }
							<td>#618236981273</td>
							<td>{invoice.total.toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</td>
							<td>{moment(invoice.created_at).format('D/MM/Y')}</td>
							<td>
								<span className={`${invoice.status === 'unpaid' ? 'text-danger' : 'text-success'}`}><IconCircleFilled size={18} className="me-2" />{invoice.status === 'paid' ? 'Платена' : 'Не платена'}</span>
							</td>
							{ isAdmin ? 
							<td>
								<EditButton onClick={ () => { setOpenModal(true); setInvoiceData(invoice) } } onlyIcon />
							</td>
							: ''}
							<td>
								<button className="btn btn-primary text-nowrap btn-only-icon"  onClick={ () => navigate('/invoices/'+invoice.id) }>
									<IconClipboardText size={14} />
								</button>
							</td>
						</tr>
					) ) }
					</> : <tr><td align="center" colSpan="8">Не се пронајдени фактури</td></tr> }
				</tbody>
			</table>

			{/* Edit User Modal */}
			<CSSTransition in={openModal} timeout={0} classNames="fade">
				<EditInvoiceModal refetch={refetch} serviceData={invoiceData} setCloseModal={setOpenModal} invoice={invoiceData} />
			</CSSTransition>
		</>
	)
}

export default InvoicesTable