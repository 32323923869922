import React, { useEffect, useState } from 'react'
import Weather24ForecastCard from './weather-forecast-card/weather-24-forecast-card.component';

const DailyTemperature = ({data, isEditable}) => {
	
	const [ hourlyData, setHourlyData ] = useState(data.data.hourly);
 
	return (
		<div className="row g-4">
			{ hourlyData.length ?
				hourlyData.map( (data, key) => <div className="col-sm-6 col-md-4 col-lg-2 col-xl-2" key={key}><Weather24ForecastCard index={key} setHourlyData={setHourlyData} hourlyData={hourlyData} isEditable={isEditable} /></div> ) : 
				<p className='col-sm-12 text-center'>Не се пронајдени податоци</p>
			}
		</div>
	)
}

export default DailyTemperature