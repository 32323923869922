import React, { useEffect } from 'react'
import Video from '../components/video'
import bg from './assets/blue-bg.mp4'
import moment from 'moment';

import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons';
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info';

const Forecast5Days = ({data}) => {

	const fiveDays = [0, 1, 2, 3, 4];

	return (
		<>
			<div className="video-bg">
				<Video src={bg} loop autoPlay muted />
			</div>

			<div className="forecast-5days">
				<h1>Македонија ●<span> во наредни 5 дена</span></h1>

				<div className="forecast-5days-boxes">
					{ fiveDays.map((i) => 
					<div className="forecast-5days-boxes_box" key={i}>
						<div className="forecast-5days-boxes_box_header">{moment(data['Скопје']?.forecast[i]?.date*1000).format('dddd')}</div>
						<div className="forecast-5days-boxes_box_max-temp">
							<p><GetWeatherMaxTemp city="Маврово" day={i} />°</p>
							<p><GetWeatherMaxTemp city="Гевгелија" day={i} />°</p>
						</div>
						<div className="forecast-5days-boxes_box_icon">
							<img src={getWeatherIcon( data, "Гевгелија", i )} width="200" height="200" alt={SITEL_ICONS[data['Скопје']?.forecast[i].data.icon].value} />
						</div>
						<div className="forecast-5days-boxes_box_min-temp">
							<p><GetWeatherMinTemp city="Маврово" day={i} />°</p>
							<p><GetWeatherMinTemp city="Гевгелија" day={i} />°</p>
						</div>
					</div>
					)}
				</div>
			</div>
		</>
	)
}

export default Forecast5Days