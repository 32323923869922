import React from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconSettingsX, IconUserX } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { removeService } from '../fetch/services.fetch'
import { useSelector } from 'react-redux'

const RemoveServiceModal = ({service, setCloseModal, refetchCustomer}) => {
	const token = useSelector( state => state.user.token );

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
        // detecting if editing or creating new service
		mutationFn: () => removeService(service.id, token),
		onSuccess: (res) => {
            setCloseModal(false)
			refetchCustomer()
		},
	})

	return (
		<Modal>
			<div className="modal-content">
				<ModalHeader title="Delete Service?" />
				
				<ModalBody>
					<p>Дали сте сигурни дека сакате да го избришете овој сервис {service?.title}?</p>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Не</button>
					<button type="button" className="btn btn-danger" onClick={mutation.mutate}>
						<IconSettingsX size={18} className="me-1" />
						Избриши сервис
					</button>
				</ModalFooter>
			</div>

			<div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
		</Modal>
	)
}

export default RemoveServiceModal