import axios from "axios";
import {PRODUCTION_API} from '../config'

// ----------------------------------------------------------
// Get main settings
// ----------------------------------------------------------
export const getSettings = async (token, userID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/settings`, config);
}


// ----------------------------------------------------------
// Get Dashboard Stats
// ----------------------------------------------------------
export const getDashboardStats = async (token, id) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/settings/stats`, config);
}