import { IconPencil, IconTrash } from '@tabler/icons-react';
import moment from 'moment';
import React from 'react'
import EditButton from '../buttons/edit-button.component';

const UserService = ({service, templates, openModal, setEditServiceModalData, openRemoveServiceModal, setRemoveServiceModalData, isAdmin}) => {
	return (
		<>
			<tr>
				<td>{service?.title}</td>
				<td>{service?.price.toLocaleString('mk', { style: 'currency', currency: 'MKD' })}</td>
				<td>{service?.templates.name}</td>
				<td><small>{service?.key}</small></td>
				<td>
					<span className={`fw-medium ${ moment() < moment(service?.active_to) ? 'text-success' : 'text-danger' }`}>{moment(service.active_to).format('D/MM/Y')}</span>
				</td>
				{isAdmin ? <>
					<td><EditButton onClick={() => { openModal(true); setEditServiceModalData(service); }} onlyIcon /></td>
					<td><button type="button" className="btn btn-light-danger btn-only-icon" onClick={() => {openRemoveServiceModal(true); setRemoveServiceModalData(service)}}><IconTrash className="svg-icon btn-icon" /></button></td>
				</> : '' }
			</tr>
		</>
	)
}

export default UserService