import axios from "axios";
import { PRODUCTION_API } from '../config'

// ----------------------------------------------------------
// Get all templates
// ----------------------------------------------------------
export const getTemplates = async (token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.get(`${PRODUCTION_API}/templates`, config);
}


// ----------------------------------------------------------
// Create new template
// ----------------------------------------------------------
export const createTemplate = async (token, data) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.put(`${PRODUCTION_API}/templates`, data, config);
}


// ----------------------------------------------------------
// Edit template
// ----------------------------------------------------------
export const editTemplate = async (token, data, id) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.post(`${PRODUCTION_API}/templates/edit/${id}`, data, config);
}


// ----------------------------------------------------------
// Delete template
// ----------------------------------------------------------
export const removeTemplate = async (token, id) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.delete(`${PRODUCTION_API}/templates/${id}`, config);
}
