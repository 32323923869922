import React, { useEffect } from 'react'

const Video = ({src, play, ...prop}) => {

	return (
		<video {...prop}>
			<source src={src} />
		</video>
	)
}

export default Video