import React, { useEffect, useState } from 'react'
import Card from '../../card/card.component'
import CardBody from '../../card/card-body.component'
import { IconCompass, IconEdit, IconWind } from '@tabler/icons-react'
import { useForm } from 'react-hook-form'
import { ICONS, W4ALL_ICONS, WEATHER_ICONS } from '../../../config'
import { CSSTransition } from 'react-transition-group'
import WeatherIconsModal from '../../../modals/weather-icons.modal'
import moment from 'moment'
import 'moment/locale/mk';

const Weather24ForecastCard = ({hourlyData, index, isEditable}) => {

	const { time, icon, temp, precipation, wind } = hourlyData[index];
	const [ modalWeather, setModalWeather ] = useState(false)
	const [ weatherIcon, setWeatherIcon ] = useState(icon)


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, setValue } = useForm();


	// ----------------------------------------------------------
	// Setting default values in inputs after opening modal
	// ----------------------------------------------------------
	useEffect( () => {
		if ( hourlyData )
		{
			setValue('temperature', temp);
		}
	}, [hourlyData])
	

	return (
		<>
		<Card className="h-100 mb-0">
			<CardBody className="d-flex flex-sm-column justify-content-between py-3 px-4">
				<h6 className="text-center text-muted mb-1 capitalize">{moment(time*1000).format('LT')}</h6>
				<div className={`position-relative text-center ${isEditable ? 'border cursor-pointer btn btn-outline-info border-0 px-0' : ''}`} onClick={() => { isEditable ? setModalWeather(true) : setModalWeather(modalWeather) } }>
					<img src={W4ALL_ICONS[weatherIcon].img} alt='' height={40} />
					<br />
					{W4ALL_ICONS[weatherIcon].value}
					{precipation ? <span className="text-rain">{precipation}%</span> : '' }
				</div>
				<div className="mt-2">
					<div className="input-group mb-3">
						<input type="text" style={{ width: 20 }} className={`form-control ${!isEditable ? 'bg-transparent shadow-none border' : ''}`} disabled={!isEditable} {...register("temperature")} />
						<label htmlFor="temp" className="input-group-text">°C</label>
					</div>
					<div className='d-flex justify-content-between'>
						<h6 className="text-center mb-0 d-flex align-items-center"><IconWind size={16} className="me-1 text-muted" />{wind}km/s</h6>
						<h6 className="text-center mb-0 d-flex align-items-center"><IconCompass size={16} className="me-1 text-muted" />{wind}</h6>
					</div>
				</div>
			</CardBody>
		</Card>

		{/* Choose Weather Icons Modal */}
		<CSSTransition in={modalWeather} timeout={0} classNames="fade">
			<WeatherIconsModal setWeatherIcon={setWeatherIcon} setModal={setModalWeather} weatherIcon={weatherIcon} />
		</CSSTransition>
		</>
	)
}

export default Weather24ForecastCard