import { IconRosette, IconArrowRight, IconDiscountCheckFilled } from '@tabler/icons-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';

const CustomersTable = ({customers, sliceFrom, sliceTo}) => {
	// ----------------------------------------------------------
	// Use navigate hook for redirecting
	// ----------------------------------------------------------
	const navigate = useNavigate();

	return (
		<>
			<table className="table table-modern table-hover">
				<thead className="">
					<tr>
						<th>Корисник</th>
						<th>Град</th>
						<th>Дата</th>
						<th>Статус</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ customers?.length !== 0 ? <>
					{ customers.slice(sliceFrom, sliceTo).map( (customer, i) => (
						<tr key={customer.id} onClick={ () => navigate('/customers/'+customer.id) } role='button'>
							<td>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<div className="ratio ratio-1x1 me-3" style={{width: '48px'}}>
											<div className="text-secondary rounded-2 d-flex align-items-center justify-content-center">
												{ customer.avatar ? <img className="avatar rounded-circle bg-l25-secondary" src={`${URL}/storage/uploads/${customer?.id}/${customer?.avatar}`} alt="Avatar" width="48" height="48" /> : 
													<div className="avatar rounded-circle btn btn-light-primary d-flex align-items-center justify-content-center fs-5 fw-bold" style={{ width: 48, height: 48 }}>{customer.first_name.charAt(0)}</div>
												}
											</div>
										</div>
									</div>
									<div className="flex-grow-1">
										<div className="fs-6 fw-bold">{customer.first_name} {customer.last_name}</div>
										<div className="text-muted"><small>{customer.email}</small></div>
									</div>
								</div>
							</td>
							<td>
								<span className="">{customer.city}</span>
							</td>
							<td>
								<div>{moment(customer.created_at).format('lll')}</div>
								<div><small className="text-muted">{moment(customer.created_at).startOf('minutes').fromNow()}</small></div>
							</td>
							<td>
								{ customer.status === 1 ? <span className="text-success"><IconDiscountCheckFilled size={18} className="me-2" />Активиран</span> : <span className="text-danger"><IconRosette size={18} className="me-2" />Деактивиран</span> }
							</td>
							<td>
								<button className="btn btn-dark shadow-sm btn-only-icon">
									<IconArrowRight size={14} />
								</button>
							</td>
						</tr>
					) ) }
					</> : <tr><td align="center" colSpan="7">Не се пронајдени корисници</td></tr> }
				</tbody>
			</table>
		</>
	)
}

export default CustomersTable