import SearchSubheader from "../../components/subheader/search-subheader.component"
import CustomersTable from "../../components/table/customers-table.component"
import Page from "../../layouts/page/page.component"
import { getCustomers } from "../../fetch/customers.fetch"
import { useQuery } from "@tanstack/react-query"
import Loader from "../../components/loader/loader.component"
import { IconUsers } from "@tabler/icons-react"
import CardHeader from "../../components/card/card-header.component"
import Card from "../../components/card/card.component"
import CardBody from "../../components/card/card-body.component"
import { useContext, useEffect, useState } from "react"
import CardFooter from "../../components/card/card-footer.component"
import TableFooter from "../../components/table/table-footer.component"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../store/user/user.action"
import { ITEMS_PER_PAGE } from "../../config"
import { DataContext } from "../../context/data.context"

const Customers = () => {

	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)
	const dispatch = useDispatch();

	// ----------------------------------------------------------
	// Defining States
	// ----------------------------------------------------------
	const { customerData, setCustomerData } = useContext(DataContext);
	const [searchInput, setSearchInput] = useState('')
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)
	const [pagination, setPagination] = useState({
		totalPages: 1,
		currentPage: 1,
	});


	// ----------------------------------------------------------
	// Fetching all customers information
	// ----------------------------------------------------------
	const customersQuery = useQuery({
		queryKey: ['customers'],
		queryFn: () => getCustomers(token),
		staleTime: 2000,
		onSuccess: (res) => {
			setCustomerData(res.data.users);
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
		}
	});


	// ----------------------------------------------------------
	// Changing pagination depending on the filtered data
	// ----------------------------------------------------------
	useEffect( () => {
		if (customerData.length)
		{
			setPagination({
				currentPage: 1,
				totalPages: Math.ceil(customerData?.length / itemsPerPage),
			});
		}
	}, [customerData]);


	// ----------------------------------------------------------
	// Filter customers 
	// ----------------------------------------------------------
	useEffect( () => {
		customersQuery?.data?.data?.users && setCustomerData(customersQuery?.data?.data?.users.filter(customer => {
			var fullName = customer.first_name+' '+customer.last_name;
			return fullName.toLowerCase().includes(searchInput.toLowerCase())
		}
		))
	}, [searchInput] )


	// ----------------------------------------------------------
	// Calculating the slice from to for the data
	// ----------------------------------------------------------
	var sliceTo = pagination.currentPage * itemsPerPage;
	var sliceFrom = sliceTo - itemsPerPage;

	return (
		<Page>
			<SearchSubheader page="customers" refetch={customersQuery.refetch} setSearchInput={setSearchInput} />

			<div className="row h-100 mt-4">
				<div className="col-sm-12 col-lg-12">
				{ ( customersQuery.isLoading || customersQuery.isFetching ) ? <Loader isCentered /> :
					<Card className="card-stretch-full">
						<CardHeader icon={<IconUsers size={30} color="#6c5dd3" />} title="Корисници" />

						<CardBody>
							<CustomersTable customers={customerData} pagination={pagination} setItemsPerPage={setItemsPerPage} sliceTo={sliceTo} sliceFrom={sliceFrom} setPagination={setPagination} />
						</CardBody>

						<CardFooter>
							<TableFooter sliceFrom={sliceFrom} sliceTo={sliceTo} items={customerData} pagination={pagination} setPagination={setPagination} setItemsPerPage={setItemsPerPage} />
						</CardFooter>
					</Card>
				}
				</div>
			</div>
		</Page>
	)
}

export default Customers