import { IconCirclePlus, IconSearch } from "@tabler/icons-react"
import { CSSTransition } from "react-transition-group"
import { useEffect, useState } from "react"
import NewCustomerModal from "../../modals/new-customer.modal"
import NewCityModal from "../../modals/new-city.modal"
import NewTemplateModal from "../../modals/new-template.modal"

const SearchSubheader = ({refetch, setSearchInput, page}) => {

	// ----------------------------------------------------------
	// Modals State
	// ----------------------------------------------------------
	const [newCustomerModal, setNewCustomerModal] = useState(false)
	const [newCityModal, setNewCityModal] = useState(false)
	const [newTemplateModal, setNewTemplateModal] = useState(false)

	// ----------------------------------------------------------
	// Handling escape keyboard press for closing all modals
	// ----------------------------------------------------------
	useEffect( () => {
		if ( newCustomerModal === true || newCityModal === true || newTemplateModal === true )
		{
			document.addEventListener('keydown', (e) => {
				if ( e.key === 'Escape' )
				{
					setNewCustomerModal(false)
					setNewCityModal(false)
					setNewTemplateModal(false)
				}
			}, false)
		}
	}, [newCustomerModal, newCityModal, newTemplateModal] );

	return (
		<>
			<div className="subheader">
				<div className="subheader-left col-sm">
					<label className="border-0 bg-transparent cursor-pointer me-0" htmlFor="searchInput">
						<IconSearch color="#6c5dd3" size={24} />
					</label>
					<input id="searchInput" type="search" className="form-control border-0 shadow-none bg-transparent" placeholder={page === 'customers' ? 'Пребарај корисник...' : page === 'weather' ? 'Пребарај град...' : page === 'templates' ? 'Пребарај темплејти...' : 'Пребарај фактура...'} onChange={(e) => setSearchInput(e.target.value)} />
				</div>
				{ page === 'customers' ? (
				<div className="subheader-right col-sm-auto">
					<div className="subheader-separator"></div>
					<button type="button" className="btn btn-light-primary" onClick={() => setNewCustomerModal(true)}>
						<IconCirclePlus className="me-2 svg-icon btn-icon" />
						Додај Корисник
					</button>
				</div>
				) : '' }

				{ page === 'weather' ? (
				<div className="subheader-right col-sm-auto">
					<div className="subheader-separator"></div>
					<button type="button" className="btn btn-light-primary" onClick={() => setNewCityModal(true)}>
						<IconCirclePlus className="me-2 svg-icon btn-icon" />
						Додај Град
					</button>
				</div>
				) : '' }

				{ page === 'templates' ? (
				<div className="subheader-right col-sm-auto">
					<div className="subheader-separator"></div>
					<button type="button" className="btn btn-light-primary" onClick={() => setNewTemplateModal(true)}>
						<IconCirclePlus className="me-2 svg-icon btn-icon" />
						Додај Темплејт
					</button>
				</div>
				) : '' }
			</div>


			{/* New User Modal */}
			<CSSTransition in={newCustomerModal} timeout={0} classNames="fade">
				<NewCustomerModal refetchCustomers={refetch} setCloseModal={setNewCustomerModal} />
			</CSSTransition>
			
			{/* New City Modal */}
			<CSSTransition in={newCityModal} timeout={0} classNames="fade">
				<NewCityModal refetchCities={refetch} setCloseModal={setNewCityModal} />
			</CSSTransition>

			{/* New Template Modal */}
			<CSSTransition in={newTemplateModal} timeout={0} classNames="fade">
				<NewTemplateModal refetchTemplates={refetch} setCloseModal={setNewTemplateModal} />
			</CSSTransition>
		</>
	)
}

export default SearchSubheader