import AsideBody from './aside-body.component';
import AsideHead from './aside-head.component';
import AsideFooter from './aside-footer.component';
import { useEffect, useState } from 'react';

const Aside = () => {
	const [isAsideOpen, setIsAsideOpen] = useState(localStorage.getItem('isAsideOpen'));

	useEffect( () => {
		if ( isAsideOpen === null )
			localStorage.setItem('isAsideOpen', true);
		
		localStorage.setItem('isAsideOpen', isAsideOpen);
	}, [isAsideOpen] )

	return (
		<aside className={`aside ${isAsideOpen ? 'open' : ''} aside-touch-bar aside-touch-bar-close`} style={{ left: 0 }}>
			<AsideHead asideToggle={setIsAsideOpen} isAsideOpen={isAsideOpen} />

			<AsideBody />

			<AsideFooter />
		</aside>
	)
}

export default Aside