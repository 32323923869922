import SearchSubheader from "../../components/subheader/search-subheader.component"
import InvoicesTable from "../../components/table/invoices-table.component"
import Page from "../../layouts/page/page.component"
import { getInvoices } from "../../fetch/invoices.fetch"
import { useQuery } from "@tanstack/react-query"
import Loader from "../../components/loader/loader.component"
import { IconUsers } from "@tabler/icons-react"
import CardHeader from "../../components/card/card-header.component"
import Card from "../../components/card/card.component"
import CardBody from "../../components/card/card-body.component"
import { useContext, useEffect, useState } from "react"
import CardFooter from "../../components/card/card-footer.component"
import TableFooter from "../../components/table/table-footer.component"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../store/user/user.action"
import { ITEMS_PER_PAGE } from "../../config"
import { DataContext } from "../../context/data.context"

const Invoices = () => {

	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)
	const dispatch = useDispatch();


	// ----------------------------------------------------------
	// Defining States
	// ----------------------------------------------------------
	const { invoicesData, setInvoicesData } = useContext(DataContext)
	const [searchInput, setSearchInput] = useState('')
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)
	const [pagination, setPagination] = useState({
		totalPages: 1,
		currentPage: 1,
	});


	// ----------------------------------------------------------
	// Fetching all invoices
	// ----------------------------------------------------------
	const invoicesQuery = useQuery({
		queryKey: ['invoices'],
		queryFn: () => getInvoices(token),
		staleTime: 2000,
		onSuccess: (res) => {
			if ( res.status === 401 )
				dispatch(removeUser());
			
			setInvoicesData(res.data.invoices);
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
		}
	});


	// ----------------------------------------------------------
	// Changing pagination depending on the filtered data
	// ----------------------------------------------------------
	useEffect( () => {
		if (invoicesData.length)
		{
			setPagination({
				currentPage: 1,
				totalPages: Math.ceil(invoicesData?.length / itemsPerPage),
			});
		}
	}, [invoicesData]);


	// ----------------------------------------------------------
	// Filter invoices 
	// ----------------------------------------------------------
	useEffect( () => {
		invoicesQuery?.data?.data?.invoices && setInvoicesData(invoicesQuery?.data?.data?.invoices.filter(invoice => {
			var searchKeyword = invoice.title;
			return searchKeyword?.toLowerCase().includes(searchInput.toLowerCase())
		}
		))
	}, [searchInput] )


	// ----------------------------------------------------------
	// Calculating the slice from to for the data
	// ----------------------------------------------------------
	var sliceTo = pagination.currentPage * itemsPerPage;
	var sliceFrom = sliceTo - itemsPerPage;


	return (
		<Page>
			<SearchSubheader refetchCustomers={invoicesQuery.refetch} setSearchInput={setSearchInput} />

			<div className="row h-100 mt-4">
				<div className="col-sm-12 col-lg-12">
				{ ( invoicesQuery.isLoading || invoicesQuery.isFetching ) ? <Loader isCentered /> :
					<Card className="card-stretch-full">
						<CardHeader icon={<IconUsers size={30} color="#6c5dd3" />} title="Фактури" />

						<CardBody>
							<InvoicesTable refetch={invoicesQuery.refetch} invoices={invoicesData} pagination={pagination} sliceTo={sliceTo} sliceFrom={sliceFrom} setPagination={setPagination} />
						</CardBody>

						<CardFooter>
							<TableFooter items={invoicesData} pagination={pagination} setPagination={setPagination} itemsPerPage={itemsPerPage} sliceTo={sliceTo} sliceFrom={sliceFrom} />
						</CardFooter>
					</Card>
				}
				</div>
			</div>
		</Page>
	)
}

export default Invoices