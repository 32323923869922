import React, { useEffect, useState } from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import SaveButton from '../components/buttons/save-button.component'
import { useMutation } from '@tanstack/react-query'
import { editForecastData } from '../fetch/weather.fetch'
import { useSelector } from 'react-redux'

const WeatherTextModal = ({setWeatherText, setModal, weatherText, forecastData}) => {
	const [ text, setText ] = useState(weatherText)
	const token = useSelector( state => state.user.token )

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editForecastData( token, data ),
		onSuccess: () => {
			setModal(false)
		}
	})

	
	const handleTextareaChange = (ele) => {
		setText(ele.target.value)
	}
	
	const handleSubmit = (ele) => {
		setWeatherText(text);

		var data = {
			text_description: text,
			city: forecastData.city_id,
			date: forecastData.date
		}

		// Start mutation
		mutation.mutate(data);
	}

	

	return (
		<Modal>
			<div className="modal-content">
				<ModalHeader title="Choose Weather Icons?" />
				
				<ModalBody>
					<textarea className="form-control" rows="3" onChange={handleTextareaChange} placeholder="Текст">{text}</textarea>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => { setModal(false); }} className="btn btn-outline-info border-0">Затвори</button>
					<SaveButton onClick={handleSubmit} />
				</ModalFooter>
			</div>

			<div className="modal-backdrop" onClick={ () => setModal(false) }></div>
		</Modal>
	)
}

export default WeatherTextModal