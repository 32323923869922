import React, { useState } from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import { IconReportMoney } from '@tabler/icons-react'
import CardBody from '../card/card-body.component'
import InvoicesTable from '../table/invoices-table.component'
import CardFooter from '../card/card-footer.component'
import TableFooter from '../table/table-footer.component'
import { ITEMS_PER_PAGE } from '../../config'
import './customer-invoices.style.scss';

const CustomerInvoices = ({invoices, refetch}) => {
	// ----------------------------------------------------------
	// Pagination States
	// ----------------------------------------------------------
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)
	const [pagination, setPagination] = useState({
		totalPages: Math.ceil(invoices.length / itemsPerPage),
		currentPage: 1,
	});


	// ----------------------------------------------------------
	// Calculating the slice from to for the data
	// ----------------------------------------------------------
	var sliceTo = pagination.currentPage * itemsPerPage;
	var sliceFrom = sliceTo - itemsPerPage;
	

	return (
		<Card>
			<CardHeader icon={<IconReportMoney size={30} color="#6c5dd3" />} title="Сметки" />
			<CardBody>
				<InvoicesTable refetch={refetch} invoices={invoices} sliceFrom={sliceFrom} sliceTo={sliceTo} />
			</CardBody>
			<CardFooter>
				<TableFooter items={invoices} pagination={pagination} setPagination={setPagination} itemsPerPage={itemsPerPage} sliceFrom={sliceFrom} sliceTo={sliceTo} />
			</CardFooter>
		</Card>
	)
}

export default CustomerInvoices