import { IconChessKing, IconMoon, IconSun } from "@tabler/icons-react"
import { useContext, useEffect, useState } from "react"
import { colorModeContext } from "../../context/colorMode.context"
import { useSelector } from "react-redux";
import { URL } from "../../config";

const Header = () => {

	// Color Mode
	const { colorMode, setColorMode } = useContext(colorModeContext);

	const handleColorModeChange = () => colorMode === 'light' || colorMode == null ? setColorMode('dark') : setColorMode('light')

	// Get logged user info
	const user = useSelector( (state) => state.user.info )

	return (
		<>
		<header className="header mb-5">
			<div className="container-fluid">
				<div className="row d-flex align-items-center">
					<div className="header-left col-md">
						<div className="col d-flex align-items-center">
							<div className="me-3">
								{ user?.avatar ? <img className="avatar rounded-circle bg-l25-primary" src={`${URL}/storage/uploads/${user.id}/${user?.avatar}`} alt="Avatar" width="48" height="48" /> : <div className="rounded-2 d-flex align-items-center justify-content-center bg-l10-primary w-50px h-50px"><span className="text-primary fs-4 fw-bold">{user?.first_name?.charAt(0)}</span></div> }
							</div>
							<div>
								<h5 className="fw-bold mb-0">{user?.first_name} {user?.last_name}</h5>
								<div className="text-muted"><small>{user?.email}</small></div>
							</div>
						</div>
					</div>
					<div className="header-right col-md-auto">
						<div className="row g-3">
							<div className="col-auto">
								<button type="button" className={`btn btn-lg btn-hover-shadow-sm btn-${colorMode === 'light' || colorMode === null ? 'warning' : 'dark'} border-transparent btn-only-icon`} onClick={handleColorModeChange}>
									{ colorMode === 'light' || colorMode === null ? <IconSun size={22} color="#000" stroke={2} /> : <IconMoon size={20} color="#fff" stroke={2} /> }
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<header className="mobile-header">
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						<button type="button" className="btn btn-light btn-lg btn-only-icon mobile-header-toggle" aria-label="Toggle Aside">
							<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon btn-icon" data-name="Material--LastPage"><path d="M0 0h24v24H0V0z" fill="none" opacity="0.87"></path><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6-1.41 1.41zM16 6h2v12h-2V6z"></path></svg>
						</button>
						<button type="button" className="btn btn-light btn-lg btn-only-icon mobile-header-toggle" aria-label="Toggle Left Menu">
							<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon btn-icon" data-name="Material--MoreHoriz"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
						</button>
					</div>
					<div className="col-auto">
						<button type="button" className="btn btn-light btn-lg btn-only-icon mobile-header-toggle" aria-label="Toggle Right Menu">
							<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon btn-icon" data-name="Material--MenuOpen"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"></path></svg>
						</button>
					</div>
				</div>
			</div>
		</header>
		</>
	)
}

export default Header