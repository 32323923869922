import React from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconUserX } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { removeTemplate } from '../fetch/templates.fetch'

const RemoveTemplate = ({template, setModal, refetchTemplates}) => {

	const token = useSelector( state => state.user.token );

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
        // detecting if editing or creating new service
		mutationFn: () => removeTemplate(token, template.id),
		onSuccess: (res) => {
            setModal(false)
			refetchTemplates()
		},
	})

	return (
		<Modal>
			<div className="modal-content">
				<ModalHeader title="Суспендирај клиент?" />
				
				<ModalBody>
					<p>Дали сте сигурни дека сакате да го избришите {template?.name}?</p>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setModal(false)} className="btn btn-outline-info border-0">Не</button>
					<button type="button" className="btn btn-danger" onClick={mutation.mutate}>
						<IconUserX size={18} className="me-1" />
						Избриши
					</button>
				</ModalFooter>
			</div>

			<div className="modal-backdrop" onClick={ () => setModal(false) }></div>
		</Modal>
	)
}

export default RemoveTemplate