
export const SITEL_ICONS = {
	100: {
		link: `${window.location.origin}/sitel_icons/100.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/100.apng`} alt="Icon" /><small>Сончево</small></div>,
		value: "Сончево"
	},
	101: {
		link: `${window.location.origin}/sitel_icons/101.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/101.apng`} alt="Icon" /><small>Претежно сончево</small></div>,
		value: "Претежно сончево"
	},
	102: {
		link: `${window.location.origin}/sitel_icons/102.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/102.apng`} alt="Icon" /><small>Претежно сончево со зголемена oблачност</small></div>,
		value: "Претежно сончево со зголемена oблачност"
	},
	103: {
		link: `${window.location.origin}/sitel_icons/103.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/103.apng`} alt="Icon" /><small>Претежно сончево со услови за дожд </small></div>,
		value: "Претежно сончево со услови за дожд "
	},
	104: {
		link: `${window.location.origin}/sitel_icons/104.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/104.apng`} alt="Icon" /><small>Умерено облачно со услови за дожд</small></div>,
		value: "Умерено облачно со услови за дожд"
	},
	105: {
		link: `${window.location.origin}/sitel_icons/105.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/105.apng`} alt="Icon" /><small>Умерено облачно со дожд и грмотевици </small></div>,
		value: "Умерено облачно со дожд и грмотевици "
	},
	106: {
		link: `${window.location.origin}/sitel_icons/106.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/106.apng`} alt="Icon" /><small>Умерено облачно со сончеви периоди</small></div>,
		value: "Умерено облачно со сончеви периоди"
	},
	107: {
		link: `${window.location.origin}/sitel_icons/107.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/107.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб дожд </small></div>,
		value: "Умерено облачно со услови за слаб дожд "
	},
	108: {
		link: `${window.location.origin}/sitel_icons/108.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/108.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб дожд и снег</small></div>,
		value: "Умерено облачно со услови за слаб дожд и снег"
	},
	109: {
		link: `${window.location.origin}/sitel_icons/109.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/109.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб снег </small></div>,
		value: "Умерено облачно со услови за слаб снег "
	},
	110: {
		link: `${window.location.origin}/sitel_icons/110.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/110.apng`} alt="Icon" /><small>Умерено облачно со врнежи од дожд</small></div>,
		value: "Умерено облачно со врнежи од дожд"
	},
	111: {
		link: `${window.location.origin}/sitel_icons/111.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/111.apng`} alt="Icon" /><small>Умерено облачно со дожд и снег</small></div>,
		value: "Умерено облачно со дожд и снег"
	},
	112: {
		link: `${window.location.origin}/sitel_icons/112.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/112.apng`} alt="Icon" /><small>Умерено облачно со снег</small></div>,
		value: "Умерено облачно со снег"
	},
	113: {
		link: `${window.location.origin}/sitel_icons/113.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/113.apng`} alt="Icon" /><small>Умерено облачно со дожд и грмотевици </small></div>,
		value: "Умерено облачно со дожд и грмотевици "
	},
	114: {
		link: `${window.location.origin}/sitel_icons/114.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/114.svg`} alt="Icon" /><small>Облачно</small></div>,
		value: "Облачно"
	},
	115: {
		link: `${window.location.origin}/sitel_icons/115.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/115.apng`} alt="Icon" /><small>Облачно со слаб дожд</small></div>,
		value: "Облачно со слаб дожд"
	},
	116: {
		link: `${window.location.origin}/sitel_icons/116.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/116.apng`} alt="Icon" /><small>Облачно со слаб дожд и снег</small></div>,
		value: "Облачно со слаб дожд и снег"
	},
	117: {
		link: `${window.location.origin}/sitel_icons/117.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/117.apng`} alt="Icon" /><small>Облачно со слаб снег</small></div>,
		value: "Облачно со слаб снег"
	},
	118: {
		link: `${window.location.origin}/sitel_icons/118.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/118.apng`} alt="Icon" /><small>Облачно со дожд</small></div>,
		value: "Облачно со дожд"
	},
	119: {
		link: `${window.location.origin}/sitel_icons/119.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/119.apng`} alt="Icon" /><small>Облачно со дожд и снег</small></div>,
		value: "Облачно со дожд и снег"
	},
	120: {
		link: `${window.location.origin}/sitel_icons/120.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/120.apng`} alt="Icon" /><small>Облачно со снег</small></div>,
		value: "Облачно со снег"
	},
	121: {
		link: `${window.location.origin}/sitel_icons/121.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/121.apng`} alt="Icon" /><small>Облачно со обилен дожд</small></div>,
		value: "Облачно со обилен дожд"
	},
	122: {
		link: `${window.location.origin}/sitel_icons/122.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/122.apng`} alt="Icon" /><small>Облачно со врнежи од дожд и грмотевици</small></div>,
		value: "Облачно со врнежи од дожд и грмотевици"
	},
	123: {
		link: `${window.location.origin}/sitel_icons/123.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/123.apng`} alt="Icon" /><small>Претежно сончево со појава на магла</small></div>,
		value: "Претежно сончево со појава на магла"
	},
	124: {
		link: `${window.location.origin}/sitel_icons/124.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/124.apng`} alt="Icon" /><small>Магла </small></div>,
		value: "Магла"
	},
	125: {
		link: `${window.location.origin}/sitel_icons/100.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/sonce.apng`} alt="Icon" /><small>Ведро </small></div>,
		value: "Ведро"
	},
	126: {
		link: `${window.location.origin}/sitel_icons/101.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/101.apng`} alt="Icon" /><small>Претежно ведро </small></div>,
		value: "Претежно ведро"
	},
	127: {
		link: `${window.location.origin}/sitel_icons/102.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/102.apng`} alt="Icon" /><small>Претежно ведро со зголемена oблачност</small></div>,
		value: "Претежно ведро со зголемена oблачност"
	},
	128: {
		link: `${window.location.origin}/sitel_icons/103.svg`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/103.svg`} alt="Icon" /><small>Претежно ведро со услови за дожд </small></div>,
		value: "Претежно ведро со услови за дожд "
	},
	129: {
		link: `${window.location.origin}/sitel_icons/104.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/104.apng`} alt="Icon" /><small>Умерено облачно со услови за дожд</small></div>,
		value: "Умерено облачно со услови за дожд"
	},
	130: {
		link: `${window.location.origin}/sitel_icons/105.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/105.apng`} alt="Icon" /><small>Умерено облачно со дожд и грмотевици </small></div>,
		value: "Умерено облачно со дожд и грмотевици "
	},
	131: {
		link: `${window.location.origin}/sitel_icons/106.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/106.apng`} alt="Icon" /><small>Умерено облачно</small></div>,
		value: "Умерено облачно"
	},
	132: {
		link: `${window.location.origin}/sitel_icons/107.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/107.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб дожд</small></div>,
		value: "Умерено облачно со услови за слаб дожд"
	},
	133: {
		link: `${window.location.origin}/sitel_icons/108.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/108.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб дожд и снег</small></div>,
		value: "Умерено облачно со услови за слаб дожд и снег"
	},
	134: {
		link: `${window.location.origin}/sitel_icons/109.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/109.apng`} alt="Icon" /><small>Умерено облачно со услови за слаб снег </small></div>,
		value: "Умерено облачно со услови за слаб снег "
	},
	135: {
		link: `${window.location.origin}/sitel_icons/110.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/110.apng`} alt="Icon" /><small>Умерено облачно со врнежи од дожд</small></div>,
		value: "Умерено облачно со врнежи од дожд"
	},
	136: {
		link: `${window.location.origin}/sitel_icons/111.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/111.apng`} alt="Icon" /><small>Умерено облачно со дожд и снег</small></div>,
		value: "Умерено облачно со дожд и снег"
	},
	137: {
		link: `${window.location.origin}/sitel_icons/112.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/112.apng`} alt="Icon" /><small>Умерено облачно со снег</small></div>,
		value: "Умерено облачно со снег"
	},
	138: {
		link: `${window.location.origin}/sitel_icons/113.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/113.apng`} alt="Icon" /><small>Умерено облачно со дожд и грмотевици </small></div>,
		value: "Умерено облачно со дожд и грмотевици "
	},
	139: {
		link: `${window.location.origin}/sitel_icons/114.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/114.apng`} alt="Icon" /><small>Облачно</small></div>,
		value: "Облачно"
	},
	140: {
		link: `${window.location.origin}/sitel_icons/115.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/115.apng`} alt="Icon" /><small>Облачно со слаб дожд</small></div>,
		value: "Облачно со слаб дожд"
	},
	141: {
		link: `${window.location.origin}/sitel_icons/116.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/116.apng`} alt="Icon" /><small>Облачно со слаб дожд и снег</small></div>,
		value: "Облачно со слаб дожд и снег"
	},
	142: {
		link: `${window.location.origin}/sitel_icons/117.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/117.apng`} alt="Icon" /><small>Облачно со слаб снег</small></div>,
		value: "Облачно со слаб снег"
	},
	143: {
		link: `${window.location.origin}/sitel_icons/118.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/118.apng`} alt="Icon" /><small>Облачно со дожд</small></div>,
		value: "Облачно со дожд"
	},
	144: {
		link: `${window.location.origin}/sitel_icons/119.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/119.apng`} alt="Icon" /><small>Облачно со дожд и снег</small></div>,
		value: "Облачно со дожд и снег"
	},
	145: {
		link: `${window.location.origin}/sitel_icons/120.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/120.apng`} alt="Icon" /><small>Облачно со снег</small></div>,
		value: "Облачно со снег"
	},
	146: {
		link: `${window.location.origin}/sitel_icons/121.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/121.apng`} alt="Icon" /><small>Облачно со обилен дожд</small></div>,
		value: "Облачно со обилен дожд"
	},
	147: {
		link: `${window.location.origin}/sitel_icons/122.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/122.apng`} alt="Icon" /><small>Облачно со врнежи од дожд и грмотевици</small></div>,
		value: "Облачно со врнежи од дожд и грмотевици"
	},
	148: {
		link: `${window.location.origin}/sitel_icons/123.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/123.apng`} alt="Icon" /><small>Претежно ведро со појава на магла</small></div>,
		value: "Претежно ведро со појава на магла"
	},
	149: {
		link: `${window.location.origin}/sitel_icons/124.apng`,
		label: <div className="d-flex align-items-center custom-weather-select"><img width="40" src={`${window.location.origin}/sitel_icons/124.apng`} alt="Icon" /><small>Магла</small></div>,
		value: "Магла"
	},
}