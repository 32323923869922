import React, { useEffect, useState } from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import { IconCirclePlus, IconSettings } from '@tabler/icons-react'
import CardBody from '../card/card-body.component'
import UserService from '../user-profile/user-service.component'
import { CSSTransition } from 'react-transition-group'
import NewServiceModal from '../../modals/new-service.modal'
import RemoveServiceModal from '../../modals/remove-service.modal'

const CustomerServices = ({services, templates, isAdmin, refetchCustomer, userID}) => {
	const [newServiceModal, setNewServiceModal] = useState(false)
	const [editServiceModalData, setEditServiceModalData] = useState(false)
	const [removeServiceModal, setRemoveServiceModal] = useState(false)
	const [removeServiceModalData, setRemoveServiceModalData] = useState(false)


	useEffect( () => {
		if ( newServiceModal === true )
		{
			document.addEventListener('keydown', (e) => {
				if ( e.key === 'Escape' )
					setNewServiceModal(false)
			}, false)
		}
	}, [newServiceModal, removeServiceModal] )


	useEffect( () => {
		if (!newServiceModal)
		{	
			setEditServiceModalData(false)
		}
	}, [newServiceModal])


	return (
		<>
		<Card>
			<CardHeader icon={<IconSettings size={30} color="#6c5dd3" />} title="Сервиси" />
			<CardBody>
				<table className="table table-modern table-hover">
					<thead className="">
						<tr>
							<th>Име</th>
							<th>Цена</th>
							<th>Темплејт</th>
							<th>Клуч</th>
							<th>Активен до</th>
							{isAdmin ? <>
							<th width={50}></th>
							<th width={50}></th>
							</> : ''}
						</tr>
					</thead>
					<tbody>
						{ 
							services.length ? 
							<> 
								{ services.map( (service, index) => 
									<UserService isAdmin={isAdmin} key={index++} service={service} templates={templates} openModal={setNewServiceModal} openRemoveServiceModal={setRemoveServiceModal} setEditServiceModalData={setEditServiceModalData} setRemoveServiceModalData={setRemoveServiceModalData} /> 
								) }
								{ isAdmin ? 
								<tr>
									<td colSpan={7} align="center"><button type="button" className="btn btn-light-primary" onClick={() => setNewServiceModal(true) }><IconCirclePlus size={18} className="me-2 svg-icon btn-icon" />Креирај нов сервис</button></td>
								</tr> : '' }
							</> : 
							<>
								<tr>
									<td colSpan="7" className="text-center">
										Не се пронајдени сервиси<br /><br />
										<button type="button" className="btn btn-light-primary" onClick={() => setNewServiceModal(true) }><IconCirclePlus size={18} className="me-2 svg-icon btn-icon" />Креирај нов сервис</button>
									</td>
								</tr>
							</>
						}
					</tbody>
				</table>
				
			</CardBody>
		</Card>

		{ isAdmin ? <>
		{/* New Service Modal */}
		<CSSTransition in={newServiceModal} timeout={0} classNames="fade">
			<NewServiceModal refetch={refetchCustomer} templates={templates} serviceData={editServiceModalData} setCloseModal={setNewServiceModal} user_id={userID} />
		</CSSTransition>


		{/* Remove Service Modal */}
		<CSSTransition in={removeServiceModal} timeout={0} classNames="fade">
			<RemoveServiceModal refetchCustomer={refetchCustomer} setCloseModal={setRemoveServiceModal} service={removeServiceModalData} />
		</CSSTransition>
		</> : '' }
		</>
	)
}

export default CustomerServices