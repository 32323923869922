import React, { useState } from 'react'
import Card from '../../card/card.component'
import CardBody from '../../card/card-body.component'
import { IconBandage, IconCheck, IconClipboard, IconClipboardText, IconDropletHalf2Filled, IconEdit, IconFlower, IconSun, IconSunrise, IconSunset, IconTemperature, IconUvIndex, IconX } from '@tabler/icons-react'
import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import { editForecastData } from '../../../fetch/weather.fetch'
import Loader from '../../loader/loader.component'
import { useForm } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
import WeatherIconsModal from '../../../modals/weather-icons.modal'
import EditButton from '../../buttons/edit-button.component'
import { useSelector } from 'react-redux'
import { W4ALL_ICONS } from '../../../config'
import { IconDropletFilled } from '@tabler/icons-react'
import WeatherTextModal from '../../../modals/weather-text.modal'


const Weather14ForecastCard = ({setForecastData, forecastData, index}) => {
	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)

	const [ isEditable, setIsEditable ] = useState(false)
	const [ modalWeather, setModalWeather ] = useState(false)
	const [ modalWeatherText, setModalWeatherText ] = useState(false)
	const [ weatherIcon, setWeatherIcon ] = useState(JSON.parse(forecastData[index]?.updated_data)?.icon ? JSON.parse(forecastData[index]?.updated_data).icon : forecastData[index]?.data.icon)
	// const [ weatherIcon, setWeatherIcon ] = useState(forecastData[index]?.data.icon)
	const [ weatherText, setWeatherText ] = useState(forecastData[index]?.text_description)
	const { precipation, sunset, sunrise, hay, migraine, sun } = forecastData[index]?.data;
	const temp_min = forecastData[index]?.updated_data !== null ? JSON.parse(forecastData[index]?.updated_data).temp_min : forecastData[index]?.data?.temp_min;
	const temp_max = forecastData[index]?.updated_data !== null ? JSON.parse(forecastData[index]?.updated_data).temp_max : forecastData[index]?.data?.temp_max;
	const uv = 2;


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, getValues, setValue } = useForm({
		defaultValues: {
			temp_max: temp_max,
			temp_min: temp_min,
		}
	});


	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editForecastData( token, data ),
		onSuccess: () => {
			setIsEditable(false)
		}
	})

	const handleEditTemperature = () => {
		const  { temp_max, temp_min } = getValues()
		
		var data = {
			city: forecastData[index].city_id,
			date: forecastData[index].date,
			temp_max: temp_max,
			temp_min: temp_min,
			icon: weatherIcon
		}

		// Start mutation
		mutation.mutate(data);
	}


	// Revert inputs value to default if not save the edit
	const handleCancelEdit = () => {
		setValue('temp_max', temp_max)
		setValue('temp_min', temp_min)
		setIsEditable(false)
	}

	return (
		<>
		<Card className="h-100 mb-0">
			<div className="card-header pb-0">
				<div>
					<h5 className="mb-1 capitalize">{moment(forecastData[index]?.date*1000).format('dddd')}</h5>
					<h6 className="text-muted mb-1 capitalize">{moment(forecastData[index]?.date*1000).format('ll')}</h6>
				</div>
				<div>
				{ 
					mutation.isLoading ? 
					<Loader size="btn-sm" /> : 
					!isEditable ? <><button className="btn btn-light btn-only-icon me-2" onClick={() => setModalWeatherText(true)}>{ weatherText ? <IconClipboardText size="18" className="svg-icon btn-icon" /> : <IconClipboard size="18" className="svg-icon btn-icon" />}</button><EditButton onClick={() => setIsEditable(true)} onlyIcon /></> : 
					<>
						<button className="btn btn-success btn-only-icon" title="Save" onClick={handleEditTemperature}><IconCheck size={20} /></button>
						<button className="btn btn-outline-danger btn-only-icon text-danger ms-2" title="Cancel" onClick={handleCancelEdit}><IconX size={20} /></button>
					</> 
				}
				</div>
			</div>
			<CardBody className="d-flex flex-sm-column justify-content-between py-3 px-4">
				<div className="mt-2">

					<div className="d-flex align-items-center justify-content-end">
						<div className="position-relative text-center" style={{ flex: 1 }}>
							{ isEditable ? <IconEdit className="position-absolute opacity-50 cursor-pointer" style={{ right: 0 }}  onClick={() => isEditable ? setModalWeather(true) : ''} /> : '' }
							<img src={W4ALL_ICONS[weatherIcon]?.img} width={90} alt="Weather Icon" className="cursor-pointer" onClick={() => isEditable ? setModalWeather(true) : ''} />
							<div>
								{ precipation === 0 || precipation === null || precipation === undefined ? '' : <h6 className="text-rain" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom" style={{ top: 'unset', bottom: -30, left: 0 }}>{ precipation < 50 ? <IconDropletHalf2Filled size={14} /> : <IconDropletFilled size={14} /> } {precipation}%</h6> }
							</div>
						</div>

						<div>
							<div className="px-2 py-2">
								<h6 className="mb-0 d-flex align-items-center text-muted">
									<IconTemperature size={14} className="me-1" />
									Max Temp
								</h6>
								<div className="input-group">
									<input type="text" style={{ width: 65 }} className={`form-control ${!isEditable ? 'bg-transparent shadow-none border-white' : ''}`} disabled={!isEditable} {...register("temp_max")} />
									<label htmlFor="temp" className="input-group-text">°C</label>
								</div>
							</div>
							
							<div className="px-2 py-2">
								<h6 className="mb-0 d-flex align-items-center text-muted">
									<IconTemperature size={14} className="me-1" />
									Min Temp
								</h6>
								<div className="input-group">
									<input type="text" style={{ width: 65 }} className={`form-control ${!isEditable ? 'bg-transparent shadow-none border-white' : ''}`} disabled={!isEditable} {...register("temp_min")} />
									<label htmlFor="temp" className="input-group-text">°C</label>
								</div>
							</div>
						</div>
					</div>

					<div className="navigation-line border-dark"></div>

					<div className="d-flex justify-content-between">
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconSunrise size={14} className="me-1" />Sunrise
							</h6>
							<h5 className="mt-2 mb-0">{sunrise}</h5>
						</div>
						
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconSunset size={14} className="me-1" />Sunset
							</h6>
							<h5 className="mt-2 mb-0">{sunset}</h5>
						</div>
						
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconSun size={14} className="me-1" />Daylight
							</h6>
							<h5 className="mt-2 mb-0">{sun}</h5>
						</div>
					</div>

					<div className="navigation-line border-dark"></div>

					<div className="d-flex justify-content-between">
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconUvIndex size={14} className="me-1" />UV Index
							</h6>
							<h5 className="mt-2 mb-0">{uv}</h5>
						</div>
						
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconFlower size={14} className="me-1" />Hay
							</h6>
							<h5 className="mt-2 mb-0">{hay}</h5>
						</div>
						
						<div className="px-2 py-2">
							<h6 className="mb-0 d-flex align-items-center text-muted">
								<IconBandage size={14} className="me-1" />Migraine
							</h6>
							<h5 className="mt-2 mb-0">{migraine}</h5>
						</div>
					</div>

				</div>
			</CardBody>
		</Card>

		{/* Choose Weather Icons Modal */}
		<CSSTransition in={modalWeather} timeout={0} classNames="fade">
			<WeatherIconsModal setWeatherIcon={setWeatherIcon} setModal={setModalWeather} weatherIcon={weatherIcon} />
		</CSSTransition>
		
		
		{/* Edit Weather Text Modal */}
		<CSSTransition in={modalWeatherText} timeout={0} classNames="fade">
			<WeatherTextModal setWeatherText={setWeatherText} weatherText={weatherText} setModal={setModalWeatherText} forecastData={forecastData[index]} />
		</CSSTransition>
		</>
	)
}

export default Weather14ForecastCard