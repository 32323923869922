import React from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import { IconReceipt2 } from '@tabler/icons-react'
import CardBody from '../card/card-body.component'
import moment from 'moment'

const LatestTransactions = ({data}) => {

	return (
		<Card className="card-stretch-full">
			<CardHeader title='Последни Трансакции' icon={<IconReceipt2 size={30} color="#6c5dd3" />} />

			<CardBody className="card-body-scrollable">
				<div className="row g-4">
					
					{data?.stats.invoices.map( (invoice, i) => (
					<div className="col-12" key={i}>
						<div className="row">
							<div className="col d-flex align-items-center">
								<div className="flex-shrink-0">
									<div className={`${ invoice?.status === 'paid' ? 'text-success bg-l10-success' : 'text-danger bg-l10-danger' } fw-bold py-2 rounded-pill me-3 text-center`} style={{width: '90px'}}>{ invoice.status === 'paid' ? 'Платена' : 'Не платена'}</div>
								</div>
								<div className="flex-grow-1">
									<div className="fs-6">{moment(invoice?.created_at).format('D MMM Y')}</div>
									<div className="text-muted"><small>{invoice?.user?.email}</small></div>
								</div>
							</div>
							<div className="col-auto text-end">
								<div><strong>{parseInt(invoice.total).toLocaleString('mk', { style: 'currency', currency: 'MKD', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</strong></div>
								<div className="text-muted"><small>Tax {parseInt(invoice?.tax).toLocaleString('mk', { style: 'currency', currency: 'MKD', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</small></div>
							</div>
						</div>
					</div>
					))}
				</div>
			</CardBody>
		</Card>
	)
}

export default LatestTransactions