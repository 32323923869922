import React, { useEffect, useState } from 'react'

// duration: the duration of the slider item
// pauseAudio: if set to true it will pause previous played audio
const Item = ({children, duration, pauseAudio, className}) => {
	const [ pageIsLoading, setPageIsLoading ] = useState(true);

	useEffect( () => {
		setTimeout( () => {
			setPageIsLoading(false)
		}, 5000 )
	}, [] )

	return (
		<div className={`item ${className ?? ''}`} data-pauseaudio={pauseAudio} data-duration={duration}>{children}</div>
	)
}

export default Item