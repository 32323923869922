import React, { useEffect, useState } from 'react'
import Europe from './assets/europe.jpg';
import moment from 'moment';
import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import { getWeatherIcon, GetWeatherMaxTemp } from '../components/weather_info';

const EuropeMap = ({data}) => {

	const day = 2;

	return (
		<>
			<div className="balkan">

				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1931 1087">
					<defs>
						<pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 2876 1618">
						<image width="2876" height="1618" href={Europe} />
						</pattern>
						<radialGradient id="radial-gradient" cx="0.267" cy="0.199" r="0.796" gradientTransform="matrix(0.565, 0.825, -0.825, 0.565, 0.28, -0.134)" gradientUnits="objectBoundingBox">
						<stop offset="0" stopColor="#eb8332"/>
						<stop offset="1" stopColor="#d12c1f"/>
						</radialGradient>
					</defs>
					<rect id="Image_9" data-name="Image 9" width="1931" height="1087" fill="url(#pattern)"/>
					<g id="city-wrap" data-name="Group 35" transform="translate(-41 699)">
						<text id="Лисабон" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-93.466" y="0">Лисабон</tspan></text>
						<g id="temperature" transform="translate(-8)">
						<circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Лисабон" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Лисабон', day )} transform="translate(573 55)" />
					</g>
					<g id="city-wrap" data-name="Group 36" transform="translate(115 623)">
						<text id="Мадрид" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-87.482" y="0">Мадрид</tspan></text>
						<g id="temperature-2" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_2" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Мадрид" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Мадрид', day )} transform="translate(576 55)" />
					</g>
					<g id="city-wrap" data-name="Group 37" transform="translate(319 354)">
						<text id="Париз" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-67.354" y="0">Париз</tspan></text>
						<g id="temperature-3" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-3" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_3" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Париз" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Париз', day )} transform="translate(586 55)" />
					</g>
					<g id="city-wrap" data-name="Group 38" transform="translate(298 198)">
						<text id="Лондон" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-80.342" y="0">Лондон</tspan></text>
						<g id="temperature-4" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-4" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_4" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Лондон" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Лондон', day )} transform="translate(579 55)" />
					</g>
					<g id="city-wrap" data-name="Group 40" transform="translate(486 221)">
						<text id="Амстердам" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-125.154" y="0">Амстердам</tspan></text>
						<g id="temperature-5" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-5" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_5" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Амстердам" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Амстердам', day )} transform="translate(557 55)" />
					</g>
					<g id="city-wrap" data-name="Group 44" transform="translate(646 261)">
						<text id="Берлин" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-80.852" y="0">Берлин</tspan></text>
						<g id="temperature-6" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-6" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_6" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Берлин" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Берлин', day )} transform="translate(577 55)" />
					</g>
					<g id="city-wrap" data-name="Group 47" transform="translate(650 426)">
						<text id="Виена" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-67.116" y="0">Виена</tspan></text>
						<g id="temperature-7" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-7" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_7" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Виена" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Виена', day )} transform="translate(587 55)" />
					</g>
					<g id="city-wrap" data-name="Group 50" transform="translate(755 514)">
						<text id="Загреб" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-76.16" y="0">Загреб</tspan></text>
						<g id="temperature-8" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-8" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_8" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Загреб" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Загреб', day )} transform="translate(581 55)" />
					</g>
					<g id="city-wrap" data-name="Group 52" transform="translate(888 633)">
						<text id="Скопје" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-72.012" y="0">Скопје</tspan></text>
						<g id="temperature-9" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-9" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_9" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Скопје" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Скопје', day )} transform="translate(583 55)" />
					</g>
					<g id="city-wrap" data-name="Group 54" transform="translate(909 755)">
						<text id="Атина" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-64.974" y="0">Атина</tspan></text>
						<g id="temperature-10" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-10" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_10" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Атина" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Атина', day )} transform="translate(587 55)" />
					</g>
					<g id="city-wrap" data-name="Group 53" transform="translate(1078 646)">
						<text id="Истанбул" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-104.278" y="0">Истанбул</tspan></text>
						<g id="temperature-11" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-11" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_11" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Истанбул" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Истанбул', day )} transform="translate(567 55)" />
					</g>
					<g id="city-wrap" data-name="Group 51" transform="translate(624 637)">
						<text id="Рим" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-44.608" y="0">Рим</tspan></text>
						<g id="temperature-12" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-12" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_12" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Рим" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Рим', day )} transform="translate(597 55)" />
					</g>
					<g id="city-wrap" data-name="Group 49" transform="translate(732 349)">
						<text id="Прага" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-64.158" y="0">Прага</tspan></text>
						<g id="temperature-13" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-13" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_13" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Прага" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Прага', day )} transform="translate(587 55)" />
					</g>
					<g id="city-wrap" data-name="Group 48" transform="translate(894 431)">
						<text id="Будимпешта" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-140.148" y="0">Будимпешта</tspan></text>
						<g id="temperature-14" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-14" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_14" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Будимпешта" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Будимпешта', day )} transform="translate(549 55)" />
					</g>
					<g id="city-wrap" data-name="Group 45" transform="translate(809 252)">
						<text id="Варшава" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-98.532" y="0">Варшава</tspan></text>
						<g id="temperature-15" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-15" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_15" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Варшава" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Варшава', day )} transform="translate(570 55)" />
					</g>
					<g id="city-wrap" data-name="Group 46" transform="translate(955 333)">
						<text id="Киев" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-54.366" y="0">Киев</tspan></text>
						<g id="temperature-16" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-16" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_16" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Киев" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Киев', day )} transform="translate(592 55)" />
					</g>
					<g id="city-wrap" data-name="Group 55" transform="translate(963 125)">
						<text id="Москва" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-84.388" y="0">Москва</tspan></text>
						<g id="temperature-17" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-17" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_17" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Москва" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Москва', day )} transform="translate(577 55)" />
					</g>
					<g id="city-wrap" data-name="Group 41" transform="translate(522 16)">
						<text id="Осло" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-53.924" y="0">Осло</tspan></text>
						<g id="temperature-18" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-18" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_18" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Осло" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Осло', day )} transform="translate(592 55)" />
					</g>
					<g id="city-wrap" data-name="Group 42" transform="translate(699 47)">
						<text id="Стокхолм" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-105.298" y="0">Стокхолм</tspan></text>
						<g id="temperature-19" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-19" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_19" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Стокхолм" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Стокхолм', day )} transform="translate(567 55)" />
					</g>
					<g id="city-wrap" data-name="Group 43" transform="translate(601 131)">
						<text id="Копенхаген" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-127.024" y="0">Копенхаген</tspan></text>
						<g id="temperature-20" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-20" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_20" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Копенхаген" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Копенхаген', day )} transform="translate(556 55)" />
					</g>
					<g id="city-wrap" data-name="Group 39" transform="translate(175 93)">
						<text id="Даблин" transform="translate(648 156)" fill="#fff" fontSize="34"><tspan x="-82.79" y="0">Даблин</tspan></text>
						<g id="temperature-21" data-name="temperature" transform="translate(-8)">
						<circle id="Ellipse_2-21" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(661 118)" fill="url(#radial-gradient)"/>
						<text id="_3_21" data-name="3°" transform="translate(694 154)" fill="#fff" fontSize="35"><tspan x="-9.48" y="0" textAnchor="middle"><GetWeatherMaxTemp city="Даблин" day={day} />°</tspan></text>
						</g>
						<image className="icon" width={90} height={90} href={getWeatherIcon( data, 'Даблин', day )} transform="translate(578 55)" />
					</g>
					<text id="ЕВРОПА_11_01_2024" data-name="ЕВРОПА 11,01,2024" transform="translate(1863 173)" fill="#fff" fontSize="37"><tspan x="-222.666" y="0">ЕВРОПА {moment().add(day,'days').format('DD.MM.Y')}</tspan></text>
					<text id="www.weather4all.com.mk" transform="translate(1644 198)" fill="#fff" fontSize="27" opacity="0.7"><tspan x="0" y="0">www.weather4all.com.mk</tspan></text>
				</svg>
			</div>
		</>
	)
}

export default EuropeMap