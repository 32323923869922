import React, { useEffect, useState } from 'react'
import Slider from '../components/slider'
import Item from '../components/item'
import './lokalna-dnevna.scss';

import { useSelector } from 'react-redux';

import MK5DenaBG from './assets/mk-5dena.jpg';
import MapEurope from './map-europe';
import ForecastCity from './forecast-city';
import ForecastCities from './forecast-cities';
import intro from './assets/intro.mp4';
import BalkanMap from './map-balkan';
import MacedoniaMap from './map-mk';

const TemplateLokalnaDnevna = () => {
	const [ slideIndex, setSlideIndex ] = useState()
	const channel = new BroadcastChannel('record');
	const weather = useSelector(state => state.weather.collection)

	// Listen for messages on "record".
	channel.onmessage = function(e) {
		if ( e.data === 'start')
			setSlideIndex(0)
	};

	const handleDownload = (e) => {
		setSlideIndex(0)

		// window.open('/templates/download', "_blank", "width=400,height=200,menubar=no,location=no,status=no,titlebar=no,scrollbars=no")
		e.currentTarget.remove();
	}

	// useEffect( () => {
	// 	setTimeout( () => {
	// 		setSlideIndex(0);
	// 	}, 5000 )
	// }, [] )

	return (
		<div className="template lokalna-dnevna">
			<Slider slideIndex={slideIndex} setSlideIndex={setSlideIndex}>
				<button className='btn btn-primary start-template download' onClick={(e) => handleDownload(e) }>
					Start
				</button>

				<Item duration="8">
					<video id="transition">
						<source src={intro} />
					</video>
				</Item>
				
				<Item duration={'space'}>
					<MapEurope data={weather} />
				</Item>

				<Item duration={'space'}>
					<BalkanMap data={weather} />
				</Item>
				
				<Item duration={'space'}>
					<MacedoniaMap data={weather} />
				</Item>
				
				<Item duration={'space'}>
					<ForecastCity forecastFor="today" data={weather} />
				</Item>

				<Item duration={'space'}>
					<ForecastCity forecastFor="tomorrow" data={weather} />
				</Item>

				<Item duration={'space'}>
					<ForecastCities data={weather} />
				</Item>
			</Slider>
		</div>
	)
}

export default TemplateLokalnaDnevna