import React, { useEffect, useRef, useState } from 'react'
import Slider from '../components/slider'
import Item from '../components/item'
import intro from './assets/intro.mp4'
import transition from './assets/transition.webm'
import bgImage from './assets/bg.png'
import CityForecast from './city-forecast'
import MapForecast from './map-forecast'
import './vistel-utrinska.scss';
import { useSelector } from 'react-redux'

const TemplateVistelUtrinska = () => {
	const [ slideIndex, setSlideIndex ] = useState()
	const channel = new BroadcastChannel('record');
	const weather = useSelector(state => state.weather.collection)
	
	// Listen for messages on "record".
	channel.onmessage = function(e) {
		if ( e.data === 'start')
			setSlideIndex(0)
	};

	const handleDownload = (e) => {
		window.open('/templates/download', "_blank", "width=400,height=200,menubar=no,location=no,status=no,titlebar=no,scrollbars=no")
		e.currentTarget.remove();
	}


	return (
		<div className="template vistel-utrinska" style={{backgroundImage: 'url('+bgImage+')'}}>
			<Slider slideIndex={slideIndex} setSlideIndex={setSlideIndex}>
				<><button className='btn btn-primary download' onClick={(e) => handleDownload(e) }>Download</button></>
				<Item className='active' duration={13}>
					<video id="transition">
						<source src={intro} />
					</video>
				</Item>

				<Item duration={1.3}>
					<video id="transition">
						<source src={transition} />
					</video>
				</Item>

				<Item duration={40}>
					<CityForecast data={weather} play={slideIndex === 2 ? true : false} />
				</Item>

				<Item duration={1.3}>
					<video id="transition">
						<source src={transition} />
					</video>
				</Item>

				<Item duration={15}>
					<MapForecast data={weather} />
				</Item>

				<Item duration={1.3}>
					<video id="transition">
						<source src={transition} />
					</video>
				</Item>

				<Item duration={13} pauseAudio={1}>
					<video id="transition">
						<source src={intro} />
					</video>
				</Item>
			</Slider>
		</div>
	)
}

export default TemplateVistelUtrinska