import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { checkToken } from '../fetch/auth.fetch';

const ProtectedRoute = ({children}) => {
	const token = useSelector( state => state.user.token )

	return token ? children : <Navigate to="/login" />
};

export default ProtectedRoute;