import { IconAddressBook } from '@tabler/icons-react'
import React from 'react'

const UserAddress = ({customer}) => {
	return (
		<div className="d-flex align-items-start">
			<div className="flex-shrink-0">
				<IconAddressBook size={30} color="#6c5dd3" />
			</div>
			<div className="flex-grow-1 ms-3">
				<p className="fw-bold fs-5">Адреса</p>
				<div>{customer?.user?.address}</div>
				<div>{customer?.user?.city}</div>
				<div>Republic of Macedonia</div>
			</div>
		</div>
	)
}

export default UserAddress