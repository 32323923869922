import React, { useEffect, useState } from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import CardBody from '../card/card-body.component'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { editCityInfoData } from '../../fetch/weather.fetch'
import { useSelector } from 'react-redux'
import { IconBuildingArch } from '@tabler/icons-react'
import SaveButton from '../buttons/save-button.component'
import Loader from '../loader/loader.component'

const CityInfo = ({data}) => {
	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)
	const [ buttonDisabled, setButtonDisabled ] = useState(false)

	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, watch, handleSubmit, setValue } = useForm();

	// ----------------------------------------------------------
	// Setting default values in inputs after opening modal
	// ----------------------------------------------------------
	useEffect( () => {
		if ( data.data )
		{			
			setValue('name', data?.data?.name);
			setValue('api_urls', data?.data?.api_urls);
			setValue('active', data?.data?.active);
		}
	}, [data])

	
	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (mutationData) => editCityInfoData(token, data?.data?.id, mutationData),
		onSuccess: (res) => {
			setButtonDisabled(false);
		},
		onError: () => {
			setButtonDisabled(false);
		}
	})

	const onSubmit = (data) => {
		setButtonDisabled(true);
		mutation.mutate(data)
	};

  	return (
		<Card>
			<form onSubmit={handleSubmit(onSubmit)}>
				<CardHeader title="Информации" icon={<IconBuildingArch size={30} color="#6c5dd3" />} />
				<CardBody>
					<div className="row g-4">
						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="name" type="text" className="form-control" placeholder="Name" {...register("name")} />
								<label htmlFor="name" className="form-label">Име</label>
							</div>
						</div>
						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<select id="status" type="text" className="form-select" {...register("active")}>
									<option value="1">Активен</option>
									<option value="0">Не активен</option>
								</select>
								<label htmlFor="status" className="form-label">Статус</label>
							</div>
						</div>
					</div>

					<div className="row g-4 mt-2">
						<div className="col-sm-12">
                            <h5 className='fw-bold mb-0'>API </h5>
                        </div>
						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="foreca" type="text" className="form-control" placeholder="Foreca" {...register("api_urls.foreca")} />
								<label htmlFor="foreca" className="form-label">Foreca</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="meteoblue" type="text" className="form-control" placeholder="Meteoblue" {...register("api_urls.meteoblue")} />
								<label htmlFor="meteoblue" className="form-label">Meteoblue</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="meteovista" type="text" className="form-control" placeholder="meteovista" {...register("api_urls.meteovista")} />
								<label htmlFor="meteovista" className="form-label">Meteovista</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="uhmr" type="text" className="form-control" placeholder="uhmr" {...register("api_urls.uhmr")} />
								<label htmlFor="uhmr" className="form-label">UHMR</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="sinoptik" type="text" className="form-control" placeholder="sinoptik" {...register("api_urls.sinoptik")} />
								<label htmlFor="sinoptik" className="form-label">Sinoptik</label>
							</div>
						</div>

						<div className="col-sm-12 col-md-4">
							<div className="form-floating">
								<input id="morecast" type="text" className="form-control" placeholder="morecast" {...register("api_urls.morecast")} />
								<label htmlFor="morecast" className="form-label">Morecast</label>
							</div>
						</div>
					</div>

					<div className="row g-4 mt-2">
						<div className="col-sm-12 text-end">
							{ !buttonDisabled ? <SaveButton disabled={buttonDisabled} /> : <Loader /> }
						</div>
					</div>
				</CardBody>
			</form>
		</Card>
	)
}

export default CityInfo
