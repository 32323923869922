import React, { useEffect, useState } from 'react'
import Slider from '../components/slider'
import Item from '../components/item'
import intro from './assets/intro.mp4'
import transition from './assets/transition.webm'
import bgImage from './assets/bg.png'
import MapEurope from './map-europe'
import Macedonia3Days from './macedonia-3days'
import './vistel-dnevna.scss';
import MapBalkan from './map-balkan'
import { useSelector } from 'react-redux'

const TemplateVistelDnevna = () => {
	const [ slideIndex, setSlideIndex ] = useState()
	const channel = new BroadcastChannel('record');
	const weather = useSelector(state => state.weather.collection)

	// Listen for messages on "record".
	channel.onmessage = function(e) {
		if ( e.data === 'start')
			setSlideIndex(0)
	};

	const handleDownload = (e) => {
		window.open('/templates/download', "_blank", "width=400,height=200,menubar=no,location=no,status=no,titlebar=no,scrollbars=no")
		e.currentTarget.remove();
	}


	return (
		<div className="template vistel-dnevna" style={{backgroundImage: 'url('+bgImage+')'}}>
			<Slider slideIndex={slideIndex} setSlideIndex={setSlideIndex}>
				<><button className='btn btn-primary download' onClick={(e) => handleDownload(e) }>Download</button></>
				<Item className='active' duration={"space"}>
					<video id="transition">
						<source src={intro} />
					</video>
				</Item>

				<Item duration={13} className="active">
					<div className="transition-bg">
						<video id="transition">
							<source src={transition} />
						</video>
					</div>
					<MapEurope data={weather} />
				</Item>

				<Item duration={16.3}>
					<div className="transition-bg">
						<video id="transition">
							<source src={transition} />
						</video>
					</div>
					<MapBalkan data={weather} />       
				</Item>

				<Item duration={11.3}>
					<div className="transition-bg">
						<video id="transition">
							<source src={transition} />
						</video>
					</div>
					<Macedonia3Days data={weather} />
				</Item>
				
				<Item duration={1.3}>
					<div className="transition-bg">
						<video id="transition">
							<source src={transition} />
						</video>
					</div>
				</Item>

				<Item duration={13} pauseAudio={1}>
					<video id="transition">
						<source src={intro} />
					</video>
				</Item>
			</Slider>
		</div>
	)
}

export default TemplateVistelDnevna