import React, { useEffect, useRef, useState } from 'react'
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc'

const TemplatesDownload = () => {
	const [blob, setBlob] = useState(null);
	const recorderRef = useRef(null);

	const [ button, setButton ] = useState('start')

	const displayMediaStreamConstraints = {
		video: {
			displaySurface: 'application', // monitor, window, application, browser
			cursor: 'never', // never, always, motion
			frameRate: 30,
			width: {
				ideal: 1920, max: 1920
			},
			height: {
				ideal: 1080, max: 1080
			}
		},
		audio: {
			sampleRate: 44100,
			bufferSize: 4096
		}
	};


	// Connect to the channel named "record".
	const channel = new BroadcastChannel('record');

	// 
	const handleRecording = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaStreamConstraints);
			
			recorderRef.current = new RecordRTC(mediaStream, {
				type: 'video',
				bitsPerSecond: 51200000,
				mimeType: 'video/webm;codecs=h264',
			});

			recorderRef.current.startRecording();
			setButton('recording');
			channel.postMessage('start');
		} catch (err) {
			/* handle the error */
			alert('Permision denied')
		}
	};

	// handle stop recording when broadcasted stop
	channel.onmessage = async function(e) {
		if ( e.data === 'stop' )
			handleStop();
	};

	const handleStop = () => {
		recorderRef.current.stopRecording(() => {
			setButton('stop');
			setBlob(recorderRef.current.getBlob());
		});
	  };

	const handleSave = () => {
		invokeSaveAsDialog(blob);
		recorderRef.current.destroy();
		setButton('done');
	};
	

	useEffect(() => {
		if ( blob )
			handleSave();
	}, [blob])

	return (
		<>
			<header className="template-download">
				{ button === 'start' && <button className='btn btn-primary' onClick={handleRecording}>Start</button> }
				{ button === 'recording' && <button className='btn btn-primary'>Video is processing...</button> }
				{ button === 'stop' && <button className='btn btn-primary'>Downloading..</button> }
				{ button === 'done' && <button className='btn btn-primary'>Finished</button> }
			</header>
		</>
	)
}

export default TemplatesDownload