import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const UnprotectedRoute = ({children}) => {
	const isAuthenticated = useSelector( state => state.user.token )

	return isAuthenticated ? <Navigate to="/" /> : children
};

export default UnprotectedRoute;