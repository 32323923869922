const Modal = ({ children, size }) => {

	return (
		<>
			<div className="modal">
				<div className={`modal-dialog ${size}`}>
					{children}
				</div>
			</div>
		</>
	)
}

export default Modal