import React from 'react'
import NavItem from '../aside/nav-item.component'
import { IconCloudBolt, IconLayoutDashboard, IconReportMoney, IconSettings, IconTemplate, IconUsers } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from '../../config';

const Navigation = () => {
	// Get the user role of logged user
	const user = useSelector( state => state.user.info )

	return (
		<ul id="aside-dashboard" className="navigation">
			<NavItem link='/' icon={<IconLayoutDashboard size={22} stroke={2} />} text="Почетна" />
			{ user.isAdmin ? <NavItem link='/weather' icon={<IconCloudBolt size={22} stroke={2} />} text="Временска Прогноза" /> : '' }
			{ user.isAdmin ? <NavItem link='/templates' icon={<IconTemplate size={22} stroke={2} />} text="Темплејти" /> : <NavItem link='/templates' icon={<IconTemplate size={22} stroke={2} />} text="Темплејти" /> }
			{ user.isAdmin ? <NavItem link='/customers' icon={<IconUsers size={22} stroke={2} />} text="Корисници" /> : '' }
			<NavItem link='/invoices' icon={<IconReportMoney size={22} stroke={2} />} text="Сметки" />
			<NavItem link='/settings' icon={<IconSettings size={22} stroke={2} />} text="Подесување" />
		</ul>
	)
}

export default Navigation