// App CSS conataining the bootstrap
import './App.css';
import './assets/scss/style.scss';

// Router
import { Routes, Route, useLocation } from "react-router-dom";

// Screens
import Login from './routes/login/Login'
import Dashboard from './routes/dashboard/Dashboard'

import ProtectedRoute from './middleware/protected-route';
import UnprotectedRoute from './middleware/unprotected-route';
import Customers from './routes/customers/Customers';
import Customer from './routes/customers/Customer';
import Invoices from './routes/invoices/Invoices';
import Invoice from './routes/invoice/Invoice';
import Templates from './routes/templates/Templates';
import Weather from './routes/weather/Weather';
import Settings from './routes/settings/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, setUser } from './store/user/user.action';
import { useQuery } from '@tanstack/react-query';
import { checkToken } from './fetch/auth.fetch';
import { useEffect } from 'react';
import ProtectedAdminRoute from './middleware/protected-admin-route';
import PageNotFound from './routes/page-not-found/page-not-found';
import CityWeather from './routes/weather/City';
import InvoicesCustomer from './routes/invoices/InvoicesCustomer';
import CustomerTemplates from './routes/templates/CustomerTemplates';
import CustomerDashboard from './routes/dashboard/CustomerDashboard';
import TemplateVistelUtrinska from './templates/vistel-utrinska/template-vistel-utrinska';
import TemplateVistelDnevna from './templates/vistel-dnevna/template-vistel-dnevna';
import TemplateVistelVecerna from './templates/vistel-vecerna/template-vistel-vecerna';
import TemplateDownload from './routes/templates/TemplateDownload';
import { Helmet } from 'react-helmet';
import TemplateSitelUtrinska from './templates/sitel-utrinska/template-sitel-utrinska';
import TemplateSitelDnevna12 from './templates/sitel-dnevna-12/template-sitel-dnevna-12';
import TemplateSitelDnevna16 from './templates/sitel-dnevna-16/template-sitel-dnevna-16';
import TemplateSitelVecerna from './templates/sitel-vecerna/template-sitel-vecerna';
import TemplateLokalnaDnevna from './templates/lokalna-dnevna/template-lokalna-dnevna';
import TemplateSitelDnevna16Tomorrow from './templates/sitel-dnevna-16-1day/template-sitel-dnevna-16';
import TemplateSitelDnevna16DayAfterTomorrow from './templates/sitel-dnevna-16-2day/template-sitel-dnevna-16';
import TemplateSitelVecernaDayAfterTomorrow from './templates/sitel-vecerna-2day/template-sitel-vecerna';
import TemplateSitelVecernaTomorrow from './templates/sitel-vecerna-1day/template-sitel-vecerna';

function App() {
    const token = localStorage.getItem('token');
    const token_expire = localStorage.getItem('token_expire');
    const dispatch = useDispatch();
	const user = useSelector( state => state.user )
    const location = useLocation();

    // ----------------------------------------------------------
	// Checking if token is expired or not
	// ----------------------------------------------------------
    const { refetch } = useQuery({
        queryKey: ['user', token],
        queryFn: () => checkToken(token),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            if ( data?.data?.user )
            {
                dispatch(setUser(data.data))
            } else dispatch(removeUser())
        },
        onError: (error) => {
            dispatch(removeUser());
        }
    });


    useEffect( () => {
        if ( token_expire )
        {
            var now = Date.now();

            if ( token_expire <= now )
            {
                refetch()
            }
        }
    }, [token_expire, location] )



    // if ( fetchStatus !== 'idle' && isLoading )
    //     return (<div className="w-100 h-100 d-flex align-items-center justify-content-center">Loading...</div>);

    return (
        <Routes>

            <Route path="/login" element={
                <UnprotectedRoute>
                    <Helmet>
                        <title>Најава | Weather4All</title>
                    </Helmet>
                    <Login />
                </UnprotectedRoute>
            } />
           
            { /* Admin Routes */ }

            <Route path="/" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Weather4All</title>
                    </Helmet>
                    { user.info.isAdmin ? <Dashboard /> : <CustomerDashboard /> }
                </ProtectedRoute>
            } />
            
            <Route path="/weather" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Временска Прогноза | Weather4All</title>
                        </Helmet>
                        <Weather />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/weather/:city" element={ 
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <CityWeather />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/templates" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Темплејти | Weather4All</title>
                    </Helmet>
                    { user.info.isAdmin ? <Templates /> : <CustomerTemplates /> }
                </ProtectedRoute>
            } />

            <Route path="/templates/vistel-utrinska" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Вистел Утринска | Weather4All</title>
                        </Helmet>
                        <TemplateVistelUtrinska />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />
            
            <Route path="/templates/vistel-dnevna" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Вистел Дневна | Weather4All</title>
                        </Helmet>
                        <TemplateVistelDnevna />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/templates/vistel-vecerna" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Вистел Вечерна | Weather4All</title>
                        </Helmet>
                        <TemplateVistelVecerna />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-utrinska" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Утринска | Weather4All</title>
                    </Helmet>
                    <TemplateSitelUtrinska />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-dnevna-12" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Дневна 12h | Weather4All</title>
                    </Helmet>
                    <TemplateSitelDnevna12 />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-dnevna-16" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Дневна 16h | Weather4All</title>
                    </Helmet>
                    <TemplateSitelDnevna16 />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-dnevna-16-1day" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Дневна 16h | Weather4All</title>
                    </Helmet>
                    <TemplateSitelDnevna16Tomorrow />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-dnevna-16-2day" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Дневна 16h | Weather4All</title>
                    </Helmet>
                    <TemplateSitelDnevna16DayAfterTomorrow />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-vecerna" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Вечерна | Weather4All</title>
                    </Helmet>
                    <TemplateSitelVecerna />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-vecerna-1day" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Вечерна | Weather4All</title>
                    </Helmet>
                    <TemplateSitelVecernaTomorrow />
                </ProtectedRoute>
            } />

            <Route path="/templates/sitel-vecerna-2day" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Сител Вечерна | Weather4All</title>
                    </Helmet>
                    <TemplateSitelVecernaDayAfterTomorrow />
                </ProtectedRoute>
            } />

            <Route path="/templates/lokalna-dnevna" element={
                <ProtectedRoute>
                    <Helmet>
                        <title>Локална Дневна | Weather4All</title>
                    </Helmet>
                    <TemplateLokalnaDnevna />
                </ProtectedRoute>
            } />

            <Route path="/templates/download" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Вистел Вечерна | Weather4All</title>
                        </Helmet>
                        <TemplateDownload />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/customers" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Helmet>
                            <title>Корисници | Weather4All</title>
                        </Helmet>
                        <Customers />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/customers/:uid" element={
                <ProtectedRoute>
                    <ProtectedAdminRoute>
                        <Customer />
                    </ProtectedAdminRoute>
                </ProtectedRoute>
            } />

            <Route path="/profile" element={
                <ProtectedRoute>
                    <Helmet>
						<title>Профил | Weather4All</title>
					</Helmet>
                    <Customer />
                </ProtectedRoute>
            } />

            <Route path="/invoices" element={
                <ProtectedRoute>
                    <Helmet>
						<title>Сметки | Weather4All</title>
					</Helmet>
                    { user.info.isAdmin ? <Invoices /> : <InvoicesCustomer /> }
                </ProtectedRoute>
            } />

            <Route path="/invoices/:invoiceID" element={
                <ProtectedRoute>
                    <Invoice />
                </ProtectedRoute>
            } />

            <Route path="/settings" element={
                <ProtectedRoute>
                    <Helmet>
						<title>Поднесување | Weather4All</title>
					</Helmet>
                    <Settings />
                </ProtectedRoute>
            } />

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default App;
