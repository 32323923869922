import { useState, createContext, useEffect } from "react";

export const colorModeContext = createContext();

export const ColorModeProvider = ({ children }) => {
    const [colorMode, setColorMode] = useState(localStorage.getItem('theme'));

    useEffect( () => {
        if ( colorMode === null || ( colorMode !== 'light' && colorMode !== 'dark' ) )
            localStorage.setItem('theme', 'light');
        else
            localStorage.setItem('theme', colorMode);
        
        document.querySelector("html").setAttribute("theme", colorMode)
    }, [colorMode])

	const value = { colorMode, setColorMode };
	return <colorModeContext.Provider value={value}>{children}</colorModeContext.Provider>;
};
