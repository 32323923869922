import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './store/store';

import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { ColorModeProvider } from './context/colorMode.context';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { DataProvider } from './context/data.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const queryClient = new QueryClient()
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
        },
    },
});

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <DataProvider>
                    <ColorModeProvider>
                        <App />
                    </ColorModeProvider>
                </DataProvider>
            </QueryClientProvider>
        </Provider>
    </BrowserRouter>
);