import React, { useEffect } from 'react'
import moment from 'moment';

import { getCurrentWeatherIcon, GetWeatherCurrentTemp, getWeatherIcon, GetWeatherMaxTemp } from '../components/weather_info';

const CityForecast = ({data, cities}) => {

	return (
		<>
			<div className="utrinska-city-forecast delayed-screen" data-delay="10">
				<div className="utrinska-city-forecast_header">
					<div className='min-temp'>Моментални<br />Температури</div>
					<div className='date capitalize'>{moment().format('D.MM.YYYY')}</div>
					<div className='max-temp'>Максимални<br />Температури</div>
				</div>

				<div className="utrinska-city-forecast_body">
					{ cities?.map( (city, key) => 
						<div className="utrinska-city-forecast_body__city" key={key}>
							<div className='min-temp'>
								<img src={getCurrentWeatherIcon( data, city )} width="90" height="90" alt="Icons" />
								<p><GetWeatherCurrentTemp city={city} day={0} />°</p>
							</div>
							<div className='city'>{data[city]?.name}</div>
							<div className='max-temp'>
								<img src={getWeatherIcon( data, city, 0 )} width="90" height="90" alt="Icons" />
								<p><GetWeatherMaxTemp city={city} day={0} />°</p>
							</div>
						</div>
					) }
				</div>
			</div>
		</>
	)
}

export default CityForecast