import React from 'react'
import { useSelector } from 'react-redux';
import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons';

export const GetWeatherCurrentTemp = ( {city, day} ) => {
	const data = useSelector(state => state.weather.collection)
	return (
		<>{ parseFloat(data[city]?.current?.temperature).toFixed() }</>
	)
}

export const GetWeatherMaxTemp = ( {city, day} ) => {
	const data = useSelector(state => state.weather.collection)

	return (
		<>{data[city]?.forecast[day]?.updated_data !== null && data[city]?.forecast[day]?.updated_data !== undefined ? parseFloat(JSON.parse(data[city]?.forecast[day]?.updated_data)?.temp_max).toFixed() : data[city]?.forecast[day]?.data?.temp_max ? parseFloat(data[city]?.forecast[day]?.data?.temp_max).toFixed() : '0' }</>
	)
}

export const GetWeatherMinTemp = ( {city, day} ) => {
	const data = useSelector(state => state.weather.collection)

	return (
		<>{data[city]?.forecast[day]?.updated_data !== null && data[city]?.forecast[day]?.updated_data !== undefined ? parseFloat(JSON.parse(data[city]?.forecast[day]?.updated_data)?.temp_min).toFixed() : data[city]?.forecast[day]?.data?.temp_min ? parseFloat(data[city]?.forecast[day]?.data?.temp_min).toFixed() : '0' }</>
	)
}

export const GetWeatherIcon = ( {city, day} ) => {
	const data = useSelector(state => state.weather.collection)

	return SITEL_ICONS[data[city]?.forecast[day]?.data?.icon].link
}

export const getWeatherIcon = (data, city, day) => {
	if ( data[city]?.forecast[day]?.updated_data )
	{
		if ( JSON.parse(data[city]?.forecast[day]?.updated_data).icon )
		{
			return SITEL_ICONS[JSON.parse(data[city]?.forecast[day]?.updated_data).icon].link;
		}
	}
	
	return SITEL_ICONS[data[city]?.forecast[day]?.data?.icon]?.link !== undefined ? SITEL_ICONS[data[city]?.forecast[day]?.data?.icon]?.link : ''
}


export const getCurrentWeatherIcon = (data, city) => {
	return SITEL_ICONS[data[city]?.current?.icon]?.link !== undefined ? SITEL_ICONS[data[city]?.current?.icon]?.link : ''
}