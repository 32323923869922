import { IconRosette, IconArrowRight, IconDiscountCheckFilled } from '@tabler/icons-react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { URL } from '../../config';
import EditButton from '../buttons/edit-button.component'
import DeleteButton from '../buttons/delete-button.component'
import EditTemplateModal from '../../modals/edit-template.modal';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from 'react';
import RemoveTemplate from '../../modals/remove-template.modal';

const TemplatesTable = ({templates, refetchTemplates, sliceFrom, sliceTo}) => {
	// ----------------------------------------------------------
	// Use navigate hook for redirecting
	// ----------------------------------------------------------
	const navigate = useNavigate();

	const [ templateData, setTemplateData ] = useState(false)
	const [ openModal, setOpenModal ] = useState(false)
	const [ removeModal, setRemoveModal ] = useState(false)

	useEffect( () => {
		if (!openModal)
			setTemplateData(false)
	}, [openModal])

	useEffect( () => {
		if (!removeModal)
			setRemoveModal(false)
	}, [removeModal])


	// ----------------------------------------------------------
	// Handling escape keyboard press for closing all modals
	// ----------------------------------------------------------
	useEffect( () => {
		if ( openModal === true )
		{
			document.addEventListener('keydown', (e) => {
				if ( e.key === 'Escape' )
				{
					setOpenModal(false)
				}
			}, false)
		}
	}, [openModal] );


	const handleTemplateHref = (key) => {
		
	}

	return (
		<>
			<table className="table table-modern table-hover">
				<thead className="">
					<tr>
						<th>Име</th>
						<th>Клуч</th>
						<th>Корисници</th>
						<th>Активен</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ templates?.length !== 0 ? <>
					{ templates.slice(sliceFrom, sliceTo).map( (template, i) => (
						<tr key={template.id}>
							<td>
								<div className="fs-6 fw-bold">{template.name}</div>
								<div className="text-muted"><small>{template.description}</small></div>
							</td>
							<td>
								<span className="">{template.slug}</span>
							</td>
							<td>
								<div className="avatar-group">
								{
									template.users.slice(0, 3).map( user => <div key={user.id} className="d-flex align-items-center justify-content-center fw-bold avatar rounded-circle border border-2 border-white bg-l50-primary tool-tip" data-tooltip={user.first_name+" "+user.last_name} style={{ width: 32, height: 32 }}>{user.first_name[0]+user.last_name[0]}</div> )
								}
								{ template.users.length > 3 ? <div className="avatar-more" style={{ width: "32px", height: "32px" }}>+{template.users.length - 3}</div> : '' }
								</div>
							</td>
							<td>
								{ template.active === 1 ? <span className="text-success"><IconDiscountCheckFilled size={18} className="me-2" />Активен</span> : <span className="text-danger"><IconRosette size={18} className="me-2" />Неактивен</span> }
								
							</td>
							<td align='right'>
								<EditButton onClick={ () => { setOpenModal(true); setTemplateData(template) } } onlyIcon />
								<Link to={`/templates/${template.slug}`} className="btn btn-dark shadow-sm btn-only-icon ms-2 me-2"><IconArrowRight size={14} /></Link>
								<DeleteButton onClick={ () => { setRemoveModal(true); setTemplateData(template) } } onlyIcon={true}><IconArrowRight size={14} /></DeleteButton>
							</td>
						</tr>
					) ) }
					</> : <tr><td align="center" colSpan="7">Не се пронајдени темплејти</td></tr> }
				</tbody>
			</table>

			{/* Edit Template Modal */}
			<CSSTransition in={openModal} timeout={0} classNames="fade">
				<EditTemplateModal refetchTemplates={refetchTemplates} template={templateData} setCloseModal={setOpenModal} />
			</CSSTransition>

			{/* Suspend User Modal */}
			<CSSTransition in={removeModal} timeout={0} classNames="fade">
				<RemoveTemplate refetchTemplates={refetchTemplates} template={templateData} setModal={setRemoveModal} />
			</CSSTransition>
		</>
	)
}

export default TemplatesTable