import Aside from "../aside/aside.component"
import Footer from "../footer/footer.component"
import Header from "../header/header"

const Page = ({children, name}) => {

	return (
		<div className={`app ${name ? name : ''}`}>
			{ name !== 'login' ? <Aside /> : '' }

            <div className="wrapper">
				{ name !== 'login' ? <Header /> : '' }
				
				<div className="content">
					<div className="page-wrapper container-fluid">
						<div className="page pt-0 container-xxl">
							{children}
						</div>
					</div>
				</div>
				
				
				{ name !== 'login' ? <Footer /> : '' }
			</div>
		</div>
	)
}

export default Page