import { combineReducers } from "redux";

import { userReducer } from "./user/user.reducer";
import { weatherReducer } from "./weather/weather.reducer";
import { customersReducer } from "./customers/customers.reducer";

export const rootReducer = combineReducers({
	user: userReducer,
	customers: customersReducer,
	weather: weatherReducer,
})