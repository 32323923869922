import WeatherCityForecast from '../weather/weather-forecast-card/weather-city-forecast.component';

const CitiesTable = ({cities, sliceFrom, sliceTo, refetch}) => {

	return (
		<>
			<table className="table table-modern table-hover">
				<thead className="">
					<tr>
						<th>Град</th>
						<th>Икона</th>
						<th>Температура</th>
						<th>Брзина ветер</th>
						<th>Насока</th>
						<th>Врнежи</th>
						<th>Измерено</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ cities?.length !== 0 ? <>
					{ cities?.slice(sliceFrom, sliceTo).map( data => (
						<WeatherCityForecast key={data.id} refetch={refetch} data={data} /> 
					) ) }
					</> : <tr><td align="center" colSpan="7">No customers found</td></tr> }
				</tbody>
			</table>
		</>
	)
}

export default CitiesTable;