import { ACTION_TYPES } from './user.types';

export const setUser = (user) => {
	return { type: ACTION_TYPES.SET_USER, payload: user };
}

export const updateUserInfo = (user) => {
	return { type: ACTION_TYPES.UPDATE_USER_INFO, payload: user };
}

export const removeUser = () => {
	return { type: ACTION_TYPES.REMOVE_USER, payload: '' };
}
