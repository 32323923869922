import React, { useEffect } from 'react'

import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import moment from 'moment';
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info';

const Forecast3Days = ({data, bg}) => {

	const threeDays = [2, 3, 4];

	return (
		<>
			<div className="video-bg">
				<img src={bg} alt="Nature" />
			</div>

			<div className="forecast-3days">
				<h1>Македонија ●<span> во наредни 3 дена</span></h1>

				<div className="forecast-3days-boxes">

					{ threeDays.map((i) =>
						<div className="forecast-3days-boxes_box">
							<div className="forecast-3days-boxes_box_header capitalize">{moment(data['Скопје']?.forecast[i]?.date*1000).format('dddd')}</div>
							<div className="forecast-3days-boxes_box_max-temp">
								<p><GetWeatherMaxTemp city="Маврово" day={i} />°</p>
								<p><GetWeatherMaxTemp city="Гевгелија" day={i} />°</p>
							</div>
							<div className="forecast-3days-boxes_box_icon">
								<img src={getWeatherIcon( data, 'Гевгелија', i )} width="300" height="300" alt={SITEL_ICONS[data['Гевгелија']?.forecast[i]?.data?.icon]?.value} />
							</div>
							<div className="forecast-3days-boxes_box_min-temp">
								<p><GetWeatherMinTemp city="Маврово" day={i} />°</p>
								<p><GetWeatherMinTemp city="Гевгелија" day={i} />°</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default Forecast3Days