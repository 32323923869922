import SearchSubheader from "../../components/subheader/search-subheader.component"
import Page from "../../layouts/page/page.component"
import { useQuery } from "@tanstack/react-query"
import Loader from "../../components/loader/loader.component"
import { IconUsers } from "@tabler/icons-react"
import CardHeader from "../../components/card/card-header.component"
import Card from "../../components/card/card.component"
import CardBody from "../../components/card/card-body.component"
import { useContext, useEffect, useState } from "react"
import CardFooter from "../../components/card/card-footer.component"
import TableFooter from "../../components/table/table-footer.component"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../store/user/user.action"
import { ITEMS_PER_PAGE } from "../../config"
import { DataContext } from "../../context/data.context"
import { getTemplates } from "../../fetch/templates.fetch"
import TemplatesTable from "../../components/table/templates-table.component"

const Templates = () => {

	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)
	const dispatch = useDispatch();

	// ----------------------------------------------------------
	// Defining States
	// ----------------------------------------------------------
	const { templatesData, setTemplatesData } = useContext(DataContext);
	const [searchInput, setSearchInput] = useState('')
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)
	const [pagination, setPagination] = useState({
		totalPages: 1,
		currentPage: 1,
	});

	// ----------------------------------------------------------
	// Fetching all templates
	// ----------------------------------------------------------
	const templatesQuery = useQuery({
		queryKey: ['templates'],
		queryFn: () => getTemplates(token),
		staleTime: 2000,
		onSuccess: (res) => {
			setTemplatesData(res.data.templates);
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
			else
				dispatch(removeUser());
		}
	});


	// ----------------------------------------------------------
	// Changing pagination depending on the filtered data
	// ----------------------------------------------------------
	useEffect( () => {
		if (templatesData.length)
		{
			setPagination({
				currentPage: 1,
				totalPages: Math.ceil(templatesData?.length / itemsPerPage),
			});
		}
	}, [templatesData]);


	// ----------------------------------------------------------
	// Filter customers 
	// ----------------------------------------------------------
	useEffect( () => {
		templatesQuery?.data?.data.templates && setTemplatesData(templatesQuery?.data?.data.templates.filter(template => {
			var template = template.name+' '+template.description;
			return template.toLowerCase().includes(searchInput.toLowerCase())
		}))
	}, [searchInput] )


	// ----------------------------------------------------------
	// Calculating the slice from to for the data
	// ----------------------------------------------------------
	var sliceTo = pagination.currentPage * itemsPerPage;
	var sliceFrom = sliceTo - itemsPerPage;
	
	return (
		<Page>
			<SearchSubheader page="templates" refetch={templatesQuery.refetch} setSearchInput={setSearchInput} />

			<div className="row h-100 mt-4">
				<div className="col-sm-12 col-lg-12">
				{ ( templatesQuery.isLoading || templatesQuery.isFetching ) ? <Loader isCentered /> :
					<Card className="card-stretch-full">
						<CardHeader icon={<IconUsers size={30} color="#6c5dd3" />} title="Темплејти" />

						<CardBody>
							<TemplatesTable templates={templatesData} refetchTemplates={templatesQuery.refetch} pagination={pagination} setItemsPerPage={setItemsPerPage} sliceTo={sliceTo} sliceFrom={sliceFrom} setPagination={setPagination} />
						</CardBody>

						<CardFooter>
							<TableFooter sliceFrom={sliceFrom} sliceTo={sliceTo} items={templatesData} pagination={pagination} setPagination={setPagination} setItemsPerPage={setItemsPerPage} />
						</CardFooter>
					</Card>
				}
				</div>
			</div>
		</Page>
	)
}

export default Templates