import React from 'react'

const ModalHeader = ({title}) => {
	return (
		<div className="modal-header">
			<h5 id="exampleModalLabel" className="modal-title">{title}</h5>
		</div>
	)
}

export default ModalHeader