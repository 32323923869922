import React, { useEffect, useState } from 'react'
import Slider from '../components/slider'
import Item from '../components/item'
import './sitel-utrinska.scss';
import CityForecast from './city-forecast';
import MapForecast from './map-forecast';

import bg from './assets/blue-bg.mp4'
import transition from './assets/animation.webm';
import Video from '../components/video';
import { useSelector } from 'react-redux';

import StartEndImage from '../icons/start_end.jpg';

const TemplateSitelUtrinska = () => {
	const [ slideIndex, setSlideIndex ] = useState()
	const channel = new BroadcastChannel('record');
	const weather = useSelector(state => state.weather.collection)

	// Listen for messages on "record".
	channel.onmessage = function(e) {
		if ( e.data === 'start')
			setSlideIndex(0)
	};

	const handleDownload = (e) => {
		setSlideIndex(0)

		// window.open('/templates/download', "_blank", "width=400,height=200,menubar=no,location=no,status=no,titlebar=no,scrollbars=no")
		e.currentTarget.remove();
	}

	useEffect( () => {
		setTimeout( () => {
			setSlideIndex(0);
		}, 5000 )
	}, [] )

	return (
		<div className="template sitel-utrinska">
			<Slider slideIndex={slideIndex} setSlideIndex={setSlideIndex}>
				{/* <button className='btn btn-primary start-template download' onClick={(e) => handleDownload(e) }>
					<IconPlayerPlay size={48} stroke={3} />
				</button> */}

				<Item duration={'space'}>
					<div className="image-bg">
						<img src={StartEndImage} alt="BG" />
					</div>
				</Item>

				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<Video src={transition} muted />
					</div> */}

					<div className="image-bg satelite">
						<img src="https://maps.neverin.hr/sat/anim_eu.webp" alt="Satelite" />
					</div>
				</Item>
				
				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div> */}

					<div className="video-bg">
						<Video src={bg} autoPlay muted />
					</div>

					<CityForecast data={weather} cities={['Скопје', 'Тетово', 'Гостивар', 'Дебар', 'Охрид', 'Крушево', 'Битола', 'Прилеп']} />
				</Item>

				<Item duration={'space'} className="shorter-delay">
					<div className="video-bg">
						<Video src={bg} autoPlay muted />
					</div>

					<CityForecast data={weather} cities={['Велес', 'Кавадарци', 'Гевгелија', 'Струмица', 'Штип', 'Берово', 'Кочани', 'Куманово']} />
				</Item>

				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div> */}

					<MapForecast data={weather} />
				</Item>

				<Item duration={'space'}>
					<div className="image-bg">
						<img src={StartEndImage} alt="BG" />
					</div>
				</Item>
			</Slider>
		</div>
	)
}

export default TemplateSitelUtrinska