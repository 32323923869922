import React from 'react'
import NotFound from '../../assets/images/404.png';
import { IconHome } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
	return (
		<div className="wrapper">
			<main className="content">
				<div className="page-wrapper container-fluid">
					<div className="row d-flex align-items-center h-100 pb-3">
						<div className="col-12 d-flex flex-column justify-content-center align-items-center">
							<div className="text-primary fw-bold" style={{fontSize: 'calc(3rem + 3vw)'}}>404</div>
							<div className="text-dark fw-bold" style={{fontSize: 'calc(1.5rem + 1.5vw)'}}>Page not found</div>
						</div>

						<div className="col-12 d-flex align-items-baseline justify-content-center">
							<img src={NotFound} alt="Humans" style={{height: '50vh'}} />
						</div>

						<div className="col-12 d-flex flex-column justify-content-center align-items-center">
							<Link class="btn btn-light-primary px-5 py-3" to="/" rel="noopener">
								<IconHome className="svg-icon--material svg-icon btn-icon" />
								Homepage
							</Link>
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}

export default PageNotFound