import React, { useEffect } from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconClipboardCheck } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { createService, editService } from '../fetch/services.fetch'
import moment from 'moment'

const NewServiceModal = ({user_id, templates, refetch, setCloseModal, serviceData}) => {
	const token = useSelector(state => state.user.token)

    // ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
        // detecting if editing or creating new service
		mutationFn: (data) => serviceData ? editService(serviceData?.id, data, token) : createService(data, token),
		onSuccess: () => {
            setCloseModal(false)
			refetch()
            reset()
		},
	})

	
	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, watch, formState: { errors }, setValue, handleSubmit, reset } = useForm();
  	const onSubmit = data => {
        // detecting if editing or creating new service
		const newData = serviceData ? {...data} : {...data, user_id: user_id}
		mutation.mutate(newData)
	};


	// ----------------------------------------------------------
    // Setting input value if editing service
	// ----------------------------------------------------------
    useEffect( () => {
		if ( serviceData )
		{
            // formated specificaly for input default date
            var date = moment(serviceData.active_to).format('YYYY-MM-DD');

			setValue('title', serviceData.title);
			setValue('price', serviceData.price);
			setValue('theme', serviceData.theme);
			setValue('key', serviceData.key);
			setValue('active_to', date);
		} else {
            setValue('title', '');
			setValue('price', '');
			setValue('theme', '');
			setValue('key', '');
			setValue('active_to', '');
        }
	}, [serviceData])


    return (
        <Modal size="modal-xl">
            <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                <ModalHeader title="Нов сервис" />
                
                <ModalBody>
                    <div className="row g-4">
                        <div className="col-md-12">
                            <label htmlFor="title" className="form-label">Наслови</label>
                            <input id="title" type="text" className="form-control" {...register("title", { required: "Please enter service title." })} />
                            <p className='text-danger mb-0'>{errors?.title?.message}</p>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="price" className="form-label">Цена</label>
                            <input id="price" type="text" className="form-control" {...register("price", { required: "Please enter service price." })} />
                            <p className='text-danger mb-0'>{errors?.price?.message}</p>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="theme" className="form-label">Темплејт</label>
                            <select id="theme" className="form-control" {...register("theme", { valueAsNumber: true, required: "Изберете темплејт." })}>
                                <option selected disabled>Избери темплејт</option>
                                { templates && templates.map( template => 
                                    <option value={template.id}>{template.name}</option>
                                ) }
                            </select>
                            <p className='text-danger mb-0'>{errors?.theme?.message}</p>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="key" className="form-label">Клуч</label>
                            <input id="key" type="text" className="form-control" {...register("key", { required: "Please enter key." })} />
                            <p className='text-danger mb-0'>{errors?.password?.message}</p>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="active_to" className="form-label">Активен до</label>
                            <input id="active_to" type="date" className="form-control" {...register("active_to")} />
                            <p className='text-danger mb-0'>{errors?.date?.message}</p>
                        </div>

                        { !serviceData ? 
                        <div className="col-md-8 d-flex align-items-center">
                            <div>
                                <div className="form-check mt-4 d-flex align-items-center">
                                    <input className="form-check-input" name="defaultCheck" type="checkbox" id="flexCheckDefault" {...register("invoice") } />
                                    <label className="form-check-label ms-2 mt-1 fs-6 fw-semibold" htmlFor="flexCheckDefault">Генерирај сметка</label>
                                </div>
                            </div>
                        </div> : '' }

                        {
                            watch("invoice") ? <>
                        <div className="col-md-4">
                            <label htmlFor="price" className="form-label">Валута</label>
                            <input id="price" type="text" className="form-control" value={'MKD'} disabled {...register("currency")} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="discount" className="form-label">Попуст</label>
                            <input id="discount" type="text" className="form-control" {...register("discount")} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="tax" className="form-label">Данок</label>
                            <input id="tax" type="text" className="form-control" {...register("tax")} />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="note" className="form-label">Забелешка</label>
                            <textarea id="note" className="form-control" {...register("note")}></textarea>
                        </div>
                        </> : '' }
                    </div>
                </ModalBody>
                
                <ModalFooter>
                    <button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
                    { mutation.isLoading ? (
                        <button type="submit" disabled className="btn btn-primary">
                            <span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
                        </button>
                        ) : (
                        <button type="submit" className="btn btn-primary">
                            <IconClipboardCheck size={18} className="me-2" />
                            { serviceData ? 'Зачувај сервис' : 'Генерирај сервис' }
                        </button>
                    ) }
                </ModalFooter>
            </form>

            <div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
        </Modal>
    )
}

export default NewServiceModal