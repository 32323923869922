import React from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconClipboardCheck } from '@tabler/icons-react'
import { authRegister } from '../fetch/auth.fetch'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

const NewCustomerModal = ({refetchCustomers, setCloseModal}) => {
	const token = useSelector(state => state.user.token)


    // ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => authRegister(data, token),
		onSuccess: (res) => {
            setCloseModal(false)
			refetchCustomers()
    		reset();
		},
        onError: (error) => {

        }
	})

	
	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, formState: { errors }, handleSubmit, reset } = useForm();
  	const onSubmit = data => {
		mutation.mutate(data)
	};


    return (
        <Modal size="modal-xl">
            <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                <ModalHeader title="New Customer" />
                
                <ModalBody>
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="firstname" className="form-label">Име</label>
                            <input id="firstname" type="text" className="form-control" {...register("first_name", { required: "Please enter your first name." })} />
                            <p className='text-danger mb-0'>{errors?.first_name?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="lastname" className="form-label">Презиме</label>
                            <input id="lastname" type="text" className="form-control" {...register("last_name", { required: "Please enter your last name." })} />
                            <p className='text-danger mb-0'>{errors?.last_name?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="email" className="form-label">Е-адреса</label>
                            <input id="email" type="email" className="form-control" {...register("email", { required: "Please enter your email address." })} />
                            <p className='text-danger mb-0'>{errors?.email?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="status" className="form-label">Статус</label>
                            <select id="status" className='form-select' {...register("status", { required: "Please select account status." })}>
                                <option value="0" checked>Неактивен</option>
                                <option value="1">Активен</option>
                            </select>
                            <p className='text-danger mb-0'>{errors?.status?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="password" className="form-label">Лозинка</label>
                            <input id="password" type="password" className="form-control" {...register("password", { required: "Please enter your password." })} />
                            <p className='text-danger mb-0'>{errors?.password?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="confirm_password" className="form-label">Потврди лозинка</label>
                            <input id="confirm_password" type="password" className="form-control" {...register("password_confirmation", { required: "Please enter your password confirmation." })} />
                            <p className='text-danger mb-0'>{errors?.password_confirmation?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="address" className="form-label">Адреса</label>
                            <input id="address" type="text" className="form-control" {...register("address")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="city" className="form-label">Град</label>
                            <input id="city" type="text" className="form-control" {...register("city")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="zip" className="form-label">Поштенски број</label>
                            <input id="zip" type="text" className="form-control" {...register("zip")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="country" className="form-label">Држава</label>
                            <input id="country" type="text" className="form-control" {...register("country")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="edb" className="form-label">ЕДБ</label>
                            <input id="edb" type="text" className="form-control" {...register("edb")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="phone" className="form-label">Телефон</label>
                            <input id="phone" type="text" className="form-control" {...register("phone")} />
                        </div>
                    </div>
                </ModalBody>
                
                <ModalFooter>
                    <button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
                    { mutation.isLoading ? (
                        <button type="submit" disabled className="btn btn-primary">
                            <span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
                        </button>
                        ) : (
                        <button type="submit" className="btn btn-primary">
                            <IconClipboardCheck size={18} className="me-2" />
                            Креирај Клиент
                        </button>
                    ) }
                </ModalFooter>
            </form>

            <div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
        </Modal>
    )
}

export default NewCustomerModal