import { useState, createContext } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [customerData, setCustomerData] = useState([]);
    const [invoicesData, setInvoicesData] = useState([]);
    const [citiesWeatherData, setCitiesWeatherData] = useState([]);
    const [cityWeatherData, setCityWeatherData] = useState([]);
    const [cityWeatherInfoData, setCityWeatherInfoData] = useState([]);
    const [templatesData, setTemplatesData] = useState([]);

	const value = { customerData, setCustomerData, invoicesData, setInvoicesData, cityWeatherData, setCityWeatherData, citiesWeatherData, setCitiesWeatherData, templatesData, setTemplatesData, cityWeatherInfoData, setCityWeatherInfoData };
	return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
