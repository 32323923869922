import React from 'react'
import Card from '../card/card.component'
import CardBody from '../card/card-body.component'
import CardHeader from '../card/card-header.component'
import { IconPassword, IconUser } from '@tabler/icons-react'
import SaveButton from '../buttons/save-button.component'
import CardFooter from '../card/card-footer.component'

const PasswordChange = () => {
	return (
		<Card>
			<CardHeader title="Промени лозинка" icon={<IconPassword size={30} color="#6c5dd3" />} />
			<CardBody>
				<div className="row g-4">
					<div className="col-sm-12">
						<div className="form-floating">
							<input id="current_password" type="password" className="form-control" placeholder="Current Password" />
							<label htmlFor="current_password" className="form-label">Вашата лозинка</label>
						</div>
					</div>

					<div className="col-sm-12">
						<div className="form-floating">
							<input id="new_password" type="password" className="form-control" placeholder="New Password" />
							<label htmlFor="new_password" className="form-label">Нова лозинка</label>
						</div>
					</div>

					<div className="col-sm-12">
						<div className="form-floating">
							<input id="confirm_new_password" type="password" className="form-control" placeholder="Confirm New Password" />
							<label htmlFor="confirm_new_password" className="form-label">Потврди нова лозинка</label>
						</div>
					</div>
				</div>
			</CardBody>

			<CardFooter>
				<div></div>
				<SaveButton />
			</CardFooter>
		</Card>
  	)
}

export default PasswordChange