import React from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { IconClipboardCheck } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { createCity } from '../fetch/weather.fetch'

const NewCityModal = ({refetchCities, setCloseModal}) => {
	const token = useSelector(state => state.user.token)


    // ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => createCity(token, data),
		onSuccess: (res) => {
            setCloseModal(false);
			refetchCities();
		},
        onError: (error) => {

        }
	})

	
	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, formState: { errors }, handleSubmit } = useForm();
  	const onSubmit = data => {
          mutation.mutate(data);
        // var result = [];
        // Object.keys(data.api_urls).map( key => result[key] = data.api_urls[key] )
        // data.api_urls = result;
        
	};


    return (
        <Modal size="modal-xl">
            <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                <ModalHeader title="Нов Град" />
                
                <ModalBody>
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="name" className="form-label">Име</label>
                            <input id="name" type="text" className="form-control" {...register("name", { required: "Please enter city name." })} />
                            <p className='text-danger mb-0'>{errors?.name?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="country" className="form-label">Статус</label>
                            <select id="country" type="text" className="form-select" {...register("active", { required: "Please enter your email address." })}>
                                <option value="1" checked>Активен</option>
                                <option value="0" checked>Не активен</option>
                            </select>
                            <p className='text-danger mb-0'>{errors?.country?.message}</p>
                        </div>
                        <div className="col-sm-12 mt-36">
                            <h5 className='fw-bold mb-0'>АПИ Извори</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="foreca" className="form-label">Foreca</label>
                            <input id="foreca" type="text" className="form-control" {...register("api_urls.foreca]")} />
                        </div>
						<div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="meteoblue" className="form-label">Meteoblue</label>
                            <input id="meteoblue" type="text" className="form-control" {...register("api_urls.meteoblue]")} />
                        </div>
						<div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="meteovista" className="form-label">Meteovista</label>
                            <input id="meteovista" type="text" className="form-control" {...register("api_urls.meteovista]")} />
                        </div>
						<div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="uhmr" className="form-label">UHMR</label>
                            <input id="uhmr" type="text" className="form-control" {...register("api_urls.uhmr]")} />
                        </div>
						<div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="sinoptik" className="form-label">Sinoptik</label>
                            <input id="sinoptik" type="text" className="form-control" {...register("api_urls.sinoptik]")} />
                        </div>
						<div className="col-sm-12 col-md-6 col-lg-4">
                            <label htmlFor="morecast" className="form-label">Morecast</label>
                            <input id="morecast" type="text" className="form-control" {...register("api_urls.morecast]")} />
                        </div>

                    </div>
                </ModalBody>
                
                <ModalFooter>
                    <button type="button" onClick={() => setCloseModal(false)} className="btn btn-outline-info border-0">Затвори</button>
                    { mutation.isLoading ? (
                        <button type="submit" disabled className="btn btn-primary">
                            <span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
                        </button>
                        ) : (
                        <button type="submit" className="btn btn-primary">
                            <IconClipboardCheck size={18} className="me-2" />
                            Додај Град
                        </button>
                    ) }
                </ModalFooter>
            </form>

            <div className="modal-backdrop" onClick={ () => setCloseModal(false) }></div>
        </Modal>
    )
}

export default NewCityModal