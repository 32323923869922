import React, { useEffect } from 'react'
import audio from './assets/audio.mp3'
import Europe from './assets/europe.png'
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info'
import moment from 'moment'

const MapEurope = ({data}) => {

	return (
		<div className="delayed-screen">
			<audio src={audio} id="audio">
				<a href="/media/cc0-audio/t-rex-roar.mp3"> Download audio </a>
			</audio>

			<div className="europe">
				<div className="europe-bg">
					<img src={Europe} alt="Europe" />
				</div>

				<div className="cities">
					<div className="current-date" style={{ position: "absolute", top: 70, left: 70 }}>
						<h1>МАКЕДОНИЈА {moment().format('DD.MM.Y')}</h1>
						<h3>weather4all.com.mk</h3>
					</div>

					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080">
						<defs>
							<clipPath id="clip-path">
							<rect id="Rectangle_5" data-name="Rectangle 5" width="1920" height="1080" fill="#fff" stroke="#707070" stroke-width="1"/>
							</clipPath>
							<filter id="Лисабон" x="151.5" y="768.5" width="108" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Мадрид" x="318.5" y="686.5" width="100" height="36" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-2"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-2"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Париз" x="533.5" y="511.5" width="78" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-3"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-3"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Лондон" x="501.5" y="286.5" width="99" height="36" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-4"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-4"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Берлин" x="766.5" y="368.5" width="90" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-5"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-5"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Виена" x="803.5" y="543.5" width="78" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-6"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-6"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Осло" x="809.5" y="45.5" width="70" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-7"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-7"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Стокхолм" x="974.5" y="114.5" width="121" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-8"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-8"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Варшава" x="974.5" y="393.5" width="108" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-9"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-9"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Букурешт" x="1062.5" y="658.5" width="115" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-10"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-10"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Киев" x="1224.5" y="543.5" width="64" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-11"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-11"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Москва" x="1436.5" y="265.5" width="95" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-12"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-12"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Рим" x="778.5" y="768.5" width="57" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-13"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-13"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Скопје" x="974.5" y="827.5" width="86" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-14"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-14"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="атина" x="960.5" y="957.5" width="78" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-15"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-15"/>
							<feComposite in="SourceGraphic"/>
							</filter>
							<filter id="Анкара" x="1214.5" y="934.5" width="91" height="35" filterUnits="userSpaceOnUse">
							<feOffset dy="3" input="SourceAlpha"/>
							<feGaussianBlur stdDeviation="2.5" result="blur-16"/>
							<feFlood flood-opacity="0.2"/>
							<feComposite operator="in" in2="blur-16"/>
							<feComposite in="SourceGraphic"/>
							</filter>
						</defs>
						<g id="cities" data-name="Mask Group 3" clip-path="url(#clip-path)">
							<g id="Group_1" data-name="Group 1" transform="translate(60)">
							<rect id="EVROPA_00000__1" data-name="EVROPA (00000)_1" width="1920" height="1080" fill="none"/>
							<g id="box" transform="translate(-115 -46)">
								<rect id="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max">
								<rect id="Rectangle_4" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Лисабон' day={0} />°</tspan></text>
								</g>
								<g id="min">
								<rect id="Rectangle_6" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Лисабон' day={0} />°</tspan></text>
								</g>
								<g id="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Лисабон', 1)} />
								</g>
								<g id="title">
								<rect id="Rectangle_2" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, 55, 46)" filter="url(#Лисабон)">
									<text id="Лисабон-2" data-name="Лисабон" transform="translate(159 790)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ЛИСАБОН</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-2" data-name="box" transform="translate(52 -128)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-2" data-name="max">
								<rect id="Rectangle_4-2" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_2" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Мадрид' day={0} />°</tspan></text>
								</g>
								<g id="min-2" data-name="min">
								<rect id="Rectangle_6-2" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_2" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Мадрид' day={0} />°</tspan></text>
								</g>
								<g id="icon-2" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Мадрид', 1)} />
								</g>
								<g id="title-2" data-name="title">
								<rect id="Rectangle_2-2" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -112, 128)" filter="url(#Мадрид)">
									<text id="Мадрид-2" data-name="Мадрид" transform="translate(326 708)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">МАДРИД</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-3" data-name="box" transform="translate(267 -303)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-3" data-name="max">
								<rect id="Rectangle_4-3" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_3" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Париз' day={0} />°</tspan></text>
								</g>
								<g id="min-3" data-name="min">
								<rect id="Rectangle_6-3" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_3" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Париз' day={0} />°</tspan></text>
								</g>
								<g id="icon-3" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Париз', 1)} />
								</g>
								<g id="title-3" data-name="title">
								<rect id="Rectangle_2-3" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -327, 303)" filter="url(#Париз)">
									<text id="Париз-2" data-name="Париз" transform="translate(541 533)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ПАРИЗ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-4" data-name="box" transform="translate(235 -528)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-4" data-name="max">
								<rect id="Rectangle_4-4" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_4" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Лондон' day={0} />°</tspan></text>
								</g>
								<g id="min-4" data-name="min">
								<rect id="Rectangle_6-4" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_4" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Лондон' day={0} />°</tspan></text>
								</g>
								<g id="icon-4" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Лондон', 1)} />
								</g>
								<g id="title-4" data-name="title">
								<rect id="Rectangle_2-4" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -295, 528)" filter="url(#Лондон)">
									<text id="Лондон-2" data-name="Лондон" transform="translate(509 308)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ЛОНДОН</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-5" data-name="box" transform="translate(500 -446)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-5" data-name="max">
								<rect id="Rectangle_4-5" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_5" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Берлин' day={0} />°</tspan></text>
								</g>
								<g id="min-5" data-name="min">
								<rect id="Rectangle_6-5" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_5" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Берлин' day={0} />°</tspan></text>
								</g>
								<g id="icon-5" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Берлин', 1)} />
								</g>
								<g id="title-5" data-name="title">
								<rect id="Rectangle_2-5" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -560, 446)" filter="url(#Берлин)">
									<text id="Берлин-2" data-name="Берлин" transform="translate(774 390)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">БЕРЛИН</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-6" data-name="box" transform="translate(537 -271)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-6" data-name="max">
								<rect id="Rectangle_4-6" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_6" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Виена' day={0} />°</tspan></text>
								</g>
								<g id="min-6" data-name="min">
								<rect id="Rectangle_6-6" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_6" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Виена' day={0} />°</tspan></text>
								</g>
								<g id="icon-6" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Виена', 1)} />
								</g>
								<g id="title-6" data-name="title">
								<rect id="Rectangle_2-6" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -597, 271)" filter="url(#Виена)">
									<text id="Виена-2" data-name="Виена" transform="translate(811 565)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ВИЕНА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-7" data-name="box" transform="translate(543 -769)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-7" data-name="max">
								<rect id="Rectangle_4-7" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_7" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Осло' day={0} />°</tspan></text>
								</g>
								<g id="min-7" data-name="min">
								<rect id="Rectangle_6-7" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_7" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Осло' day={0} />°</tspan></text>
								</g>
								<g id="icon-7" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Осло', 1)} />
								</g>
								<g id="title-7" data-name="title">
								<rect id="Rectangle_2-7" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -603, 769)" filter="url(#Осло)">
									<text id="Осло-2" data-name="Осло" transform="translate(817 67)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ОСЛО</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-8" data-name="box" transform="translate(708 -700)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-8" data-name="max">
								<rect id="Rectangle_4-8" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_8" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Стокхолм' day={0} />°</tspan></text>
								</g>
								<g id="min-8" data-name="min">
								<rect id="Rectangle_6-8" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_8" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Стокхолм' day={0} />°</tspan></text>
								</g>
								<g id="icon-8" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Стокхолм', 1)} />
								</g>
								<g id="title-8" data-name="title">
								<rect id="Rectangle_2-8" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -768, 700)" filter="url(#Стокхолм)">
									<text id="Стокхолм-2" data-name="Стокхолм" transform="translate(982 136)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">СТОКХОЛМ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-9" data-name="box" transform="translate(708 -421)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-9" data-name="max">
								<rect id="Rectangle_4-9" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_9" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Варшава' day={0} />°</tspan></text>
								</g>
								<g id="min-9" data-name="min">
								<rect id="Rectangle_6-9" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_9" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Варшава' day={0} />°</tspan></text>
								</g>
								<g id="icon-9" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Варшава', 1)} />
								</g>
								<g id="title-9" data-name="title">
								<rect id="Rectangle_2-9" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -768, 421)" filter="url(#Варшава)">
									<text id="Варшава-2" data-name="Варшава" transform="translate(982 415)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">ВАРШАВА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-10" data-name="box" transform="translate(796 -156)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-10" data-name="max">
								<rect id="Rectangle_4-10" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_10" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Букурешт' day={0} />°</tspan></text>
								</g>
								<g id="min-10" data-name="min">
								<rect id="Rectangle_6-10" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_10" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Букурешт' day={0} />°</tspan></text>
								</g>
								<g id="icon-10" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Букурешт', 1)} />
								</g>
								<g id="title-10" data-name="title">
								<rect id="Rectangle_2-10" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -856, 156)" filter="url(#Букурешт)">
									<text id="Букурешт-2" data-name="Букурешт" transform="translate(1070 680)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">БУКУРЕШТ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-11" data-name="box" transform="translate(958 -271)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-11" data-name="max">
								<rect id="Rectangle_4-11" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_11" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Киев' day={0} />°</tspan></text>
								</g>
								<g id="min-11" data-name="min">
								<rect id="Rectangle_6-11" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_11" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Киев' day={0} />°</tspan></text>
								</g>
								<g id="icon-11" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Киев', 1)} />
								</g>
								<g id="title-11" data-name="title">
								<rect id="Rectangle_2-11" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -1018, 271)" filter="url(#Киев)">
									<text id="Киев-2" data-name="Киев" transform="translate(1232 565)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">КИЕВ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-12" data-name="box" transform="translate(1170 -549)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-12" data-name="max">
								<rect id="Rectangle_4-12" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_12" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Москва' day={0} />°</tspan></text>
								</g>
								<g id="min-12" data-name="min">
								<rect id="Rectangle_6-12" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_12" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Москва' day={0} />°</tspan></text>
								</g>
								<g id="icon-12" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Москва', 1)} />
								</g>
								<g id="title-12" data-name="title">
								<rect id="Rectangle_2-12" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -1230, 549)" filter="url(#Москва)">
									<text id="Москва-2" data-name="Москва" transform="translate(1444 287)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">МОСКВА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-13" data-name="box" transform="translate(512 -46)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-13" data-name="max">
								<rect id="Rectangle_4-13" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_13" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Рим' day={0} />°</tspan></text>
								</g>
								<g id="min-13" data-name="min">
								<rect id="Rectangle_6-13" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_13" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Рим' day={0} />°</tspan></text>
								</g>
								<g id="icon-13" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Рим', 1)} />
								</g>
								<g id="title-13" data-name="title">
								<rect id="Rectangle_2-13" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -572, 46)" filter="url(#Рим)">
									<text id="Рим-2" data-name="Рим" transform="translate(786 790)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">РИМ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-14" data-name="box" transform="translate(708 13)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-14" data-name="max">
								<rect id="Rectangle_4-14" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_14" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Скопје' day={0} />°</tspan></text>
								</g>
								<g id="min-14" data-name="min">
								<rect id="Rectangle_6-14" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_14" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Скопје' day={0} />°</tspan></text>
								</g>
								<g id="icon-14" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Скопје', 1)} />
								</g>
								<g id="title-14" data-name="title">
								<rect id="Rectangle_2-14" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -768, -13)" filter="url(#Скопје)">
									<text id="Скопје-2" data-name="Скопје" transform="translate(982 849)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">СКОПЈЕ</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-15" data-name="box" transform="translate(694 143)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-15" data-name="max">
								<rect id="Rectangle_4-15" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_15" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Атина' day={0} />°</tspan></text>
								</g>
								<g id="min-15" data-name="min">
								<rect id="Rectangle_6-15" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_15" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Атина' day={0} />°</tspan></text>
								</g>
								<g id="icon-15" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Атина', 1)} />
								</g>
								<g id="title-15" data-name="title">
								<rect id="Rectangle_2-15" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -754, -143)" filter="url(#атина)">
									<text id="атина-2" data-name="атина" transform="translate(968 979)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">АТИНА</tspan></text>
								</g>
								</g>
							</g>
							<g id="box-16" data-name="box" transform="translate(948 120)">
								<rect id="bg" data-name="bg" width="140" height="70" transform="translate(204 842)" fill="rgba(255, 255, 255, .3)"/>
								<g id="max-16" data-name="max">
								<rect id="Rectangle_4-16" data-name="Rectangle 4" width="50" height="35" transform="translate(294 842)" fill="#ffbd53"/>
								<text id="_24_16" data-name="24°" transform="translate(304 867)" fill="#fff" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMaxTemp city='Анкара' day={0} />°</tspan></text>
								</g>
								<g id="min-16" data-name="min">
								<rect id="Rectangle_6-16" data-name="Rectangle 6" width="50" height="35" transform="translate(294 877)" fill="#e8e8e8"/>
								<text id="_20_16" data-name="20°" transform="translate(304 902)" fill="#393939" fontSize="20" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0"><GetWeatherMinTemp city='Анкара' day={0} />°</tspan></text>
								</g>
								<g id="icon-16" data-name="icon" transform="translate(211.5 839.5)">
									<image className="icon" width={75} height={75} href={getWeatherIcon(data, 'Анкара', 1)} />
								</g>
								<g id="title-16" data-name="title">
								<rect id="Rectangle_2-16" data-name="Rectangle 2" width="140" height="26" transform="translate(204 816)" fill="#393939"/>
								<g transform="matrix(1, 0, 0, 1, -1008, -120)" filter="url(#Анкара)">
									<text id="Анкара-2" data-name="Анкара" transform="translate(1222 956)" fill="#fff" fontSize="18" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700"><tspan x="0" y="0">АНКАРА</tspan></text>
								</g>
								</g>
							</g>
							</g>
						</g>
					</svg>

				</div>
			</div>
		</div>
	)
}

export default MapEurope
