import { useDispatch } from "react-redux";
import Page from "../../layouts/page/page.component"
import { setUser } from "../..//store/user/user.action"
import { useMutation } from "@tanstack/react-query";
import { authLogin } from "../../fetch/auth.fetch";
import Alert from "../../components/alert/alert.component";
import { useForm } from "react-hook-form";

const Login = () => {

	const dispatch = useDispatch();

	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, handleSubmit } = useForm();

  	const onSubmit = data => {
		mutation.mutate(data)
	};

	const mutation = useMutation({
		mutationFn: (data) => authLogin(data?.username, data?.password),
		onSuccess: (data) => {
			dispatch(setUser(data.data))
		}
	})

	return (
		<Page name="login">
			<div className="page-wrapper container-fluid bg-light">
				<div className="page container-xxl">
					<div className="row h-100 align-items-center justify-content-center">
						<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
							<div className="card shadow-3d-dark">
								<div className="card-body">
									<div className="text-center my-5">
										<h1>Logo</h1>
									</div>
									
									<div className="text-center h1 fw-bold mt-5">Welcome,</div>
									<div className="text-center h4 text-muted mb-5">Sign in to continue!</div>

									<form onSubmit={handleSubmit(onSubmit)} className="row g-4 mb-3">
										<div className="col-12">
											<div className="form-floating">
												<input id="loginUsername" type="text" className="form-control" placeholder="Your email or username" autoComplete="username" {...register("username")} />
												<label htmlFor="loginUsername" className="form-label">Your email or username</label>
											</div>
											<div className="form-floating mt-3">
												<input id="loginPassword" type="password" className="form-control" placeholder="Password" autoComplete="password" {...register("password")} />
												<label htmlFor="loginPassword" className="form-label">Password</label>
											</div>
										</div>
										<div className="col-12">
											<button className="btn btn-warning w-100 py-3" type="submit">
												{mutation.isLoading ? (<><span className="spinner-grow spinner-grow-sm me-2"></span> Loading...</>) : 'Continue' }
											</button>
										</div>

										{ mutation.isError ?
											<div className="col-12">
												<Alert type='danger' message={mutation?.error?.response?.data?.message} />
											</div>
										: '' }
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page>
	)
}

export default Login