import React from 'react'

const TemplateCard = ({city, icon, temp, active}) => {
	return (
		<div id="p1" className={`city-card ${active ? 'active' : ''}`}>
			<div className="name">
				<p>{city}</p>
			</div>
			<div className="info">
				<div className="icon">
					<img src={icon} alt="" />
				</div>
				<div className="temp">
					{temp}°
				</div>
			</div>
		</div>
	)
}

export default TemplateCard