import axios from "axios";
import {PRODUCTION_API} from '../config'


// ----------------------------------------------------------
// Get all billings or customer billings
// ----------------------------------------------------------
export const getInvoices = async (token, userID = false) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	if ( userID ) return await axios.get(`${PRODUCTION_API}/invoices/user/${userID}`, config)
	
	return await axios.get(`${PRODUCTION_API}/invoices`, config);
}

export const getInvoice = async (token, invoiceID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.get(`${PRODUCTION_API}/invoices/${invoiceID}`, config);
}


export const editInvoice = async (token, data, invoiceID) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.post(`${PRODUCTION_API}/invoices/edit/${invoiceID}`, data, config);
}

