import React from 'react'
import bg from './assets/clouds_bg.mp4'
import Video from '../components/video'
import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons'

import sunset from './assets/sunset.png'
import sunrise from './assets/sunrise.png'
import presure from './assets/presure.png'
import humidity from './assets/humidity.png'
import uv from './assets/uv.png'

const ForecastCity = ({forecastFor, data, city = 'Скопје'}) => {

	const newData = forecastFor === 'today' ? data[city]?.forecast[0]?.data : data[city]?.forecast[1]?.data;

	return (
		<>
			<div className="video-bg">
				<Video src={bg} loop autoPlay muted />
			</div>

			<div className="forecast-city">
				<div className="top-title">
					<div>СКОПЈЕ { forecastFor === 'today' ? 'ДЕНЕС' : 'УТРЕ' }</div>
					<div className='www'>www.w4all.mk</div>
				</div>

				<div className="forecast-city-wrap">
					<div className="wind">
						<div className="wind-direction">
							<svg style={{ rotate: newData.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
						</div>
						<div className="wind-speed">
							{newData.wind_speed}км/ч
						</div>
					</div>
					<div className="temperature">
						<h2>{ parseFloat(newData.temp_max).toFixed() }°C</h2>
						<div className="temperature-feel">
							Се чувствува { parseFloat(newData.temp_max).toFixed() }°C
						</div>
					</div>
					<div className="icon">
						<img src={SITEL_ICONS[103].link} width={320} height={320} alt="Icon" />
						<div className="description">
							Умерено облачно со слаб дожд
						</div>
					</div>
					<div className="data">
						<div className="title">
							<h1>Скопје <span></span></h1>
						</div>

						<div className="data-information">
							<div>
								<img src={sunrise} alt="Sunrise" />
								<p>Изгрејсонце</p>
								<p><b>{newData?.sunrise}</b></p>
							</div>

							<div>
								<img src={sunset} alt="Sunset" />
								<p>Зајдисонце</p>
								<p><b>{newData?.sunset}</b></p>
							</div>

							<div>
								<img src={presure} alt="Presure" />
								<p>Притисок</p>
								<p><b>{newData?.presure}hPa</b></p>
							</div>

							<div>
								<img src={humidity} alt="Humidity" />
								<p>Влажност</p>
								<p><b>{newData?.humidity}%</b></p>
							</div>

							<div>
								<img src={uv} alt="UV" />
								<p>УВ Индекс</p>
								<p><b>{newData?.uv}</b></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ForecastCity
