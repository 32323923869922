import React, { useContext, useState } from 'react'
import Page from '../../layouts/page/page.component'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import WeeklyTemperature from '../../components/weather/weekly-temperature.component'
import DailyTemperature from '../../components/weather/daily-temperature.component'
import { Link, useParams } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'
import EditButton from '../../components/buttons/edit-button.component'
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getCityWeather, getCityWeatherInfo } from '../../fetch/weather.fetch';
import { DataContext } from '../../context/data.context';
import Loader from '../../components/loader/loader.component';
import CityInfo from '../../components/weather/city-info.component';
import SaveButton from '../../components/buttons/save-button.component';
import { Helmet } from 'react-helmet';
import DeleteButton from '../../components/buttons/delete-button.component';
import { CSSTransition } from 'react-transition-group';
import RemoveCityModal from '../../modals/remove-city.modal';

const CityWeather = () => {
	// ----------------------------------------------------------
	// Get user id params from URL
	// ----------------------------------------------------------
	const { city } = useParams();
	const [tabIndex, setTabIndex] = useState(0);
	const [dailyTempEdit, setDailyTempEdit] = useState(false);
	const [removeCityModal, setRemoveCityModal] = useState(false);
	const { cityWeatherData, setCityWeatherData } = useContext(DataContext);
	const { cityWeatherInfoData, setCityWeatherInfoData } = useContext(DataContext);

	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// Fetching all customers information
	// ----------------------------------------------------------
	const weatherQuery = useQuery({
		queryKey: ['weathers'],
		queryFn: () => getCityWeather(token, city),
		onSuccess: (res) => {
			setCityWeatherData(res.data)
		}
	});


	// ----------------------------------------------------------
	// Fetching all customers information
	// ----------------------------------------------------------
	const weatherInfoQuery = useQuery({
		queryKey: ['weatherInfo'],
		queryFn: () => getCityWeatherInfo(token, city),
		onSuccess: (res) => {
			setCityWeatherInfoData(res.data)
		}
	});


	const handleDeleteCity = () => {
		setRemoveCityModal(true);
	}


	return (
		<Page>
			{cityWeatherData?.data?.name ? <Helmet><title>Временска прогноза за {cityWeatherData?.data?.name} | Weather4All</title></Helmet> : ''}
			<Tabs selectedTabClassName="active" onSelect={(index) => setTabIndex(index)}>
				<div className="row">
					<div className="col-12">
						<div className="subheader">
							<div className="subheader-left col-sm">
								<Link className="btn btn-outline-primary border-transparent" rel="noopener" to="/weather">
									<IconArrowLeft size={18} className="me-2" />
									Back to List
								</Link>
								<div className="subheader-separator"></div>
								<span className="text-muted fst-italic me-2">Last update:</span>
								<span className="fw-bold">10 minutes ago</span>
							</div>
							<div className="subheader-right col-6">
								{ weatherQuery.isLoading || weatherInfoQuery.isLoading ? <div className="col-12 my-5"><Loader isCentered={true} /></div> : 
								<TabList className="col-12 mb-0">
									<ul className="nav nav-pills text-right justify-content-end gap-3">
										<Tab className="nav-item nav-link fw-bold cursor-pointer">
											City Info
										</Tab>

										<Tab className="nav-item nav-link fw-bold cursor-pointer">
											24h Temperatures
										</Tab>

										<Tab className="nav-item nav-link fw-bold cursor-pointer">
											14 days Temperatures
										</Tab>
									</ul>
								</TabList>
								}
							</div>
						</div>
					</div>

					{ !cityWeatherData || weatherQuery.isLoading || weatherInfoQuery.isLoading ? <div className="col-12 my-5"><Loader isCentered={true} /></div> : 
					<>
						<div className="col-12 my-5">
							<div className="row justify-content-between align-items-center">
								<div className="col-auto">
									<span className="display-5 fw-bold">{cityWeatherData?.data?.name}</span>
								</div>
								<div className="col-auto">
									{ tabIndex === 0 && !dailyTempEdit ? <DeleteButton onClick={() => handleDeleteCity()} /> : '' }
									{ tabIndex === 1 && !dailyTempEdit ? <EditButton onClick={() => setDailyTempEdit(true)} /> : (tabIndex === 1 && dailyTempEdit) ? <SaveButton onClick={() => setDailyTempEdit(false)} /> : '' }
								</div>
							</div>
						</div>
						
						<div className="col-12">
							<TabPanel>
								<CityInfo data={cityWeatherInfoData} />
							</TabPanel>

							<TabPanel>
								<DailyTemperature data={cityWeatherData} isEditable={dailyTempEdit} />
							</TabPanel>

							<TabPanel>
								<WeeklyTemperature data={cityWeatherData} />
							</TabPanel>
						</div>
					</>
					}
				</div>
			</Tabs>

			{/* Suspend User Modal */}
			<CSSTransition in={removeCityModal} timeout={0} classNames="fade">
				<RemoveCityModal data={cityWeatherData?.data} setRemoveCityModal={setRemoveCityModal} />
			</CSSTransition>
		</Page>
	)
}

export default CityWeather