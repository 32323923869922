import React, { useEffect } from 'react'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import Modal from '../components/modal/modal.component'
import ModalFooter from '../components/modal/modal-footer.component'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../fetch/customers.fetch'
import { useSelector } from 'react-redux'
import SaveButton from '../components/buttons/save-button.component'

const EditCustomerModal = ({user, refetchCustomer, setEditUserModal}) => {
	const token = useSelector(state => state.user.token)

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST) for editing customer
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editCustomer(token, data, user?.id),
		onSuccess: (res) => {
            setEditUserModal(false)
			refetchCustomer()
		}
	})


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, formState: { errors }, setValue, handleSubmit } = useForm();
  	const onSubmit = data => {
		mutation.mutate(data)
	};


	// ----------------------------------------------------------
	// Setting default values in inputs after opening modal
	// ----------------------------------------------------------
	useEffect( () => {
		if ( user )
		{
			setValue('first_name', user.first_name);
			setValue('last_name', user.last_name);
			setValue('email', user.email);
			setValue('status', user.status);
			setValue('address', user.address);
			setValue('city', user.city);
			setValue('zip', user.zip);
			setValue('country', user.country);
			setValue('edb', user.edb);
			setValue('phone', user.phone);
		}
	}, [user])


	return (
		<Modal size="modal-xl">
			<form onSubmit={handleSubmit(onSubmit)} className="modal-content">
				<ModalHeader title="Измени Корисник" />
				
				<ModalBody>
					<div className="row g-4">
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="name" className="form-label">Име</label>
							<input id="name" type="text" className="form-control" {...register("first_name")} />
						</div>
						<div className="col-sm-12 col-md-6 col-lg-6">
							<label htmlFor="name" className="form-label">Презиме</label>
							<input id="name" type="text" className="form-control" {...register("last_name")} />
						</div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="email" className="form-label">Е-адреса</label>
                            <input id="email" type="email" className="form-control" {...register("email", { required: "Please enter your email address." })} />
                            <p className='text-danger mb-0'>{errors?.email?.message}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="status" className="form-label">Статус</label>
							<select className="form-select" id="status" {...register("status")}>
								<option value="0" checked={!user?.status ? true : false}>Неактивен</option>
								<option value="1" checked={user?.status ? true : false}>Активен</option>
							</select>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="address" className="form-label">Адреса</label>
                            <input id="address" type="text" className="form-control" {...register("address")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="city" className="form-label">Град</label>
                            <input id="city" type="text" className="form-control" {...register("city")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="zip" className="form-label">Поштенски број</label>
                            <input id="zip" type="text" className="form-control" {...register("zip")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="country" className="form-label">Држава</label>
                            <input id="country" type="text" className="form-control" {...register("country")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="edb" className="form-label">ЕДБ</label>
                            <input id="edb" type="text" className="form-control" {...register("edb")} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="phone" className="form-label">Телефон</label>
                            <input id="phone" type="text" className="form-control" {...register("phone")} />
                        </div>
					</div>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => setEditUserModal(false)} className="btn btn-outline-info border-0">Затвори</button>
					{ mutation.isLoading ? (
					<button type="submit" disabled className="btn btn-primary">
						<span className="spinner-grow spinner-grow-sm me-2"></span> Loading...
					</button>
					) : (
					<SaveButton />
					) }
				</ModalFooter>
			</form>

			<div className="modal-backdrop" onClick={ () => setEditUserModal(false) }></div>
		</Modal>
	)
}

export default EditCustomerModal