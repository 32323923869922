import { IconReportMoney, IconTemplate, IconUsers, IconUsersMinus } from "@tabler/icons-react"
import DashboardInfoCard from "./dashboard-info-card.component"

const DashboardInfo = ({data}) => {

	return (
		<div className="row">
			<div className="col-sm-6 col-lg-3">
				<DashboardInfoCard data={data?.stats?.active_users} icon={<IconUsers color="#6c5dd3" />} iconBGClass="bg-l10-primary" info={23} text="Активни Корисници" />
			</div>

			<div className="col-sm-6 col-lg-3">
				<DashboardInfoCard data={data?.stats?.inactive_users} icon={<IconUsersMinus color="#f35421" />} iconBGClass="bg-l10-danger" info={3} text="Суспендирани Корисници" />
			</div>
			
			<div className="col-sm-6 col-lg-3">
				<DashboardInfoCard  data={data?.stats?.templates} icon={<IconTemplate color="#4d69fa" />} iconBGClass="bg-l10-info" info={23} text="Темплејти" />
			</div>
			
			<div className="col-sm-6 col-lg-3">
				<DashboardInfoCard data={parseInt(data?.stats?.revenue).toLocaleString('mk', { style: 'currency', currency: 'MKD', minimumFractionDigits: 0, maximumFractionDigits: 0, })} icon={<IconReportMoney color="#46bcaa" />} iconBGClass="bg-l10-success" info={`$100,000`} text="Приход" />
			</div>
		</div>	
	)
}

export default DashboardInfo