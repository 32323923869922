import { IconLogout } from '@tabler/icons-react'
import { useDispatch } from 'react-redux'
import { removeUser } from '../../store/user/user.action'
import NavItem from './nav-item.component'

const AsideFooter = () => {

	const dispatch = useDispatch();

	return (
		<div className="aside-foot">

			<nav className='mb-2'>
				<ul id="aside-dashboard" className="navigation">				
					<NavItem icon={<IconLogout size={20} color="white" stroke={2} />} text="Одјава" onClick={() => dispatch(removeUser())} />
				</ul>
			</nav>
		</div>
	)
}

export default AsideFooter