import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<footer className="footer mt-5">
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						<span className="fw-light">Copyright © 2023 - Version 1.0.0</span>
					</div>
					<div className="col-auto">
						<Link to="https://qbit.solutions" target="_blank" className="text-decoration-none fw-light">
							<small className="fw-bold">Crafted with ❤️ by qBit Solutions.</small>
						</Link>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer