import React, { useEffect, useState } from 'react'
import Modal from '../components/modal/modal.component'
import ModalHeader from '../components/modal/modal-header.component'
import ModalBody from '../components/modal/modal-body.component'
import ModalFooter from '../components/modal/modal-footer.component'
import SaveButton from '../components/buttons/save-button.component'
import { WEATHER_ICONS, W4ALL_ICONS } from '../config';

const WeatherIconsModal = ({setWeatherIcon, setModal, weatherIcon}) => {
	const [ firstIcon, setFirstIcon ] = useState(weatherIcon)

	return (
		<Modal>
			<div className="modal-content">
				<ModalHeader title="Choose Weather Icons?" />
				
				<ModalBody>
					 <ul className='row list-unstyled'>
						{ WEATHER_ICONS.map((icon, key) => 
							<li className="col-3 my-2" key={key}>
								<img src={icon.img} alt="Icon" className="border rounded cursor-pointer" width={100} onClick={() => setWeatherIcon(icon.code)} />
								<p style={{ fontSize: '11px', textAlign: 'center' }}>{icon.value}</p>
							</li>
						) }
					 </ul>
				</ModalBody>
				
				<ModalFooter>
					<button type="button" onClick={() => { setModal(false); setWeatherIcon(firstIcon) }} className="btn btn-outline-info border-0">Затвори</button>
					<SaveButton onClick={ () => { setModal(false); } } />
				</ModalFooter>
			</div>

			<div className="modal-backdrop" onClick={ () => setModal(false) }></div>
		</Modal>
	)
}

export default WeatherIconsModal