import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavItem = ({link, icon, text, onClick}) => {

	const location = useLocation();
	const firstPath = location.pathname.split('/')[1];

	return (
		<li className="navigation-item" onClick={onClick}>
			<Link className={`navigation-link navigation-link-pill ${ '/'+firstPath === link ? 'active' : ''}`} to={link}>
				<span className="navigation-link-info">
					{icon}
					<span className="navigation-text ms-1">{text}</span>
				</span>
			</Link>
		</li>
	)
}

export default NavItem