import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';

import { rootReducer } from './root-reducer';

// Loger
const middleWares = [logger]

const composedEhancers = compose(applyMiddleware(...middleWares));

// const composedEhancers = compose();
export const store = createStore(rootReducer, undefined, composedEhancers);