import React, { useEffect } from 'react'
import moment from 'moment';
import {SITEL_ICONS} from '../icons/sitel-icons/conf-sitel-icons';
import { getWeatherIcon, GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info';


const Forecast5Days = ({title, data, bg}) => {

	const fiveDays = [1, 2, 3, 4, 5]

	const city = title === 'Скопје' ? "Скопје" : "Гевгелија";
	
	return (
		<>
			<div className="video-bg">
				<img src={bg} alt="Nature" />
			</div>

			<div className="forecast-5days">
				<h1>{title} ●<span> во наредни 5 дена</span></h1>

				<div className="forecast-5days-boxes">
					{ fiveDays.map((i) =>
					<div className="forecast-5days-boxes_box" key={i}>
						<div className="forecast-5days-boxes_box_header">{moment(data['Скопје']?.forecast[i]?.date*1000).format('dddd')}</div>
						<div className={`forecast-5days-boxes_box_max-temp ${city === 'Скопје' ? 'skopje' : ''}`}>
							{ city === "Гевгелија" ? <p><GetWeatherMaxTemp city="Маврово" day={i} />°</p> : "" }
							<p><GetWeatherMaxTemp city={city} day={i} />°</p>
						</div>
						<div className="forecast-5days-boxes_box_icon">
							<img src={getWeatherIcon( data, city, i )} width="300" height="300" alt={SITEL_ICONS[data[city]?.forecast[i]?.data?.icon]?.value} />
						</div>
						<div className={`forecast-5days-boxes_box_min-temp ${city === 'Скопје' ? 'skopje' : ''}`}>
							{ city === "Гевгелија" ? <p><GetWeatherMinTemp city="Маврово" day={i} />°</p> : "" }
							<p><GetWeatherMinTemp city={city} day={i} />°</p>
						</div>
					</div>
					)}
				</div>
			</div>
		</>
	)
}

export default Forecast5Days