import React, { useContext, useEffect, useState } from 'react'
import Page from '../../layouts/page/page.component'
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import { DataContext } from '../../context/data.context';
import { useQuery } from '@tanstack/react-query';
import { getCitiesWeather } from '../../fetch/weather.fetch';
import Card from '../../components/card/card.component';
import CardBody from '../../components/card/card-body.component';
import Loader from '../../components/loader/loader.component';
import CardHeader from '../../components/card/card-header.component';
import { IconCloudBolt } from '@tabler/icons-react';
import SearchSubheader from '../../components/subheader/search-subheader.component';
import { ITEMS_PER_PAGE } from '../../config';
import TableFooter from '../../components/table/table-footer.component';
import CardFooter from '../../components/card/card-footer.component';
import CitiesTable from '../../components/table/cities-table.component';

const Weather = () => {

	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)
	const { citiesWeatherData, setCitiesWeatherData } = useContext(DataContext);
	const [searchInput, setSearchInput] = useState('')
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)
	const [pagination, setPagination] = useState({
		totalPages: 1,
		currentPage: 1,
	});
	const { cityWeatherData, setCityWeatherData } = useContext(DataContext);
	const { cityWeatherInfoData, setCityWeatherInfoData } = useContext(DataContext);

	
	useEffect( () => {
		setCityWeatherData('');
	}, [])


	// ----------------------------------------------------------
	// Fetching all customers information
	// ----------------------------------------------------------
	const weatherQuery = useQuery({
		queryKey: ['weather'],
		queryFn: () => getCitiesWeather(token),
		staleTime: 5000,
		onSuccess: (res) => {
			setCitiesWeatherData(res.data.data);
		}
	});

	
	// ----------------------------------------------------------
	// Changing pagination depending on the filtered data
	// ----------------------------------------------------------
	useEffect( () => {
		if (citiesWeatherData.length)
		{
			setPagination({
				currentPage: 1,
				totalPages: Math.ceil(citiesWeatherData?.length / itemsPerPage),
			});
		}
	}, [citiesWeatherData]);


	// ----------------------------------------------------------
	// Filter Cities
	// ----------------------------------------------------------
	useEffect( () => {
		weatherQuery?.data?.data?.data && setCitiesWeatherData(weatherQuery.data.data.data.filter(city => {
			// var searchKeyword = city.city;
			var fullName = city.name;
			return fullName.toLowerCase().includes(searchInput.toLowerCase())
		}))
	}, [searchInput] )


	// ----------------------------------------------------------
	// Calculating the slice from to for the data
	// ----------------------------------------------------------
	var sliceTo = pagination.currentPage * itemsPerPage;
	var sliceFrom = sliceTo - itemsPerPage;

	return (
		<Page>
			<SearchSubheader page='weather' refetch={weatherQuery.refetch} setSearchInput={setSearchInput} />

			<div className="row h-100 mt-4">
				<div className="col-sm-12 col-lg-12">
				{ ( weatherQuery.isLoading ) ? <Loader isCentered /> :
					<Card className="card-stretch-full">
						<CardHeader icon={<IconCloudBolt size={30} color="#6c5dd3" />} title="Временска прогноза по градови" />

						<CardBody>
							<CitiesTable cities={citiesWeatherData} refetch={weatherQuery.refetch} pagination={pagination} setItemsPerPage={setItemsPerPage} sliceTo={sliceTo} sliceFrom={sliceFrom} setPagination={setPagination} />
						</CardBody>

						<CardFooter>
							<TableFooter sliceFrom={sliceFrom} sliceTo={sliceTo} items={citiesWeatherData} pagination={pagination} setPagination={setPagination} setItemsPerPage={setItemsPerPage} />
						</CardFooter>
					</Card>
				}
				</div>
			</div>
		</Page>
	)
}

export default Weather