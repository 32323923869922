import axios from "axios";
import { PRODUCTION_API } from "../config";

export const authLogin = async (email, password) => {
	return await axios.post(`${PRODUCTION_API}/auth/login`, {
		email: email,
    	password: password,
	});
}


export const authRegister = async (data, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return await axios.post(`${PRODUCTION_API}/auth/register`, data, config);
}


export const checkToken = async (token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	
	return await axios.get(`${PRODUCTION_API}/auth/me`, config);
}